import { api } from '../index';
import { useMutation } from 'react-query';
import { ExternalUpdateVariables } from '../models/external-models/ExternalUpdateVariables';
import { ExternalPrizeOption } from '../models/external-models/ExternalPrizeOption';

const editPrizeOptionMutation = (variables: ExternalUpdateVariables) => {
    return api.patch<ExternalPrizeOption>(`/prize/option?id=${variables.id}`, variables.updateObjects);
};

export const useEditPrizeOptionMutation = () => {
    return useMutation('editPrizeOptionMutation', editPrizeOptionMutation);
};