import { api } from '../index';
import { QueryFunctionContext, useQuery } from 'react-query';
import { ExternalPartner } from '../models/external-models/ExternalPartner';
import { ExternalPaginatedResponse } from '../models/external-models/ExternalPaginatedResponse';

const fetchPartners = ({ pageParam }: QueryFunctionContext) => {
    const params = new URLSearchParams();
    params.append('page-size', '50');
    return api.get<{ partners: ExternalPartner[] } & ExternalPaginatedResponse>('/partners', { params });
};

export const useFetchPartners = () => {
    return useQuery('fetchPartners', fetchPartners, {
        select: response => response.data.partners.map(partner => partner.name),
    });
};
