import { InvitationCard } from '../api/models/firestore/InvitationCard';
import { InvitationCardSeason } from '../api/models/firestore/InvitationCardSeason';

export const findLastSeasonUuid = (card: InvitationCard): string => {
    return Object.keys(card.seasons).sort(
        (s1, s2) => parseInt(card.seasons[s2].season.split('-')[0]) - parseInt(card.seasons[s1].season.split('-')[0]),
    )[0];
};

export const findLastSeason = (card: InvitationCard): InvitationCardSeason => {
    return card.seasons[findLastSeasonUuid(card)];
};

export const seasonIsValid = (season: string) => {
    return /^\d{4}-\d{4}$/g.test(season);
};
