import { alpha, styled } from '@mui/material/styles';

export const ArrowStyle = styled('span')<{direction?: 'left' | 'center' | 'right' | number}>(({ theme, direction = 'right' }) => ({
  [theme.breakpoints.up('sm')]: {
      top: -7,
      zIndex: 1,
      width: 12,
      ...(direction === 'right' && {right: 20}),
      ...(direction === 'left' && {left: 20}),
      ...(direction === 'center' && {alignSelf: 'center'}),
      ...(typeof direction === 'number' && {right: direction}),
      height: 12,
      position: 'absolute',
      borderRadius: '0 0 4px 0',
      transform: 'rotate(-135deg)',
      background: theme.palette.background.paper,
      borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
      borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));