import { styled } from '@mui/material/styles';
import { Button, FormLabel } from '@mui/material';
import { Box } from '@mui/system';

export const ArrayFromLabel = styled(FormLabel)(({ theme }) => ({
    width: '100%',
    margin: 10,
    padding: 10,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 5,
}));

export const ArrayAddButton = styled(Button)(({ theme }) => ({
    width: 300,
    padding: 15,
    margin: 8,
}));

export const ControlButton = styled(Button)(({ theme }) => ({
    width: 200,
    padding: 15,
    margin: 8,
}));

export const ActionButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
}));
