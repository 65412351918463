import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Props {
    onConfirm: () => void;
    onCancel?: () => void;
    title: string;
    description: string;
    open: boolean;
    confirmButtonText?: string;
    list?: string[],
}

const ConfirmationDialog: React.FC<Props> = ({ onConfirm, open, title, onCancel, description, confirmButtonText, list }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                {list && list.length > 0 && (
                    <ul>
                        {list.map(t => (<DialogContentText><li>{t}</li></DialogContentText>))}
                    </ul>
                )}
            </DialogContent>
            <DialogActions>
                {onCancel && <Button onClick={onCancel}>{t('cancel')}</Button>}
                <Button onClick={onConfirm} autoFocus>
                    {confirmButtonText || t('confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
