import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FileUploadArea = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    border: '3px dashed black',
    alignItems: 'center',
    width: '100%',
    height: 400,
    borderRadius: 10,
    flexDirection: 'column',
}));

export const FileUploadText = styled(Typography)(({ theme }) => ({
    margin: 20
}));
