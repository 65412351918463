import { useMutation } from 'react-query';
import { mailApi } from '../index';
import { ExternalSendInvitationCardMails } from '../models/external-models/ExternalSendInvitationCardMails';
import { InvitationCard } from '../models/firestore/InvitationCard';
import config from '../../config';
import { findLastSeasonUuid } from '../../utils/SeasonsUtil';

const sendInvitationCardEmails = (cards: InvitationCard[]) => {
    const extSendIncitationCardsObject: ExternalSendInvitationCardMails = {
        from_email: 'no-reply@rbfa.be',
        from_name: 'Invitation cards RBFA',
        language: cards[0].language.toUpperCase(),
        batch: cards.map(c => {
            // Search for the last seasons uuid
            const uuid = findLastSeasonUuid(c)
            const googleLink = `${config.intermediateUrl}/api/v1/wallet/invitation-cards/google?card=${uuid}`;
            const appleLink = `${config.intermediateUrl}/api/v1/wallet/invitation-cards/apple?card=${uuid}`;
            return {
                to_emails: [c.email],
                first_name: c.firstname,
                google_card_link: googleLink,
                apple_card_link: appleLink,
                card_type: c.cardType,
            };
        }),
    };
    return mailApi.post('/invitation-card', extSendIncitationCardsObject);
};

export const useMutateInvitationCardsMails = () => {
    return useMutation('sendInvitationCardEmails', sendInvitationCardEmails);
};
