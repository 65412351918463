import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const DashboardButtonsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: -25,
    marginRight: -25,
}));

const S = {
    DashboardButtonsBox,
};

export default S;
