import React, { useEffect, useState } from 'react';
import { InputBaseComponentProps, TextField, Divider, Box, Autocomplete, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import MultiLingualInput from '../../components/MultiLingualInput';
import CreateOrUpdateModal from '../../components/CreateOrUpdateModal';
import S from '../../components/CreateOrUpdateModal.styles';
import { format, isBefore } from 'date-fns';
import { useAddEventsMutation } from '../../api/hooks/useAddEventsMutation';
import { UrlValidator } from '../../utils/url.validator';
import { useFetchPartners } from '../../api/hooks/useFetchPartners';
import { AllLanguagesString } from '../../api/models/AllLanguagesString';
import { useFetchEvent } from '../../api/hooks/useFetchEvent';
import { useEditEventMutation } from '../../api/hooks/useEditEventMutation';
import { UpdateTransformer } from '../../api/models/transformers/UpdateTransformer';
import { EventTransformer } from '../../api/models/transformers/EventTransformer';
import { ExternalAddEventsVariables } from '../../api/models/external-models/ExternalAddEventsVariables';
import { ExternalSingleEventVariables } from '../../api/models/external-models/ExternalSingleEventVariables';
import { isEqual } from 'lodash';

interface Props {
    open: boolean;
    onClose: (created?: boolean) => void;
    campaignId: string;
    eventId?: string;
}

const numberInputProps: InputBaseComponentProps = { inputMode: 'numeric', pattern: '[0-9]*' };
const CreateOrUpdateEventModal: React.FC<Props> = ({ open, onClose, campaignId, eventId }) => {
    const { t } = useTranslation();
    const editEventMutation = useEditEventMutation();
    const partnersQuery = useFetchPartners();
    const eventQuery = useFetchEvent(eventId, true);
    const addEventMutation = useAddEventsMutation();
    const [addEventVariables, setAddEventVariables] = useState<ExternalAddEventsVariables | ExternalSingleEventVariables>(
        EventTransformer.transformToEventVariables<ExternalAddEventsVariables>(),
    );

    useEffect(() => {
        setAddEventVariables(EventTransformer.transformToEventVariables<ExternalAddEventsVariables>());
    }, [open]);

    useEffect(() => {
        if (addEventMutation.isSuccess || editEventMutation.isSuccess) {
            onClose(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEventMutation.isSuccess, editEventMutation.isSuccess]);

    useEffect(() => {
        if (!!eventQuery.data) {
            setAddEventVariables(EventTransformer.transformToEventVariables<ExternalSingleEventVariables>(eventQuery.data));
        }
    }, [eventQuery.data]);

    const onChangePartner = (event: any, newValue: string | null) => setAddEventVariables({ ...addEventVariables, partner: newValue || '' });

    const onChangeAmountToCreate = (event: React.ChangeEvent<HTMLInputElement>) => {
        let amount = +event.target.value;
        amount = amount > 250 ? 250 : amount < 0 ? 0 : amount;
        setAddEventVariables({ ...addEventVariables, amount: amount });
    };

    const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddEventVariables({ ...addEventVariables, type: event.target.value });
    };

    const onChangePoints = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddEventVariables({ ...addEventVariables, points: +event.target.value });
    };

    const onChangeRedemptionsPerUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddEventVariables({ ...addEventVariables, redemptions_per_user: +event.target.value });
    };

    const onChangeDescription = (value: string, language: keyof AllLanguagesString) => {
        const updatedEventVariables = { ...addEventVariables };
        updatedEventVariables.description[language] = value;
        setAddEventVariables(updatedEventVariables);
    };

    const onChangeConfirmationMessage = (value: string, language: keyof AllLanguagesString) => {
        const updatedEventVariables = { ...addEventVariables };
        updatedEventVariables.confirmation_message[language] = value;
        setAddEventVariables(updatedEventVariables);
    };

    const onChangeWebsiteUrl = (value: string, language: keyof AllLanguagesString) => {
        const updatedEventVariables = { ...addEventVariables };
        updatedEventVariables.website_url[language] = value;
        setAddEventVariables(updatedEventVariables);
    };

    const createOrUpdateEvent = () => {
        if (areFieldsValid()) {
            if (!!eventId) {
                const previousAddEventVariables = EventTransformer.transformToEventVariables<ExternalSingleEventVariables>(eventQuery.data);
                const eventUpdateObject = UpdateTransformer.transformToUpdateObject(previousAddEventVariables, addEventVariables);
                if (eventUpdateObject.updates.length > 0) {
                    editEventMutation.mutate({
                        id: eventId,
                        updateObjects: eventUpdateObject,
                    });
                }
            } else if ('amount' in addEventVariables) {
                addEventMutation.mutate({
                    ...addEventVariables,
                    campaign_id: campaignId,
                });
            }
        }
    };

    const changeStartDate = (date: Date | null) => {
        setAddEventVariables({
            ...addEventVariables,
            start_date: format(date!, 'yyyy-MM-dd'),
        });
    };

    const changeEndDate = (date: Date | null) => {
        setAddEventVariables({
            ...addEventVariables,
            end_date: format(date!, 'yyyy-MM-dd'),
        });
    };

    const areFieldsValid = (): boolean => {
        const datesAreValid =
            !!addEventVariables.start_date &&
            !!addEventVariables.end_date &&
            isBefore(new Date(addEventVariables.start_date), new Date(addEventVariables.end_date));
        const descriptionsAreValid = addEventVariables.description.EN && addEventVariables.description.FR && addEventVariables.description.NL;
        const confirmationsAreValid =
            addEventVariables.confirmation_message.EN && addEventVariables.confirmation_message.FR && addEventVariables.confirmation_message.NL;
        const websiteUrlsAreValid =
            (!addEventVariables.website_url.EN || UrlValidator.validate(addEventVariables.website_url.EN)) &&
            (!addEventVariables.website_url.FR || UrlValidator.validate(addEventVariables.website_url.FR)) &&
            (!addEventVariables.website_url.NL || UrlValidator.validate(addEventVariables.website_url.NL));

        const isChanged = !isEqual(EventTransformer.transformToEventVariables<ExternalSingleEventVariables>(eventQuery.data), addEventVariables);

        return (
            !!addEventVariables.partner &&
            !!addEventVariables.type &&
            datesAreValid &&
            addEventVariables.points > 0 &&
            addEventVariables.redemptions_per_user > 0 &&
            (!('amount' in addEventVariables) || addEventVariables.amount > 0) &&
            !!descriptionsAreValid &&
            !!confirmationsAreValid &&
            websiteUrlsAreValid &&
            isChanged
        );
    };

    const urlValidator = (value: string): boolean => {
        return !!value && !UrlValidator.validate(value);
    };

    return (
        <CreateOrUpdateModal
            open={open}
            onCancel={onClose}
            areFieldsValid={areFieldsValid}
            isLoading={addEventMutation.isLoading}
            title={!eventId ? t('create.new.event') : `${t('edit.event')}: ${eventQuery.data?.name}`}
            onCreate={createOrUpdateEvent}
            isUpdateModal={!!eventId}>
            <S.PaddedBox>
                <Box sx={{ flexDirection: 'row', display: 'flex', mb: 2 }}>
                    <Autocomplete
                        sx={{ flex: 1 }}
                        value={addEventVariables.partner}
                        onChange={onChangePartner}
                        fullWidth
                        loading={partnersQuery.isLoading}
                        options={partnersQuery.data || []}
                        getOptionLabel={option => option}
                        disabled={!!eventId}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant={'standard'}
                                label={`${t('partner')} *`}
                                placeholder={t('partner')}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {partnersQuery.isLoading ? <CircularProgress size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
                <TextField
                    label={`${t('event.type')} *`}
                    variant={'standard'}
                    fullWidth
                    value={addEventVariables.type}
                    onChange={onChangeType}
                    sx={{ flex: 1 }}
                    disabled={!!eventId}
                />
                <MultiLingualInput title={t('description')} value={addEventVariables.description} onChangeValue={onChangeDescription} isRequired />
                <S.Row>
                    <DatePicker
                        label={`${t('campaign.start.date')} *`}
                        value={addEventVariables.start_date ? new Date(addEventVariables.start_date) : null}
                        onChange={changeStartDate}
                        inputFormat={'dd/MM/yyyy'}
                        renderInput={params => <TextField {...params} variant={'standard'} sx={{ mr: 2, flex: 1 }} />}
                    />
                    <DatePicker
                        label={`${t('campaign.end.date')} *`}
                        value={addEventVariables.end_date ? new Date(addEventVariables.end_date) : null}
                        onChange={changeEndDate}
                        inputFormat={'dd/MM/yyyy'}
                        renderInput={params => <TextField {...params} variant={'standard'} sx={{ flex: 1 }} />}
                    />
                </S.Row>
                <S.Row mt={2}>
                    <TextField
                        inputProps={numberInputProps}
                        label={`${t('event.points')} *`}
                        variant={'standard'}
                        fullWidth
                        type={'number'}
                        value={Number(addEventVariables.points).toString()}
                        onChange={onChangePoints}
                        sx={{ mr: 2, flex: 1 }}
                    />
                    <TextField
                        inputProps={numberInputProps}
                        label={`${t('redemptions.per.user')} *`}
                        variant={'standard'}
                        type={'number'}
                        fullWidth
                        value={Number(addEventVariables.redemptions_per_user).toString()}
                        onChange={onChangeRedemptionsPerUser}
                        sx={{ flex: 1 }}
                    />
                </S.Row>
                <MultiLingualInput
                    title={t('event.confirmation.message')}
                    value={addEventVariables.confirmation_message}
                    onChangeValue={onChangeConfirmationMessage}
                    isRequired
                />
                <MultiLingualInput
                    title={t('website.url')}
                    value={addEventVariables.website_url}
                    onChangeValue={onChangeWebsiteUrl}
                    validator={urlValidator}
                    validatorText={t('invalid.url')}
                />
            </S.PaddedBox>
            <Divider />
            {!eventId && 'amount' in addEventVariables && (
                <S.PaddedBox>
                    <TextField
                        inputProps={numberInputProps}
                        label={`${t('amount.to.create')} *`}
                        variant={'standard'}
                        type={'number'}
                        fullWidth
                        value={Number(addEventVariables.amount).toString()}
                        onChange={onChangeAmountToCreate}
                        sx={{ flex: 1 }}
                    />
                </S.PaddedBox>
            )}
        </CreateOrUpdateModal>
    );
};

export default CreateOrUpdateEventModal;
