import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Box, InputBaseComponentProps, Switch, FormControlLabel, Divider, Autocomplete, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { ExternalAddPrizeVariables } from '../../api/models/external-models/ExternalAddPrizeVariables';
import { useAddPrizeMutation } from '../../api/hooks/useAddPrizeMutation';
import S from '../../components/CreateOrUpdateModal.styles';
import CreateOrUpdateModal from '../../components/CreateOrUpdateModal';
import { Campaign } from '../../api/models/Campaign';
import AddPrizeOptionSection from './AddPrizeOptionSection';
import { ExternalAddPrizeOptionVariables } from '../../api/models/external-models/ExternalAddPrizeOptionVariables';
import MultiLingualInput from '../../components/MultiLingualInput';
import ImageSelector from '../../components/image-selector/ImageSelector';
import { useFetchCategories } from '../../api/hooks/useFetchCategories';
import { useFetchPrize } from '../../api/hooks/useFetchPrize';
import { PrizeTransformer } from '../../api/models/transformers/PrizeTransformer';
import { UpdateTransformer } from '../../api/models/transformers/UpdateTransformer';
import { useEditPrizeMutation } from '../../api/hooks/useEditPrizeMutation';
import { AllLanguagesString } from '../../api/models/AllLanguagesString';

interface Props {
    open: boolean;
    campaign: Campaign;
    onClose: (created?: boolean) => void;
    prizeId?: string;
}

const rowStyle = { flexDirection: 'row', display: 'flex', mb: 2 };
const numberInputProps: InputBaseComponentProps = { inputMode: 'numeric', pattern: '[0-9]*' };
const textAreaProps = { multiline: true, minRows: 1, maxRows: 20, fullWidth: true };

const CreateOrUpdatePrizeModal: React.FC<Props> = ({ open, onClose, campaign, prizeId }) => {
    const addPrizeMutation = useAddPrizeMutation();
    const editPrizeMutation = useEditPrizeMutation();
    const categoriesQuery = useFetchCategories();
    const prizeQuery = useFetchPrize(prizeId, true);

    const [addPrizeVariables, setAddPrizeVariables] = useState<ExternalAddPrizeVariables>(PrizeTransformer.transformToPrizeVariables(campaign));
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [prizeOptions, setPrizeOptions] = useState<ExternalAddPrizeOptionVariables[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setAddPrizeVariables(PrizeTransformer.transformToPrizeVariables(campaign, prizeQuery.data));
    }, [campaign, prizeQuery.data]);

    useEffect(() => {
        setSelectedImages(addPrizeVariables.images);
        setPrizeOptions(addPrizeVariables.options);
    }, [addPrizeVariables.images, addPrizeVariables.options]);

    useEffect(() => {
        if (addPrizeMutation.isSuccess || editPrizeMutation.isSuccess) {
            onClose(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addPrizeMutation.isSuccess, editPrizeMutation.isSuccess]);

    const onChangeName = (value: string, propertyName: keyof AllLanguagesString) => {
        const updatedAddPrizeVariables = { ...addPrizeVariables };
        updatedAddPrizeVariables.name[propertyName] = value;
        setAddPrizeVariables(updatedAddPrizeVariables);
    };
    const onChangeDescription = (value: string, propertyName: keyof AllLanguagesString) => {
        const updatedAddPrizeVariables = { ...addPrizeVariables };
        updatedAddPrizeVariables.description[propertyName] = value;
        setAddPrizeVariables(updatedAddPrizeVariables);
    };
    const onChangeCategory = (event: any, newValue: string | null) => setAddPrizeVariables({ ...addPrizeVariables, category: newValue || '' });
    const onChangePoints = (event: React.ChangeEvent<HTMLInputElement>) =>
        setAddPrizeVariables({ ...addPrizeVariables, points: +event.target.value });
    const onChangeOrdersPerUser = (event: React.ChangeEvent<HTMLInputElement>) =>
        setAddPrizeVariables({ ...addPrizeVariables, orders_per_user: +event.target.value });
    const onChangeCountdown = (event: React.ChangeEvent<HTMLInputElement>) =>
        setAddPrizeVariables({ ...addPrizeVariables, countdown: event.target.checked });

    const changeStartDate = (date: Date | null) => {
        setAddPrizeVariables({
            ...addPrizeVariables,
            start_at: format(date!, 'yyyy-MM-dd'),
        });
    };

    const changeEndDate = (date: Date | null) => {
        setAddPrizeVariables({
            ...addPrizeVariables,
            available_until: format(date!, 'yyyy-MM-dd'),
        });
    };

    const createOrUpdatePrize = () => {
        if (areFieldsValid()) {
            if (!!prizeId && prizeQuery.data) {
                const firstAddPrizeVariables = PrizeTransformer.transformToPrizeVariables(campaign, prizeQuery.data);
                const newVariables = {
                    ...addPrizeVariables,
                    images: selectedImages,
                };
                const prizeUpdateObject = UpdateTransformer.transformToUpdateObject(firstAddPrizeVariables, newVariables);
                if (prizeUpdateObject.updates.length > 0) {
                    editPrizeMutation.mutate({
                        id: prizeId,
                        updateObjects: prizeUpdateObject,
                    });
                }
            } else {
                addPrizeMutation.mutate({
                    ...addPrizeVariables,
                    images: selectedImages,
                    options: prizeOptions,
                });
            }
        }
    };

    const areFieldsValid = (): boolean => {
        const namesAreValid = addPrizeVariables.name.EN && addPrizeVariables.name.FR && addPrizeVariables.name.NL;
        const descriptionsAreValid = addPrizeVariables.description.EN && addPrizeVariables.description.FR && addPrizeVariables.description.NL;
        const isChanged =
            !prizeQuery.data ||
            JSON.stringify(PrizeTransformer.transformToPrizeVariables(campaign, prizeQuery.data)) !== JSON.stringify(addPrizeVariables) ||
            JSON.stringify(selectedImages) !== JSON.stringify(prizeQuery.data?.images);

        const doesHavePrizeOptions = prizeOptions && prizeOptions.length > 0;
        const validOrdersPerUser = addPrizeVariables.orders_per_user > 0;

        return (
            validOrdersPerUser &&
            doesHavePrizeOptions &&
            !!namesAreValid &&
            !!descriptionsAreValid &&
            !!addPrizeVariables.available_until &&
            !!addPrizeVariables.start_at &&
            !!addPrizeVariables.category &&
            !!addPrizeVariables.label &&
            (!prizeId || isChanged)
        );
    };
    const onImageSelected = (image: string) => {
        setSelectedImages(value => [...value, image]);
    };
    const onImageDeselected = (image: string) => {
        setSelectedImages(value => value.filter(i => i !== image));
    };

    return (
        <CreateOrUpdateModal
            title={!prizeId ? t('create.new.prize') : t('edit.prize')}
            onCreate={createOrUpdatePrize}
            isLoading={addPrizeMutation.isLoading}
            open={open}
            onCancel={onClose}
            areFieldsValid={areFieldsValid}
            isUpdateModal={!!prizeId}>
            <S.PaddedBox>
                <MultiLingualInput title={t('name')} onChangeValue={onChangeName} value={addPrizeVariables.name} isRequired />
                <Box sx={rowStyle}>
                    <Autocomplete
                        sx={{ flex: 1 }}
                        value={addPrizeVariables.category}
                        onChange={onChangeCategory}
                        fullWidth
                        loading={categoriesQuery.isLoading}
                        options={categoriesQuery.data || []}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant={'standard'}
                                label={`${t('prize.category')} *`}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {categoriesQuery.isLoading ? <CircularProgress size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
                <Box sx={rowStyle}>
                    <TextField
                        inputProps={numberInputProps}
                        label={t('prize.points')}
                        variant={'standard'}
                        fullWidth
                        type={'number'}
                        value={Number(addPrizeVariables.points).toString()}
                        onChange={onChangePoints}
                        sx={{ mr: 2, flex: 1 }}
                    />
                    <TextField
                        inputProps={numberInputProps}
                        label={`${t('prize.order.per.user')} *`}
                        variant={'standard'}
                        type={'number'}
                        fullWidth
                        value={Number(addPrizeVariables.orders_per_user).toString()}
                        onChange={onChangeOrdersPerUser}
                        sx={{ flex: 1 }}
                    />
                </Box>
                <Box sx={rowStyle}>
                    <DatePicker
                        inputFormat={'dd/MM/yyyy'}
                        label={`${t('start.date')} *`}
                        value={addPrizeVariables.start_at ? new Date(addPrizeVariables.start_at) : null}
                        onChange={changeStartDate}
                        renderInput={params => <TextField {...params} variant={'standard'} sx={{ flex: 1 }} />}
                    />
                    <FormControlLabel
                        labelPlacement={'top'}
                        control={<Switch checked={addPrizeVariables.countdown} onChange={onChangeCountdown} />}
                        label={t('prize.countdown')}
                        sx={{ mr: 1, flex: 1 }}
                    />
                    <DatePicker
                        inputFormat={'dd/MM/yyyy'}
                        label={`${t('prize.available.until')} *`}
                        value={addPrizeVariables.available_until ? new Date(addPrizeVariables.available_until) : null}
                        onChange={changeEndDate}
                        renderInput={params => <TextField {...params} variant={'standard'} sx={{ flex: 1 }} />}
                    />
                </Box>
                <MultiLingualInput
                    title={t('description')}
                    onChangeValue={onChangeDescription}
                    value={addPrizeVariables.description}
                    inputProps={textAreaProps}
                    isRequired
                />
            </S.PaddedBox>
            {!prizeId && (
                <>
                    <Divider />
                    <S.Title variant={'h6'} sx={{paddingBottom: 0}}>{t('prize.options')}</S.Title>
                    <S.Title variant={'subtitle2'} sx={{paddingTop: 0}}>{`(${t('prize.option.required.one')})`}</S.Title>
                    <S.PaddedBox>
                        <AddPrizeOptionSection prizeOptions={prizeOptions} setPrizeOptions={setPrizeOptions} />
                    </S.PaddedBox>
                </>
            )}

            <Divider />
            <S.Title variant={'h6'}>{t('prize.images')}</S.Title>
            <S.PaddedBox>
                <ImageSelector onImageSelected={onImageSelected} onImageDeselected={onImageDeselected} selectedImages={selectedImages} />
            </S.PaddedBox>
        </CreateOrUpdateModal>
    );
};

export default CreateOrUpdatePrizeModal;
