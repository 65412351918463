import CreateOrUpdateModal from '../../../../components/CreateOrUpdateModal';
import { Typography } from '@mui/material';
import * as S from './SendCardsModal.styles';
import { InvitationCard } from '../../../../api/models/firestore/InvitationCard';
import { useMutateInvitationCards } from '../../../../api/hooks/firestore/useMutateInvitationCards';
import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutateInvitationCardsMails } from '../../../../api/hooks/useMutateInvitationCardsMails';
import { InvitationCardSeason } from '../../../../api/models/firestore/InvitationCardSeason';
import { useFetchInvitationCardsSettings } from '../../../../api/hooks/firestore/useFetchInvitationCardsSettings';
import { useMutateInvitationCardsSendout } from '../../../../api/hooks/firestore/useMutateInvitationCardsSendout';
import { validate as isEmail } from 'email-validator';

type Props = {
    open: boolean;
    data?: InvitationCard[];
    onDone: () => void;
};

export const SendCardsModal: React.FC<Props> = ({ open, data, onDone }) => {
    const { t } = useTranslation();
    const [pendingCards, setPendingCards] = useState<InvitationCard[]>();
    const [batchToSend, setBatchToSend] = useState<InvitationCard[]>();
    const [validEmailBatch, setValidEmailBatch] = useState<InvitationCard[]>()
    const mutateInvitationCards = useMutateInvitationCards();
    const mutateSendInvitationCards = useMutateInvitationCardsMails();
    const fetchInvitationCardSettings = useFetchInvitationCardsSettings();
    const mutateInvitationCardsSendout = useMutateInvitationCardsSendout();
    const BATCH_LENGTH = fetchInvitationCardSettings.settings?.batch_size || 10;

    useEffect(() => {
        if (data && !isEqual(pendingCards, data)) {
            setPendingCards(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (open && pendingCards) {
            setBatchToSend(pendingCards.filter(c => c.language === pendingCards[0].language).slice(0, BATCH_LENGTH + 1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, pendingCards]);

    useEffect(() => {
        if (!open) {
            setBatchToSend(undefined);
            setPendingCards(undefined);
        }
    }, [open]);

    useEffect(() => {
        if (batchToSend) {
            mutateInvitationCards.mutate(batchToSend);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [batchToSend]);

    useEffect(() => {
        if (mutateInvitationCards.isSuccess && mutateInvitationCards.data && mutateInvitationCards.data.length >= 0) {
            const dataToSend = mutateInvitationCards.data.filter(c => isEmail(c.email)).map(c => {
                const batchToSendCard = batchToSend?.find(card => card.cardNumber === c.cardNumber);
                const seasonUuid = Object.keys(c.seasons).find(uuid => c.seasons[uuid].season === batchToSendCard?.seasons.newSeason.season) || '';
                return {
                    ...c,
                    seasons: {
                        [seasonUuid]: batchToSendCard?.seasons.newSeason as InvitationCardSeason,
                    },
                };
            });
            if (dataToSend && dataToSend.length > 0) {
                setValidEmailBatch(dataToSend);
                mutateSendInvitationCards.mutate(dataToSend);
            } else {
                sendoutSuccess()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateInvitationCards.isSuccess]);

    useEffect(() => {
        if (mutateSendInvitationCards.isSuccess) {
            if (validEmailBatch && pendingCards) {
                mutateInvitationCardsSendout.mutate(validEmailBatch, pendingCards);
            }
            sendoutSuccess()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateSendInvitationCards.isSuccess]);

    const sendoutSuccess = () => {
        const newUnsendCards = pendingCards?.filter(c => !batchToSend?.some(bc => bc.cardNumber === c.cardNumber));
        setPendingCards(newUnsendCards);
    }

    const loadingBarValue = useMemo(() => {
        if (pendingCards?.length === 0) {
            return 100;
        }
        return pendingCards && data ? ((data.length - pendingCards?.length) / data.length) * 100 : 0;
    }, [pendingCards, data]);

    const isSendoutSuccess = () => !pendingCards || pendingCards.length <= 0;

    return (
        <CreateOrUpdateModal
            open={open}
            title={t('sending_mails')}
            buttonText={t('ok')}
            onCreate={onDone}
            isLoading={false}
            areFieldsValid={isSendoutSuccess}>
            <S.ContentContainer>
                <Typography>{t('sending_warning')}</Typography>
                <S.LoadingBar variant={'determinate'} value={loadingBarValue} />
            </S.ContentContainer>
        </CreateOrUpdateModal>
    );
};
