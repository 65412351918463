export class UrlValidator {
    static validate(urlString: string): boolean {
        const expression = 'https:\\/\\/(wwww\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)';

        const regex = new RegExp(expression);

        const url = urlString.replace('www.', '')

        return !!url.match(regex);
    }
}
