import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import './firebase';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </StyledEngineProvider>
    </React.StrictMode>,
);
