import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';

const CenterBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(10),
}));

const LogoutLayout = () => {
    const { oktaAuth } = useOktaAuth();

    useEffect(() => {
        oktaAuth.signOut();
    }, [oktaAuth]);

    return (
        <CenterBox>
            <CircularProgress />
        </CenterBox>
    );
};

export default LogoutLayout;
