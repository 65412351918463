import { api } from '../index';
import { QueryFunctionContext, useQuery } from 'react-query';
import { ExternalCategory } from '../models/external-models/ExternalCategory';
import { ExternalPaginatedResponse } from '../models/external-models/ExternalPaginatedResponse';

const fetchCategories = ({ pageParam }: QueryFunctionContext) => {
    const params = new URLSearchParams();
    params.append('page-size', '20');
    return api.get<{ categories: ExternalCategory[] } & ExternalPaginatedResponse>('/prize/categories', { params });
};

export const useFetchCategories = () => {
    return useQuery('fetchCategories', fetchCategories, {
        select: response => response.data.categories.map(category => category.name),
    });
};
