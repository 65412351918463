import * as React from 'react';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Skeleton, TableCell } from '@mui/material';
import { HeadCell } from '../../components/table/HeadCell';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import TableHead, { getComparator } from '../../components/table/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TablePagination from '../../components/table/TablePagination';
import { Order } from '../../components/table/Order';
import { Prize } from '../../api/models/Prize';
import { useFetchPrizes } from '../../api/hooks/useFetchPrizes';
import PrizesTableRow from './PrizesTableRow';
import { useTranslation } from 'react-i18next';
import { useDeletePrizeMutation } from '../../api/hooks/useDeletePrizeMutation';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const headCells: HeadCell<Prize>[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: '',
        sortable: false,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        sortable: false,
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'prize.category',
        sortable: false,
    },
    {
        id: 'label',
        numeric: false,
        disablePadding: false,
        label: 'prize.label',
        sortable: false,
    },
    {
        id: 'points',
        numeric: false,
        disablePadding: false,
        label: 'prize.points',
        sortable: false,
    },
    {
        id: 'ordersPerUser',
        numeric: false,
        disablePadding: false,
        label: 'prize.orders.per.user',
        sortable: false,
    },
    {
        id: 'availableUntil',
        numeric: false,
        disablePadding: false,
        label: 'prize.available.until',
        sortable: false,
    },
];

const tableCellHeight = 73;

interface Props {
    campaignId: string;
    segments: string[];
    onPrizeEditPressed: (id: string) => void;
}

const PrizesOverview: React.FC<Props> = ({ campaignId, segments, onPrizeEditPressed }) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Prize>('name');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [prizeToBeDeleted, setPrizeToBeDeleted] = useState<Prize | null>(null);

    const { t } = useTranslation();

    const prizesQuery = useFetchPrizes(campaignId, pageSize, segments);
    const deletePrizeMutation = useDeletePrizeMutation();

    useEffect(() => {
        if (deletePrizeMutation.isSuccess) {
            setPrizeToBeDeleted(null);
            prizesQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deletePrizeMutation.isSuccess]);

    const onDeletePrizePressed = (prize: Prize) => {
        setPrizeToBeDeleted(prize);
    };

    const onConfirmDeletePrize = () => {
        if (prizeToBeDeleted) {
            deletePrizeMutation.mutate(prizeToBeDeleted.id);
        }
    };

    const onEditPrizePressed = onPrizeEditPressed;
    const onDeletePrizeOptionPressed = (prizeId: string, id: string) => {};
    const onPrizeOptionEditSuccess = () => prizesQuery.refetch();


    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Prize) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (prizesQuery.data && prizesQuery.data.pages.length <= newPage) {
            prizesQuery.fetchNextPage();
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderSkeleton = (): ReactNode => {
        return Array.from({ length: pageSize }, (_, index) => (
            <TableRow hover tabIndex={-1} key={index.toString()} sx={{ height: tableCellHeight }}>
                <TableCell width={30}>
                    <Skeleton variant={'text'} />
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell key={headCell.label}>
                        <Skeleton variant={'text'} width={100} />
                    </TableCell>
                ))}
                <TableCell width={80}>
                    <Skeleton variant={'text'} />
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={2}>
                <TableContainer>
                    <MuiTable>
                        <TableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
                        <TableBody>
                            {prizesQuery.data?.pages[page]
                                ? prizesQuery.data.pages[page].sort(getComparator(order, orderBy)).map(row => (
                                      <Fragment key={row.id}>
                                          <PrizesTableRow
                                              prize={row}
                                              headCells={headCells}
                                              onPrizeDeletePressed={() => onDeletePrizePressed(row)}
                                              onPrizeEditPressed={onEditPrizePressed}
                                              onPrizeOptionDeletePressed={onDeletePrizeOptionPressed}
                                              onPrizeOptionEditSuccess={onPrizeOptionEditSuccess}
                                          />
                                      </Fragment>
                                  ))
                                : renderSkeleton()}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TablePagination
                    count={prizesQuery.data ? (prizesQuery.hasNextPage ? -1 : prizesQuery.data.pages.flatMap(p => p).length) : 0}
                    page={page}
                    handleChangePage={handleChangePage}
                    rowsPerPage={pageSize}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <ConfirmationDialog
                title={t('delete.prize.title', { name: prizeToBeDeleted?.name })}
                description={t('delete.prize.description', { name: prizeToBeDeleted?.name })}
                onConfirm={onConfirmDeletePrize}
                onCancel={() => setPrizeToBeDeleted(null)}
                open={!!prizeToBeDeleted}
            />
        </Box>
    );
};

export default PrizesOverview;
