import { Box, TextField, useTheme, Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import useAddCampaignMutation from '../../api/hooks/useAddCampaignMutation';
import { format } from 'date-fns';
import { ExternalAddCampaignVariables } from '../../api/models/external-models/ExternalAddCampaignVariables';
import S from '../../components/CreateOrUpdateModal.styles';
import CreateOrUpdateModal from '../../components/CreateOrUpdateModal';
import MultiLingualInput from '../../components/MultiLingualInput';
import { useSegmentsQuery } from '../../api/hooks/useSegmentsQuery';
import { AllLanguagesString } from '../../api/models/AllLanguagesString';

interface Props {
    open: boolean;
    onClose: (created?: boolean) => void;
}

const createInitialValues = (): ExternalAddCampaignVariables => ({
    name: {
        EN: '',
        FR: '',
        NL: '',
    },
    segments: [],
    end_date: '',
    start_date: '',
});

const CreateCampaignModal: React.FC<Props> = ({ open, onClose }) => {
    const addCampaignMutation = useAddCampaignMutation();
    const theme = useTheme();
    const segmentsQuery = useSegmentsQuery();

    const [addCampaignVariables, setAddCampaignVariables] = useState<ExternalAddCampaignVariables>(createInitialValues());
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (open) {
            setAddCampaignVariables(createInitialValues());
            setStartDate(null);
            setEndDate(null);
        }
    }, [open]);

    useEffect(() => {
        if (addCampaignMutation.isSuccess) {
            onClose(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCampaignMutation.isSuccess]);

    const onChangeName = (value: string, language: keyof AllLanguagesString) => {
        const updatedAddCampaignVariables = { ...addCampaignVariables };
        updatedAddCampaignVariables.name[language] = value;
        setAddCampaignVariables(updatedAddCampaignVariables);
    };

    const createCampaign = () => {
        if (areFieldsValid()) {
            addCampaignMutation.mutate({
                ...addCampaignVariables,
                start_date: format(startDate!, 'yyyy-MM-dd'),
                end_date: endDate ? format(endDate!, 'yyyy-MM-dd') : undefined,
            });
        }
    };
    const onCancelClicked = () => {
        if (!open) return;

        onClose();
    };
    const areFieldsValid = (): boolean => {
        const namesAreValid = addCampaignVariables.name.EN && addCampaignVariables.name.FR && addCampaignVariables.name.NL;
        return !!namesAreValid && !!startDate && (!!endDate ? startDate < endDate : true);
    };

    const onAddSegment = (event: any, newValue: string[]) => {
        if (newValue) {
            const newVariables: ExternalAddCampaignVariables = {
                name: { ...addCampaignVariables.name },
                segments: newValue,
                end_date: addCampaignVariables.end_date,
                start_date: addCampaignVariables.start_date,
            };
            setAddCampaignVariables(newVariables);
        }
    };

    return (
        <CreateOrUpdateModal
            title={t('create.new.campaign')}
            onCreate={createCampaign}
            isLoading={addCampaignMutation.isLoading}
            open={open}
            onCancel={onCancelClicked}
            areFieldsValid={areFieldsValid}>
            <S.PaddedBox>
                <MultiLingualInput title={t('name')} onChangeValue={onChangeName} value={addCampaignVariables.name} isRequired />
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                    <DatePicker
                        inputFormat={'dd/MM/yyyy'}
                        label={`${t('campaign.start.date')} *`}
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={params => <TextField {...params} variant={'standard'} sx={{ mr: 2, flex: 1 }} />}
                    />
                    <DatePicker
                        inputFormat={'dd/MM/yyyy'}
                        label={t('campaign.end.date')}
                        value={endDate}
                        onChange={setEndDate}
                        renderInput={params => <TextField {...params} variant={'standard'} sx={{ flex: 1 }} />}
                    />
                </Box>
                {segmentsQuery.data && (
                    <Autocomplete
                        sx={{ marginTop: theme.spacing(2) }}
                        multiple
                        value={addCampaignVariables.segments}
                        onChange={onAddSegment}
                        options={segmentsQuery.data}
                        getOptionLabel={option => option}
                        renderInput={params => <TextField {...params} variant={'standard'} label={t('segments')} placeholder={t('segments')} />}
                    />
                )}
            </S.PaddedBox>
        </CreateOrUpdateModal>
    );
};

export default CreateCampaignModal;
