import { styled } from '@mui/material/styles';
import { Box, Button, Paper } from '@mui/material';

export const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 10,
}));

export const ActionButtons = styled(Button)(({ theme }) => ({
    marginLeft: 10,
    minWidth: 150,
    padding: 10,
}));

export const ContentPaper = styled(Paper)(({ theme }) => ({
    padding: 15,
    overflow: 'scroll',
    maxHeight: '70vh',
}));
