import { listAll, ref, getStorage, getDownloadURL } from 'firebase/storage';
import { useQuery } from 'react-query';
import config from '../../config';
import firebase from '../../firebase';

const storage = getStorage(firebase.app, `gs://${config.firebase.storageBucket}`);

const removeExtension = (filename: string) => {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
};

const fetchProductImages = () => {
    return listAll(ref(storage, '/flametime')).then(result =>
        Promise.all(
            result.items.map(async item => ({
                url: await getDownloadURL(ref(storage, item.fullPath)),
                name: removeExtension(item.name),
            })),
        ),
    );
};

export const useFetchProductImages = () => {
    return useQuery('fetchProductImages', fetchProductImages);
};
