import React, { ReactNode } from 'react';
import { Modal, CircularProgress, Divider, Button, IconButton, Box } from '@mui/material';
import S from './CreateOrUpdateModal.styles';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';

interface Props {
    title: string;
    onCreate: () => void;
    children: ReactNode;
    isLoading: boolean;
    open: boolean;
    onCancel?: (created?: boolean) => void;
    areFieldsValid: () => boolean;
    isUpdateModal?: boolean;
    buttonText?: string;
}

const CreateOrUpdateModal: React.FC<Props> = ({
    open,
    title,
    children,
    onCreate,
    isLoading,
    onCancel,
    areFieldsValid,
    isUpdateModal = false,
    buttonText,
}) => {
    const { t } = useTranslation();
    const onCloseModal = (event: Object, reason: string) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            onCancel && onCancel();
        }
    };

    return (
        <Modal open={open} onClose={onCloseModal} disableEscapeKeyDown>
            <S.Container>
                {isLoading && (
                    <S.ProgressBox>
                        <CircularProgress />
                    </S.ProgressBox>
                )}
                <S.Header>
                    <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <S.Title variant="h4">{title}</S.Title>
                        {onCancel && (
                            <Box sx={{ mr: 1 }}>
                                <IconButton size={'large'} onClick={() => onCancel()} type={'reset'}>
                                    <Close />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                    <Divider />
                </S.Header>
                <S.Body>{children}</S.Body>
                <S.Footer>
                    <Divider />
                    <S.FooterInnerContainer>
                        <Button onClick={onCreate} disabled={!areFieldsValid()}>
                            {buttonText || (!isUpdateModal ? t('create') : t('save'))}
                        </Button>
                    </S.FooterInnerContainer>
                </S.Footer>
            </S.Container>
        </Modal>
    );
};

export default CreateOrUpdateModal;
