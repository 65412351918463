import { useEffect, useContext } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { History, Transition } from 'history';

export const useUnsavedChangesBlock = (hasUnsavedChanges: boolean, message: string) => {
    const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

    useEffect(() => {
        if (!hasUnsavedChanges) return;

        const unblock = navigator.block((tx: Transition) => {
            if (window.confirm(message)) {
                // Unblock the navigation.
                unblock();

                // Retry the transition.
                tx.retry();
            }
        });
        
        return unblock;
    }, [hasUnsavedChanges, message, navigator]);
};
