import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import i18n from '../i18n';
import okta from '../okta';
import { GlobalConstants } from '../constants';

export const api = axios.create({
    baseURL: config.baseUrl + GlobalConstants.LOYALTY_PATH,
});

export const mailApi = axios.create({
    baseURL: config.baseUrl + GlobalConstants.MAIL_PATH,
});

export const functions = axios.create({
    baseURL: config.functionsBaseUrl,
});

const onFulfilled = (config: AxiosRequestConfig) => {
    if (config.params instanceof URLSearchParams) {
        const urlSearchParams = config.params as URLSearchParams;
        if (!urlSearchParams.has('language')) {
            urlSearchParams.append('language', i18n.language.toUpperCase());
        }
    } else if (!config.params?.language) {
        config.params = {
            ...(config.params || {}),
            language: i18n.language.toUpperCase(),
        };
    }
    config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${okta.getAccessToken()}`,
    };
    return config;
};
api.interceptors.request.use(onFulfilled);
mailApi.interceptors.request.use(onFulfilled);
functions.interceptors.request.use(onFulfilled);
