import { ExternalCampaigns } from '../external-models/ExternalCampaigns';
import { Campaign } from '../Campaign';
import { ExternalCampaign } from '../external-models/ExternalCampaign';
import { DateUtil } from '../../../utils/DateUtil';

export class CampaignTransformer {
    static transformCampaigns(campaigns: ExternalCampaigns): Campaign[] {
        return campaigns.campaigns.map(CampaignTransformer.transformCampaign);
    }

    static transformCampaign(campaign: ExternalCampaign): Campaign {
        return {
            segments: campaign.segments,
            endDate: DateUtil.formatDate(campaign.end_date),
            startDate: DateUtil.formatDate(campaign.start_date),
            id: campaign.id,
            name: campaign.name,
        };
    }
}
