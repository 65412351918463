import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEditAppConfiguration } from '../../api/hooks/firestore/useEditAppConfiguration';
import { useFetchAppConfiguration } from '../../api/hooks/firestore/useFetchAppConfiguration';
import { FeatureFlagsTab } from './tabs/feature-flags-tab/FeatureFlagsTab';
import * as S from './AppConfiguration.styles';
import { SyntheticEvent, useState } from 'react';
import { TabPanel } from '../../components/TabPanel';
import { AppSettingsTab } from './tabs/app-settings-tab/AppSettingsTab';
import { DocumentData } from 'firebase/firestore';
import { useUnsavedChangesBlock } from '../../hooks/useUnsavedChangesBlock';

export const AppConfiguration = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(0);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const fetchAppConfig = useFetchAppConfiguration();
    const editAppConfig = useEditAppConfiguration(fetchAppConfig.data);
    useUnsavedChangesBlock(hasUnsavedChanges, t('unsaved.changes'));

    const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
        if (hasUnsavedChanges) {
            if (window.confirm(t('unsaved.changes'))) {
                changeTab(newValue);
            }
        } else {
            changeTab(newValue);
        }
    };

    const changeTab = (tab: number) => {
        setHasUnsavedChanges(false);
        setTab(tab);
    };

    const updateSettings = (changes: DocumentData) => {
        editAppConfig.update(changes);
    };

    return (
        <Box>
            <S.HeaderContainer>
                <Typography variant={'h4'} gutterBottom sx={{ fontWeight: '500' }}>
                    {t('app.configuration')}
                </Typography>
                <S.TabsContainer value={tab} onChange={handleTabChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                    <S.NavTab label={t('features')} id={'Features'} />
                    <S.NavTab label={t('settings')} id={'Settings'} />
                </S.TabsContainer>
            </S.HeaderContainer>

            <Divider />

            {fetchAppConfig.isLoading ? (
                <Box sx={{ flex: 1 }}>
                    <CircularProgress />
                </Box>
            ) : fetchAppConfig.isError || !fetchAppConfig.data ? (
                <Box>
                    <Typography variant={'h5'} gutterBottom sx={{ fontWeight: '500' }}>
                        {t('error')}
                    </Typography>
                </Box>
            ) : (
                <>
                    <TabPanel activeTab={tab} index={0}>
                        <FeatureFlagsTab data={fetchAppConfig.data} onChangeSettings={updateSettings} onUnsavedChanges={setHasUnsavedChanges} />
                    </TabPanel>
                    <TabPanel activeTab={tab} index={1}>
                        <AppSettingsTab data={fetchAppConfig.data} onChangeSettings={updateSettings} onUnsavedChanges={setHasUnsavedChanges} />
                    </TabPanel>
                </>
            )}
        </Box>
    );
};
