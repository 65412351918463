import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { api } from '../index';
import { ExternalEvents } from '../models/external-models/ExternalEvents';
import { EventTransformer } from '../models/transformers/EventTransformer';

const fetchEvents = ({ queryKey, pageParam }: QueryFunctionContext) => {
    const params = new URLSearchParams();
    params.append('page-size', queryKey[2] as string);
    params.append('campaign', queryKey[1] as string);
    if (pageParam) {
        params.append('page', pageParam as string);
    }
    if (!!queryKey[3] && !!queryKey[4]) {
        params.append('sort-by', queryKey[3] as string);
        params.append('sort-direction', queryKey[4] as string);
    }
    return api.get<ExternalEvents>('/events', { params });
};

export const useFetchEvents = (campaignId: string, pageSize: number, orderBy?: string, sortDirection?: string) => {
    return useInfiniteQuery(['fetchEvents', campaignId, pageSize, orderBy, sortDirection], fetchEvents, {
        select: response => ({
            pages: response.pages.map(page => EventTransformer.transformEvents(page.data)),
            pageParams: response.pageParams,
        }),
        getPreviousPageParam: firstPage => firstPage.data.previous_page,
        getNextPageParam: lastPage => lastPage.data.next_page,
        keepPreviousData: true,
    });
};
