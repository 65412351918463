import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as S from './InvitationCardsTab.styles';
import { InvitationCardsTable } from '../../../components/invitation-cards-table/InvitationCardsTable';
import { useNavigate } from 'react-router-dom';
import { useFetchInvitationCards } from '../../../api/hooks/firestore/useFetchInvitationCards';
import { SendNewEmailModal } from '../../../components/send-new-email-modal/SendNewEmailModal';
import { useEffect, useRef, useState } from 'react';
import { InvitationCard } from '../../../api/models/firestore/InvitationCard';
import { useMutateInvitationCards } from '../../../api/hooks/firestore/useMutateInvitationCards';
import ActionButton from '../../../models/ActionButton';
import { useMutateInvitationCardsMails } from '../../../api/hooks/useMutateInvitationCardsMails';
import { useMutateInvitationCardsSendout } from '../../../api/hooks/firestore/useMutateInvitationCardsSendout';
import { findLastSeasonUuid } from '../../../utils/SeasonsUtil';
import { Role } from '../../../enums/Role';
import { useCheckBackofficeRoles } from '../../../hooks/useCheckBackofficeRoles';
const InvitationCardsTemplateExcel = require('../../../files/invites_template.xlsx');

export const InvitationCardsTab = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const downloadRef = useRef<HTMLAnchorElement>(null);
    const [invitationCardToSend, setInvitationCardToSend] = useState<InvitationCard>();
    const [invitationBeingSend, setInvitationBeingSend] = useState<InvitationCard>();
    const hasEditRole = useCheckBackofficeRoles([Role.EDIT])

    const fetchInvitationCards = useFetchInvitationCards();
    const mutateInvitationCards = useMutateInvitationCards();
    const mutateSendInvitationCardsMail = useMutateInvitationCardsMails()
    const mutateInvitationCardSendout = useMutateInvitationCardsSendout()

    useEffect(() => {
        if (mutateSendInvitationCardsMail.isSuccess && invitationBeingSend){
            mutateInvitationCardSendout.mutateSingle(invitationBeingSend, findLastSeasonUuid(invitationBeingSend))
            setInvitationBeingSend(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateSendInvitationCardsMail.isSuccess, invitationBeingSend])

    const onSend = (email: string, shouldUpdate?: boolean) => {
        if (invitationCardToSend) {
            const card = {
                ...invitationCardToSend,
                email: email,
            }
            if (shouldUpdate) {
                mutateInvitationCards.mutate(card);
            }
            mutateSendInvitationCardsMail.mutate([card])
            setInvitationBeingSend(card)
        }
        setInvitationCardToSend(undefined);
    };

    const onCancelSendEmail = () => {
        setInvitationCardToSend(undefined);
    };

    const clickUploadButton = () => {
        navigate('upload');
    };

    const onClickTemplateButton = () => {
        downloadRef.current?.click();
    };

    const actionButtons: ActionButton[] = [
        {
            actionButtonClicked: onClickTemplateButton,
            actionButtonText: t('download.template'),
            variant: 'outlined',
        },
        {
            actionButtonClicked: clickUploadButton,
            actionButtonText: t('upload'),
        },
    ];

    return (
        <Box>
            <a href={InvitationCardsTemplateExcel} target={'_blank'} rel={'noreferrer'} ref={downloadRef} hidden>
                template
            </a>
            <SendNewEmailModal
                open={!!invitationCardToSend}
                email={invitationCardToSend?.email || ''}
                onSend={onSend}
                onCancel={onCancelSendEmail}
                warningText={t('cards.invitation.update.email.warning')}
                shouldAskToUpdate
            />
            {fetchInvitationCards.isLoading ? (
                <S.LoadingBox>
                    <CircularProgress />
                </S.LoadingBox>
            ) : (
                <InvitationCardsTable
                    data={fetchInvitationCards.invitationCards}
                    actionButtons={hasEditRole ? actionButtons : undefined}
                    onSendSingleClicked={setInvitationCardToSend}
                />
            )}
        </Box>
    );
};
