import { api } from '../index';
import { useMutation } from 'react-query';
import { ExternalUpdateVariables } from '../models/external-models/ExternalUpdateVariables';
import { ExternalSingleEventVariables } from '../models/external-models/ExternalSingleEventVariables';

const editEventMutation = (variables: ExternalUpdateVariables) => {
    return api.patch<ExternalSingleEventVariables>(`/event?id=${variables.id}`, variables.updateObjects);
};

export const useEditEventMutation = () => {
    return useMutation('editEventMutation', editEventMutation);
};
