import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Pass = styled(Box)<{ color: string} >(({ theme,color }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: color,
    borderRadius: 10,
    width: 320,
    overflow: 'hidden',
    height: 570,
    margin: 5,
}));

export const PassHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 5,
    padding: 15,
}));

export const LogoImage = styled(Box)(({ theme }) => ({
    height: 'auto',
    width: 30,
    borderRadius: 200,
    marginRight: 10
}));

export const Title = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF',
}));

export const RowContainer = styled(Box)<{ justifyContent?: string }>(({ theme, justifyContent }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: justifyContent || 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: 'center',
}));

export const QrContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 15,
    alignItems: 'flex-end',
    marginBottom: 30,
    backgroundColor: 'white',
    borderRadius: 10,
}));

export const InfoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const InfoText = styled(Typography)(({ theme }) => ({
    color: '#FFFFFF',
}));

export const Image = styled(Box)(({ theme }) => ({
    marginTop: 15,
    width: '25%',
    height: 'auto',
    maxHeight: '75px'
}));
