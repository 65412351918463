import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            light: '#d7bc7b',
            dark: '#735f23',
            main: '#a48c4e',
        },
        secondary: {
            main: '#F9FAFB',
            light: '#FFFFFF',
            dark: '#EAECF0',

        },
        background: {
            default: '#F9FAFB',
        },
        text: {
            primary: '#000000',
            secondary: '#757575',
            
        }
    },
});

export default theme;
