import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from '../index';
import { CampaignTransformer } from '../models/transformers/CampaignTransformer';
import { ExternalCampaign } from '../models/external-models/ExternalCampaign';

const fetchCampaign = ({ queryKey }: QueryFunctionContext) => {
    return api.get<{ campaign: ExternalCampaign } & Response>('/campaign', {
        params: {
            campaign: queryKey[1],
        },
    });
};

export const useFetchCampaign = (campaignId: string) => {
    return useQuery(['fetchCampaigns', campaignId], fetchCampaign, {
        select: response => CampaignTransformer.transformCampaign(response.data.campaign),
    });
};
