import PrizesOverview from '../prizes/PrizesOverview';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, CircularProgress, IconButton, Snackbar, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchCampaign } from '../../api/hooks/useFetchCampaign';
import { AxiosError } from 'axios';
import { alpha, styled } from '@mui/material/styles';
import CreateOrUpdatePrizeModal from '../prizes/CreateOrUpdatePrizeModal';
import EventsOverview from '../events/EventsOverview';
import { useQueryClient } from 'react-query';
import CreateOrUpdateEventModal from '../events/CreateOrUpdateEventModal';
import { AllInclusiveOutlined, Download, Add, ArrowBack } from '@mui/icons-material';
import { useFetchEvents } from '../../api/hooks/useFetchEvents';
// Imported with as because react already has a type called Event
import { Event as CampaignEvent } from '../../api/models/Event';
import config from '../../config';
import CsvColumn from '../../models/CsvColumn';
import { CsvUtil } from '../../utils/CsvUtil';
import { TabPanel } from '../../components/TabPanel';
import OrdersOverview from '../orders/OrdersOverview';
import { Role } from '../../enums/Role';
import { useCheckBackofficeRoles } from '../../hooks/useCheckBackofficeRoles';

const TitleBox = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
}));

const createCsvColumnsObj = (): CsvColumn[] => {
    return [
        {
            place: 0,
            columnName: 'Partner',
            param: 'partner',
        },
        {
            place: 1,
            columnName: 'Name',
            param: 'name',
        },
        {
            place: 2,
            columnName: 'Start date',
            param: 'startDate',
        },
        {
            place: 3,
            columnName: 'End date',
            param: 'endDate',
        },
        {
            place: 4,
            columnName: 'Points',
            param: 'points',
        },
        {
            place: 5,
            columnName: 'Redemptions per user',
            param: 'redemptionsPerUser',
        },
        {
            place: 6,
            columnName: 'QR Code',
            param: createQrField,
        },
    ];
};

const createQrField = (event: Object) => {
    return `${config.app.baseUrl}${config.app.qrCodePath}/${(event as CampaignEvent).id}`;
};

const CampaignDetail = () => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarText, setSnackBarText] = useState('');
    const [isPrizeModalVisible, setIsPrizeModalVisible] = useState(false);
    const theme = useTheme();
    const [isEventModalVisible, setIsEventModalVisibile] = useState(false);
    const { t } = useTranslation();
    const { id } = useParams<'id'>();
    const campaignQuery = useFetchCampaign(id as string);
    const queryClient = useQueryClient();
    const [editablePrizeId, setEditablePrizeId] = useState<string>();
    const [editableEventId, setEditableEventId] = useState<string>();
    const [hasPressedDownloadEvents, setHasPressedDownloadEvents] = useState(false);
    const [tab, setTab] = useState<number>(0);
    const eventsQuery = useFetchEvents(id!, 100);
    const navigate = useNavigate();
    const hasEditRole = useCheckBackofficeRoles([Role.EDIT])

    useEffect(() => {
        if (!hasEditRole) {
            setTab(2);
        }
    }, [hasEditRole]);

    useEffect(() => {
        if (hasPressedDownloadEvents && !eventsQuery.isLoading) {
            if (eventsQuery.hasNextPage) {
                eventsQuery.fetchNextPage();
            } else {
                let array = eventsQuery.data?.pages.flat(1);
                if (array) {
                    const csvColumns = createCsvColumnsObj();
                    CsvUtil.createCsv(array, csvColumns, 'event-export');
                }
                setHasPressedDownloadEvents(false);
            }
        }
    }, [eventsQuery, hasPressedDownloadEvents]);

    const showEventModal = () => setIsEventModalVisibile(true);

    const onSuccess = (message: string) => {
        setSnackBarText(message);
        setShowSnackbar(true);
    };

    const onCloseCreateEventModal = (created?: boolean) => {
        setIsEventModalVisibile(false);
        setEditableEventId(undefined);
        if (created) {
            campaignQuery.refetch();
            queryClient.refetchQueries(['fetchEvents', id]);
            onSuccess(t('event.created'));
        }
    };

    if (campaignQuery.isError) {
        return <Alert severity={'error'}>{t('campaign.fetch.error', { errorCode: (campaignQuery.error as AxiosError)?.code })}</Alert>;
    }

    const onClosePrizeModal = (created?: boolean) => {
        setIsPrizeModalVisible(false);
        setEditablePrizeId(undefined);
        if (created) {
            campaignQuery.refetch();
            queryClient.refetchQueries(['fetchPrizes', id]);
            onSuccess(t('prize.created'));
        }
    };
    const onClickCreateNewPrize = () => {
        setIsPrizeModalVisible(true);
        setShowSnackbar(false);
    };
    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    const onPrizeEditPressed = (id: string) => {
        setEditablePrizeId(id);
        onClickCreateNewPrize();
    };

    const onEventEditPressed = (id: string) => {
        setEditableEventId(id);
        showEventModal();
    };

    const onClickExportEvents = () => {
        setHasPressedDownloadEvents(true);
    };

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const tabStyle = {
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
        borderRadius: 3,
        alignSelf: 'center',
    };

    const onClickBack = () => {
        navigate('../');
    };

    return campaignQuery.data ? (
        <Box>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarText}
                </Alert>
            </Snackbar>
            {campaignQuery.data && isPrizeModalVisible && (
                <CreateOrUpdatePrizeModal
                    open={isPrizeModalVisible}
                    onClose={onClosePrizeModal}
                    campaign={campaignQuery.data}
                    prizeId={editablePrizeId}
                />
            )}
            <Stack>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconButton sx={{ ml: -1 }} onClick={onClickBack}>
                        <ArrowBack sx={{ color: theme.palette.text.primary }} />
                    </IconButton>
                    <Typography variant={'h5'} sx={{ fontWeight: '500', display: 'inline', m: 0 }} gutterBottom>
                        {t('campaign.name', { name: campaignQuery.data.name })}
                    </Typography>
                    {hasEditRole && (
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            sx={{ marginLeft: 5, display: 'flex', alignItems: 'center' }}
                            TabIndicatorProps={{ style: { display: 'none' } }}>
                            <Tab label={'Events'} id={'Events'} sx={tabStyle} />
                            <Tab label={'Prizes'} id={'Prizes'} sx={tabStyle} />
                            <Tab label={'Orders'} id={'Orders'} sx={tabStyle} />
                        </Tabs>
                    )}
                </Box>
                <Box sx={{ paddingBottom: theme.spacing(2) }}>
                    <Typography>{`${t('start.date')}: ${campaignQuery.data.startDate}`}</Typography>
                    <Stack direction={'row'}>
                        <Typography>{`${t('end.date')}: ${campaignQuery.data.endDate}`}&nbsp;</Typography>
                        {!!campaignQuery.data.endDate || <AllInclusiveOutlined />}
                    </Stack>
                    <Typography>{`${t('segments')}: ${campaignQuery.data.segments.join(',')}`}</Typography>
                </Box>
                <>
                    {hasEditRole && (
                        <>
                            <TabPanel activeTab={tab} index={0}>
                                <TitleBox>
                                    <Typography variant={'h5'} sx={{ fontWeight: '500', display: 'inline' }}>
                                        {t('events')}
                                    </Typography>
                                    <Box sx={{ alignSelf: 'flex-start' }}>
                                        <Button
                                            variant={'outlined'}
                                            sx={{ marginRight: 2 }}
                                            onClick={onClickExportEvents}
                                            disabled={hasPressedDownloadEvents}>
                                            {!hasPressedDownloadEvents ? (
                                                <Download sx={{ paddingRight: 1 }} />
                                            ) : (
                                                <>
                                                    <CircularProgress size={'1rem'} color={'inherit'} />
                                                    <Box sx={{ paddingLeft: 2 }}></Box>
                                                </>
                                            )}
                                            {t('export')}
                                        </Button>
                                        <Button variant={'contained'} onClick={showEventModal}>
                                            <Add sx={{ paddingRight: 1 }} />
                                            {t('create.new.event')}
                                        </Button>
                                    </Box>
                                </TitleBox>
                                <EventsOverview campaignId={id as string} onEventEditPressed={onEventEditPressed} />
                            </TabPanel>
                            <TabPanel activeTab={tab} index={1}>
                                <TitleBox>
                                    <Typography variant={'h5'} sx={{ fontWeight: '500', display: 'inline' }}>
                                        {t('prizes')}
                                    </Typography>
                                    <Button variant={'contained'} sx={{ alignSelf: 'flex-start' }} onClick={onClickCreateNewPrize}>
                                        <Add sx={{ paddingRight: 1 }} />
                                        {t('create.new.prize')}
                                    </Button>
                                </TitleBox>
                                <PrizesOverview
                                    campaignId={id as string}
                                    segments={campaignQuery.data?.segments}
                                    onPrizeEditPressed={onPrizeEditPressed}
                                />
                            </TabPanel>
                        </>
                    )}

                    <TabPanel activeTab={tab} index={2}>
                        <OrdersOverview campaignId={id} />
                    </TabPanel>
                </>
            </Stack>
            {isEventModalVisible && (
                <CreateOrUpdateEventModal
                    open={isEventModalVisible}
                    onClose={onCloseCreateEventModal}
                    campaignId={id as string}
                    eventId={editableEventId}
                />
            )}
        </Box>
    ) : (
        <CircularProgress />
    );
};

export default CampaignDetail;
