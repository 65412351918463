import CsvColumn from '../models/CsvColumn';

export class CsvUtil {
    static createCsv(obj: Object[], cols: CsvColumn[], fileName: string) {
        // Create the csv file
        const csvString = this.generateCsvString(obj, cols);
        const dataUri = 'data:text/csv;charset=utf-8,' + csvString;
        const exportFileDefaultName = fileName + '.csv';

        // Download the Created csv file
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    private static generateCsvString(objs: Object[], cols: CsvColumn[]) {
        const columnDelimiter = ';';
        const lineDelimiter = '\n';

        // Sort the columns if needed
        let columns = cols;
        if (cols.every(objs => objs.place)) {
            columns = cols.sort((a, b) => (a.place! < b.place! ? -1 : 1));
        }

        // Generate the head of the table
        let headString = '';
        columns.forEach(item => {
            headString += `${item.columnName}${columnDelimiter}`;
        });

        // Ganerate the rows
        let csvString = headString.slice(0, -1) + lineDelimiter;
        objs.forEach(obj => {
            columns.forEach(col => {
                if (typeof col.param === 'function') {
                    csvString += `${col.param(obj)}${columnDelimiter}`;
                } else {
                    csvString += `${this.getParamValue(obj, col.param)}${columnDelimiter}`;
                }
            });
            csvString = csvString.slice(0, -1) + lineDelimiter;
        });

        return encodeURIComponent(csvString);
    }

    private static getParamValue(obj: Object, param: string) {
        let val = obj;
        param.split('.').forEach(item => {
            if (val.hasOwnProperty(item)) {
                //@ts-ignore
                val = val[item];
            } else {
                return undefined;
            }
        });
        return val;
    }
}
