import { Order } from '../Order';
import { User } from '../User';
import { ExportOrderRecord } from '../ExportOrderRecord';
import { DateUtil } from '../../../utils/DateUtil';

export class ExportOrderRecordTransformer {
    static transformExportOrderRecord(order: Order, prizes: string[], user?: User): ExportOrderRecord {
        return {
            id: order.id,
            name: order.address.name,
            language: order.language,
            email: user?.email,
            orderDate: DateUtil.formatDate(order.orderDate),
            prizes: prizes.every(v => !v) ? undefined : prizes,
            address: {
                name: order.address.name,
                phone: order.address.phone,
                street: order.address.street,
                postalCode: order.address.postalCode,
                city: order.address.city,
                country: order.address.country,
            },
        };
    }
}
