import { Box, MenuItem, Paper, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleAndActionContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    flexWrap: 'wrap',
}));

export const TablePaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    marginBottom: 20,
}));

export const ActionsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const Dropdown = styled(Select)(({ theme }) => ({
    width: 200,
    marginBottom: 20,
    marginRight: 20,
    textAlign: 'center',
    height: 38,
}));

export const DropdownItem = styled(MenuItem)(({ theme }) => ({
    textAlign: 'center',
    justifyContent: 'center',
    marginRight: 17,
}));
