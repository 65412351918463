import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import firebase from '../../../firebase';
import { isEqual } from 'lodash';
import { InvitationCardsSettings } from '../../models/firestore/InvitationCardsSettings';
import { GlobalConstants } from '../../../constants';

export const useFetchInvitationCardsSettings = () => {
    const [settings, setSettings] = useState<InvitationCardsSettings>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const firestore = getFirestore(firebase.app);
    const invitationCardsSettings = doc(firestore, GlobalConstants.INVITATION_CARDS.BASE_DOCS, GlobalConstants.INVITATION_CARDS.SETTINGS_DOCS);
    getDoc(invitationCardsSettings)
        .then(docData => {
            const newData = docData.data();
            if (!!newData && !isEqual(newData, settings)) {
                setError(undefined);
                setSettings(newData as InvitationCardsSettings);
            }
        })
        .catch(err => {
            setError(err);
        });

    useEffect(() => {
      if (settings || error) {
        setIsLoading(false)
      }
    }, [settings, error])

    return {
        settings: settings,
        isLoading: isLoading,
        isError: !!error,
    };
};
