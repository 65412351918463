import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Language } from '../enums/Language';
import { AllLanguagesString } from '../api/models/AllLanguagesString';

interface Props {
    title: string;
    onChangeValue: (text: string, language: keyof AllLanguagesString) => void;
    value: AllLanguagesString;
    inputProps?: any;
    validator?: (value: string) => boolean;
    validatorText?: string;
    isRequired?: boolean;
}

const MultiLingualInput: React.FC<Props> = ({ title, onChangeValue, value, inputProps, validator, validatorText, isRequired }) => {
    const { t } = useTranslation();

    const getHelperText = (value: string): string | undefined => {
        if (validator?.(value)) {
            return validatorText;
        }
    };

    return (
        <Box mt={2}>
            <Typography mb={2}>{title}</Typography>
            <Box ml={2}>
                {Object.keys(Language).map(language => (
                    <TextField
                        key={language}
                        label={t(`language.${language.toLowerCase()}`) + (isRequired ? ' *' : '')}
                        variant={'standard'}
                        fullWidth
                        value={value[language as keyof AllLanguagesString]}
                        onChange={value => onChangeValue(value.target.value, language as keyof AllLanguagesString)}
                        sx={{ mb: 2 }}
                        {...inputProps}
                        error={validator ? validator(value[language as keyof AllLanguagesString]) : false}
                        helperText={getHelperText(value[language as keyof AllLanguagesString])}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default MultiLingualInput;
