import { styled } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';

export const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: 8,
    paddingLeft: 20,
    borderRadius: 5,
    backgroundColor: 'white',
    minWidth: 300,
    justifyContent: 'space-between',
    margin: 8,
    maxHeight: 56
}));
