import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import CampaignsOverview from './pages/campaign/CampaignsOverview';
import CampaignDetail from './pages/campaign/CampaignDetail';
import LoginLayout from './layouts/login/LoginLayout';
import RequireAuth from './components/RequireAuth';
import { LoginCallback } from '@okta/okta-react';
import LogoutLayout from './layouts/login/LogoutLayout';
import EventDetail from './pages/events/EventDetail';
import Dashboard from './pages/dashboard/Dashboard';
import { AppConfiguration } from './pages/app-configuration/AppConfiguration';
import { InvitationCards } from './pages/invitation-cards/InvitationCards';
import { InvitationCardsUpload } from './pages/invitation-cards-upload/InvitationCardsUpload';
import RequireRole from './components/require-role/RequireRole';
import { Role } from './enums/Role';

const loyaltyRoutes = [
    { path: '', element: <Navigate to="/backoffice/loyalty/campaigns" replace /> },
    { path: 'campaigns/', element: <CampaignsOverview /> },
    { path: 'campaigns/:id', element: <CampaignDetail /> },
    { path: 'campaigns/:id/event/:eventId', element: <EventDetail /> },
];

const invitationCardsRoutes = [
    { path: '', element: <InvitationCards /> },
    { path: 'upload', element: <InvitationCardsUpload /> },
];

const Routes = () => {
    return useRoutes([
        {
            path: 'backoffice',
            element: (
                <RequireAuth>
                    <DashboardLayout />
                </RequireAuth>
            ),
            children: [
                { path: '', element: <Dashboard /> },
                { path: 'loyalty', children: loyaltyRoutes },
                { path: 'app/configuration', element: <RequireRole roles={[Role.EDIT]}><AppConfiguration /></RequireRole> },
                { path: 'invitation-cards', children: invitationCardsRoutes},
            ],
        },
        { path: '/login', element: <LoginLayout /> },
        {
            path: '/logout',
            element: (
                <RequireAuth>
                    <LogoutLayout />
                </RequireAuth>
            ),
        },
        { path: '/redirect', element: <LoginCallback /> },
        { path: '*', element: <Navigate to="/backoffice" replace /> },
    ]);
};

export default Routes;
