import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { HeadCell } from './HeadCell';
import { TableObjectBase } from './TableObjectBase';
import { Order } from './Order';
import { useTranslation } from 'react-i18next';

interface Props<T extends TableObjectBase> {
    order?: Order;
    orderBy?: keyof T;
    onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    headCells: HeadCell<T>[];
    hideEndColumn?: boolean;
}

const descendingComparator = <T extends object>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order: Order, orderBy: keyof any): ((a: any, b: any) => number) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

const TableHead = <T extends TableObjectBase>({ headCells, onRequestSort, order, orderBy, hideEndColumn }: Props<T>) => {
    const { t } = useTranslation();
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort?.(event, property);
    };

    return (
        <MuiTableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id as string}
                        align={headCell.align || (headCell.numeric ? 'right' : 'left')}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        {order && headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label && t(headCell.label)}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            headCell.label && t(headCell.label)
                        )}
                    </TableCell>
                ))}
                {!hideEndColumn && <TableCell />}
            </TableRow>
        </MuiTableHead>
    );
};

export default TableHead;
