import { ExternalEvents } from '../external-models/ExternalEvents';
import { Event } from '../Event';
import { ExternalEvent } from '../external-models/ExternalEvent';
import { ExternalEventVariables } from '../external-models/ExternalEventVariables';
import { AllLanguagesString } from '../AllLanguagesString';

export class EventTransformer {
    static transformEvents(events: ExternalEvents): Event[] {
        return events.events.map(EventTransformer.transformEvent);
    }

    static transformEvent(event: ExternalEvent): Event {
        return {
            id: event.id,
            campaignId: event.campaign_id,
            endDate: event.end_date,
            startDate: event.start_date,
            confirmationMessage: event.confirmation_message,
            description: event.description,
            points: event.points,
            redemptionsPerUser: event.redemptions_per_user,
            videoUrl: event.video_url,
            websiteUrl: event.website_url,
            partner: event.partner,
            type: event.type,
            number: event.number,
            name: `${event.partner}-${event.type}-${event.number}`,
        };
    }

    static transformToEventVariables<T extends ExternalEventVariables>(event?: Event): T {
        return {
            campaign_id: event?.campaignId || '',
            start_date: event?.startDate || '',
            end_date: event?.endDate || '',
            points: event?.points || 0,
            redemptions_per_user: event?.redemptionsPerUser || 0,
            description: {
                EN: (event?.description as AllLanguagesString)?.EN || '',
                NL: (event?.description as AllLanguagesString)?.NL || '',
                FR: (event?.description as AllLanguagesString)?.FR || '',
            },
            confirmation_message: {
                EN: (event?.confirmationMessage as AllLanguagesString)?.EN || '',
                NL: (event?.confirmationMessage as AllLanguagesString)?.NL || '',
                FR: (event?.confirmationMessage as AllLanguagesString)?.FR || '',
            },
            website_url: {
                EN: (event?.websiteUrl as AllLanguagesString)?.EN || '',
                NL: (event?.websiteUrl as AllLanguagesString)?.NL || '',
                FR: (event?.websiteUrl as AllLanguagesString)?.FR || '',
            },
            video_url: {
                EN: (event?.videoUrl as AllLanguagesString)?.EN || '',
                NL: (event?.videoUrl as AllLanguagesString)?.NL || '',
                FR: (event?.videoUrl as AllLanguagesString)?.FR || '',
            },
            partner: event?.partner || '',
            type: event?.type || '',
            ...(!event && { amount: 1 }),
            ...(event?.number !== undefined && { number: event.number }),
        } as T;
    }
}
