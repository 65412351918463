import { Popover, PopoverProps } from '@mui/material';
import React from 'react';
import * as S from './MenuPopover.styles';

const MenuPopover: React.FC<PopoverProps> = props => {
    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={props.anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={props.transformOrigin || { vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 200,
                    overflow: 'inherit',
                    ...props.sx,
                },
            }}>
            <S.ArrowStyle direction={props.anchorOrigin?.horizontal} className="arrow" />
            {props.children}
        </Popover>
    );
};
export default MenuPopover;
