import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldUseLightContrast } from '../../utils/ColorUtil';

export const ColorButtonText = styled(Typography)<{ currentColor: string }>(({ theme, currentColor }) => ({
  color: shouldUseLightContrast(currentColor) ? '#FFFFFF' : theme.palette.text.primary,
  backgroundColor: currentColor,
  padding: 17,
  border: '1px solid black',
  borderRadius: 5,
}));