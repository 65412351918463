import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, CircularProgress } from '@mui/material';
import { toRelativeUrl } from '@okta/okta-auth-js';

const CenterBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(10),
}));

const LoginLayout = () => {
    const oktaAuth = useOktaAuth();
    const navigate = useNavigate();

    const originalUri = oktaAuth.oktaAuth.getOriginalUri();
    const redirectUri = originalUri ? toRelativeUrl(originalUri, window.location.origin) : '/dashboard/';

    useEffect(() => {
        if (oktaAuth.authState?.isAuthenticated) {
            navigate(redirectUri, { replace: true, });
        } 
        // This has to be a false check because else it wil also run when it is undefined (because it may not be initialised yet). 
        // So it would refresh the pages double when doing a refresh.
        else if (oktaAuth.authState?.isAuthenticated === false) {
            oktaAuth.oktaAuth.signInWithRedirect({ originalUri: redirectUri });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oktaAuth.authState?.isAuthenticated]);

    return (
        <CenterBox>
            <CircularProgress />
        </CenterBox>
    );
};

export default LoginLayout;
