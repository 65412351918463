import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LoadingBox = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
}));
