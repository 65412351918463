import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
    index: number
    activeTab: number
    children: ReactNode
}

export const TabPanel: React.FC<Props> = ({ index, activeTab, children }) => {
    return (
        <Box>
            {activeTab === index && children}
        </Box>
    )
}