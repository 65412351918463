import { useMutation } from 'react-query';
import { api } from '../index';
import { ExternalAddCampaignVariables } from '../models/external-models/ExternalAddCampaignVariables';

const addCampaign = (variables: ExternalAddCampaignVariables) => {
    return api.post('/campaign', variables);
};

const useAddCampaignMutation = () => {
    return useMutation('addCampaign', addCampaign);
};

export default useAddCampaignMutation;
