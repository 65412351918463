import { useOktaAuth } from '@okta/okta-react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = useOktaAuth();
    const location = useLocation();

    if (!auth.authState?.isAuthenticated) {
        auth.oktaAuth.setOriginalUri(location.pathname);
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
};

export default RequireAuth;
