import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import * as S from './SettingsArray.styles';
import { clone, isEqual } from 'lodash';
import { Add, Close, Delete, Edit, SaveAlt } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { SettingsTextInput } from '../settings-text-input/SettingsTextInput';
import { useTranslation } from 'react-i18next';

type Props = {
    parentKey: string;
    data: (string | number)[];
    onChange: (key: string, value: (string | number)[]) => void;
    saveSystem?: boolean;
    textInputValidationChecks?: (key: string, value: string | number) => boolean;
    onUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
};

export const SettingsArray: React.FC<Props> = ({ parentKey, data, onChange, saveSystem = false, textInputValidationChecks, onUnsavedChanges }) => {
    const { t } = useTranslation();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<(string | number)[]>(data);

    const dataToUse = saveSystem ? currentData : data;

    useEffect(() => {
        setCurrentData(data);
    }, [data]);

    useEffect(() => {
        if (saveSystem) {
            onUnsavedChanges?.(!isEqual(data, currentData))
        }
    }, [data, currentData, onUnsavedChanges, saveSystem]);

    const isNumber = dataToUse.length > 0 && typeof dataToUse[0] === 'number';
    const addNewItemToArray = () => {
        const newArray = clone(dataToUse);
        /***
         * @ts-ignore because it gives error about not being able to assign to never. That is because it can be an array of number or array of strings.
         * But in this case it doesn't matter because we make it a number if needed
         */
        // @ts-ignore
        newArray.push(isNumber ? 0 : '');
        saveArray(newArray);
    };
    const shouldDisableEditing = saveSystem && !isEditMode;

    const onEditCLicked = () => {
        setIsEditMode(true);
    };

    const onCancelEditCLicked = () => {
        setCurrentData(data);
        setIsEditMode(false);
    };

    const onSaveEditCLicked = () => {
        const filteredData = dataToUse?.filter(v => v && (typeof v !== 'string' || v !== ''));
        onChange(parentKey, filteredData);
        setIsEditMode(false);
    };

    const saveArray = (newArray: any[]) => {
        if (saveSystem) {
            setCurrentData(newArray);
        } else {
            onChange(parentKey, newArray);
        }
    };

    const alternateTextInputValidation = textInputValidationChecks
        ? (key: string, value: string | number) => {
              return textInputValidationChecks(`${parentKey}.${key}`, value);
          }
        : undefined;

    return (
        <S.ArrayFromLabel>
            <Typography>{parentKey}</Typography>
            <Box>
                {dataToUse.map((v, index) => {
                    const changeArrayData = (_: string, val: string | number) => {
                        const newArray = clone(dataToUse);
                        newArray[index] = isNumber ? parseInt(val as string) : val;
                        saveArray(newArray);
                    };
                    const onRemove = () => {
                        const newArray = clone(dataToUse);
                        newArray.splice(index, 1);
                        saveArray(newArray);
                    };
                    return (
                        <SettingsTextInput
                            key={`${parentKey}-${index + 1}`}
                            settingKey={`${index + 1}`}
                            value={v}
                            onChangeValue={changeArrayData}
                            type={isNumber ? 'number' : undefined}
                            disabled={shouldDisableEditing}
                            textInputValidationChecks={alternateTextInputValidation}
                            rightSideIcon={{
                                icon: <Delete />,
                                onClick: onRemove,
                                disabled: shouldDisableEditing,
                            }}
                        />
                    );
                })}
                {!shouldDisableEditing && (
                    <>
                        {/**
                         * This button is here because of a weird bug where the add button gets triggered when the trash can
                         * (remove button) of an input field gets pressed and when there is only 1 item in the array.
                         * This seccond invisible button fixes that
                         */}
                        <Button sx={{ display: 'none' }} />
                        <S.ArrayAddButton variant={'outlined'} startIcon={<Add />} onClick={addNewItemToArray}>
                            Add
                        </S.ArrayAddButton>
                    </>
                )}

                {shouldDisableEditing && (
                    <S.ArrayAddButton variant={'contained'} startIcon={<Edit />} onClick={onEditCLicked}>
                        {t('edit')}
                    </S.ArrayAddButton>
                )}
            </Box>
            {saveSystem && isEditMode && (
                <S.ActionButtonsContainer>
                    <S.ControlButton variant={'outlined'} startIcon={<Close />} onClick={onCancelEditCLicked}>
                        {t('cancel')}
                    </S.ControlButton>
                    <S.ControlButton variant={'contained'} startIcon={<SaveAlt />} onClick={onSaveEditCLicked}>
                        {t('save')}
                    </S.ControlButton>
                </S.ActionButtonsContainer>
            )}
        </S.ArrayFromLabel>
    );
};
