import { Typography, Stack, MenuItem, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import MenuPopover from '../menu-popover/MenuPopover';
import { useTranslation } from 'react-i18next';
import { Language } from '../../enums/Language';
import { Check } from '@mui/icons-material';
import S from './LanguagePopover.styles';
import { getLanguageIcon } from '../../utils/LanguageUtil';

const LanguagePopover = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onChangeLanguage = (language: Language) => {
        i18n.changeLanguage(language);
    };

    return (
        <>
            <S.LanguageButton ref={anchorRef} startIcon={getLanguageIcon(i18n.language)} onClick={handleOpen}>
                {t(`language.${i18n.language}`)}
            </S.LanguageButton>
            <MenuPopover
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}>
                <>
                    <Stack sx={{ p: 1 }}>
                        {Object.values(Language).map(value => (
                            <MenuItem key={value} value={value} onClick={() => onChangeLanguage(value)}>
                                <S.IconContainer>
                                   {getLanguageIcon(value)} 
                                </S.IconContainer>
                                <Typography sx={{ fontWeight: i18n.language === value ? '500' : 'inherit' }} variant={'inherit'}>
                                    {t(`language.${value}`)}
                                </Typography>
                                {i18n.language === value && <Check fontSize={'small'} sx={{ marginLeft: theme.spacing(1) }} />}
                            </MenuItem>
                        ))}
                    </Stack>
                </>
            </MenuPopover>
        </>
    );
};

export default LanguagePopover;
