import { api } from '../index';
import { ExternalAddPrizeVariables } from '../models/external-models/ExternalAddPrizeVariables';
import { useMutation } from 'react-query';

const addPrizeMutation = (variables: ExternalAddPrizeVariables) => {
    return api.post<ExternalAddPrizeVariables>('/prize', variables);
};

export const useAddPrizeMutation = () => {
    return useMutation('addPrizeMutation', addPrizeMutation);
};
