import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import theme from './theme';
import { useTranslation } from 'react-i18next';
import Routes from './routes';
import { QueryClientProvider, QueryClient } from 'react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Language } from './enums/Language';
import { nlNL, enUS, frFR } from '@mui/material/locale';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';
import okta from './okta';

const queryClient = new QueryClient();

const getLocaleFromLanguage = (language: Language) => {
    switch (language) {
        case Language.NL:
            return nlNL;
        case Language.EN:
            return enUS;
        case Language.FR:
            return frFR;
    }
};

const App = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const [locale, setLocale] = useState<Language>(i18n.language as Language);

    useEffect(() => {
        i18n.on('languageChanged', lang => {
            localStorage.setItem('language', lang);
            setLocale(lang as Language);
            queryClient.refetchQueries();
        });
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        }
    }, [i18n]);

    const themeWithLocale = useMemo(() => createTheme(theme, getLocaleFromLanguage(locale)), [locale]);

    const restoreOriginalUri = useCallback(
        async (_oktaAuth: OktaAuth, originalUri: string) => {
            navigate(toRelativeUrl(originalUri, window.location.origin), {
                replace: true,
            });
        },
        [navigate],
    );

    return (
        <Security oktaAuth={okta} restoreOriginalUri={restoreOriginalUri}>
            <ThemeProvider theme={themeWithLocale}>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        <Routes />
                    </LocalizationProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </Security>
    );
};

export default App;
