import * as React from 'react';
import { ReactNode, useState, Fragment, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { TableCell, Typography, Button, Skeleton, IconButton, Snackbar, Alert } from '@mui/material';
import { HeadCell } from '../../components/table/HeadCell';
import { useTranslation } from 'react-i18next';
import { Campaign } from '../../api/models/Campaign';
import { useFetchCampaigns } from '../../api/hooks/useFetchCampaigns';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import TableHead, { getComparator } from '../../components/table/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { Edit, Delete, AllInclusiveOutlined, Add } from '@mui/icons-material';
import TablePagination from '../../components/table/TablePagination';
import { Order } from '../../components/table/Order';
import CreateCampaignModal from './CreateCampaignModal';
import { useNavigate } from 'react-router-dom';
import { useDeleteCampaignMutation } from '../../api/hooks/useDeleteCampaignMutation';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { Role } from '../../enums/Role';
import { useCheckBackofficeRoles } from '../../hooks/useCheckBackofficeRoles';

const headCells: HeadCell<Campaign>[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        sortable: false,
    },
    {
        id: 'startDate',
        numeric: false,
        disablePadding: false,
        label: 'start.date',
        align: 'center',
        sortable: false,
    },
    {
        id: 'endDate',
        numeric: false,
        disablePadding: false,
        label: 'end.date',
        align: 'center',
        sortable: false,
    },
    {
        id: 'segments',
        numeric: false,
        disablePadding: false,
        label: 'segments',
        sortable: false,
    },
];

const tableCellHeight = 73;

const CampaignsOverview = () => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isCreateCampaignModalVisible, setIsCreateCampaignModalVisible] = useState(false);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Campaign>('name');
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [campaignToBeDeleted, setCampaignToBeDeleted] = useState<Campaign | null>(null);
    const { t } = useTranslation();
    const [snackbarReason, setSnackbarReason] = useState<'create' | 'delete'>('create');
    const navigate = useNavigate();
    const hasEditRole = useCheckBackofficeRoles([Role.EDIT]);

    const campaignsQuery = useFetchCampaigns(pageSize);
    const deleteCampaignMutation = useDeleteCampaignMutation();

    useEffect(() => {
        if (deleteCampaignMutation.isSuccess) {
            setCampaignToBeDeleted(null);
            setShowSnackbar(true);
            campaignsQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCampaignMutation.isSuccess]);

    const renderRowEntry = (row: Campaign, key: keyof Campaign, onClick?: () => void): ReactNode => {
        switch (key) {
            case 'startDate':
                return (
                    <TableCell align="center" onClick={onClick}>
                        {row.startDate}
                    </TableCell>
                );
            case 'endDate':
                return (
                    <TableCell align="center" onClick={onClick}>
                        {row.endDate || <AllInclusiveOutlined />}
                    </TableCell>
                );
            case 'segments':
                return (
                    <TableCell align="left" onClick={onClick}>
                        {row.segments.join(',')}
                    </TableCell>
                );
            case 'name':
                return (
                    <TableCell component="th" scope="row" onClick={onClick}>
                        {row.name}
                    </TableCell>
                );
            default:
                return null;
        }
    };

    const onDeletePressed = (campaign: Campaign) => {
        setShowSnackbar(false);
        setCampaignToBeDeleted(campaign);
    };
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Campaign) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (campaignsQuery.data && campaignsQuery.data.pages.length <= newPage) {
            campaignsQuery.fetchNextPage();
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderSkeleton = (): ReactNode => {
        return Array.from({ length: pageSize }, (_, index) => (
            <TableRow hover tabIndex={-1} key={index.toString()} sx={{ height: tableCellHeight }}>
                {headCells.map(headCell => (
                    <TableCell key={headCell.label}>
                        <Skeleton variant={'text'} width={100} />
                    </TableCell>
                ))}
                <TableCell width={80}>
                    <Skeleton variant={'text'} />
                </TableCell>
            </TableRow>
        ));
    };

    const onClickCreateNewCampaign = () => {
        setShowSnackbar(false);
        setIsCreateCampaignModalVisible(true);
    };

    const onCloseCreateCampaignModal = (created?: boolean) => {
        setSnackbarReason('create');
        setIsCreateCampaignModalVisible(false);
        if (created) {
            campaignsQuery.refetch();
            setShowSnackbar(true);
        }
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };
    const onConfirmDeleteCampaign = () => {
        if (campaignToBeDeleted) {
            setSnackbarReason('delete');
            deleteCampaignMutation.mutate(campaignToBeDeleted.id);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarReason === 'create' ? t('campaign.created') : t('campaign.deleted')}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                title={t('delete.campaign.title', { name: campaignToBeDeleted?.name })}
                description={t('delete.campaign.description', { name: campaignToBeDeleted?.name })}
                onConfirm={onConfirmDeleteCampaign}
                onCancel={() => setCampaignToBeDeleted(null)}
                open={!!campaignToBeDeleted}
            />
            <CreateCampaignModal open={isCreateCampaignModalVisible} onClose={onCloseCreateCampaignModal} />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant={'h4'} gutterBottom sx={{ fontWeight: '500', display: 'inline' }}>
                    {t('campaigns')}
                </Typography>
                {hasEditRole && (
                    <Button variant={'contained'} sx={{ alignSelf: 'flex-start' }} onClick={onClickCreateNewCampaign}>
                        <Add sx={{ paddingRight: 1 }} />
                        {t('create.new.campaign')}
                    </Button>
                )}
            </Box>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={2}>
                <TableContainer>
                    <MuiTable>
                        <TableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                            hideEndColumn={!hasEditRole}
                        />
                        <TableBody>
                            {campaignsQuery.data?.pages[page]
                                ? campaignsQuery.data.pages[page].sort(getComparator(order, orderBy)).map(row => (
                                      <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                                          {headCells.map(headCell => (
                                              <Fragment key={headCell.id}>{renderRowEntry(row, headCell.id, () => navigate(row.id))}</Fragment>
                                          ))}
                                          {hasEditRole && (
                                              <TableCell align={'right'}>
                                                  <IconButton disabled>
                                                      <Edit />
                                                  </IconButton>
                                                  <IconButton onClick={() => onDeletePressed(row)}>
                                                      <Delete />
                                                  </IconButton>
                                              </TableCell>
                                          )}
                                      </TableRow>
                                  ))
                                : renderSkeleton()}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TablePagination
                    count={campaignsQuery.data ? (campaignsQuery.hasNextPage ? -1 : campaignsQuery.data.pages.flatMap(p => p).length) : 0}
                    page={page}
                    handleChangePage={handleChangePage}
                    rowsPerPage={pageSize}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default CampaignsOverview;
