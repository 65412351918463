import { ExternalPrizes } from '../external-models/ExternalPrizes';
import { Prize } from '../Prize';
import { ExternalPrize } from '../external-models/ExternalPrize';
import { ExternalPrizeOption } from '../external-models/ExternalPrizeOption';
import { PrizeOption } from '../PrizeOption';
import { ExternalAddPrizeVariables } from '../external-models/ExternalAddPrizeVariables';
import { Campaign } from '../Campaign';
import { AllLanguagesString } from '../AllLanguagesString';

export class PrizeTransformer {
    static transformPrizes(externalPrizes: ExternalPrizes): Prize[] {
        return externalPrizes.prizes.map(PrizeTransformer.transformPrize);
    }

    static transformPrize(externalPrize: ExternalPrize): Prize {
        return {
            id: externalPrize.id,
            availableUntil: externalPrize.available_until,
            startAt: externalPrize.start_at,
            category: externalPrize.category,
            countdown: externalPrize.countdown,
            description: externalPrize.description,
            images: externalPrize.images,
            label: externalPrize.label,
            name: externalPrize.name,
            options: externalPrize.options.map(PrizeTransformer.transformPrizeOption),
            ordersPerUser: externalPrize.orders_per_user || 0,
            points: externalPrize.points,
        };
    }

    static transformPrizeOption(externalPrizeOption: ExternalPrizeOption): PrizeOption {
        return {
            id: externalPrizeOption.id,
            qualities: externalPrizeOption.qualities,
            images: externalPrizeOption.images,
            stock: externalPrizeOption.stock,
        };
    }

    static transformToPrizeVariables(campaign: Campaign, prize?: Prize): ExternalAddPrizeVariables{
        return {
            campaign_id: campaign.id,
            segment: campaign.segments?.[0],
            name: {
                EN: (prize?.name as AllLanguagesString)?.EN || '',
                FR: (prize?.name as AllLanguagesString)?.FR || '',
                NL: (prize?.name as AllLanguagesString)?.NL || '',
            },
            available_until: prize?.availableUntil || '',
            start_at: prize?.startAt || '',
            category: prize?.category || '',
            label: prize?.label || 'Label',
            countdown: prize?.countdown || false,
            description: {
                EN: (prize?.description as AllLanguagesString)?.EN || '',
                NL: (prize?.description as AllLanguagesString)?.NL || '',
                FR: (prize?.description as AllLanguagesString)?.FR || '',
            },
            images: prize?.images || [],
            options: prize?.options || [],
            orders_per_user: prize?.ordersPerUser || 0,
            points: prize?.points || 0,
        }
    }
}
