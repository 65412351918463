import { styled } from '@mui/material/styles';
import { Box, alpha, Typography } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    minWidth: '50%',
    overflow: 'scroll',
    maxHeight: '75%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
}));

const Header = styled(Box)(({ theme }) => ({
    flexShrink: 0,
}));

const Body = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '2em',
}));

const PaddedBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const Footer = styled(Box)(({ theme }) => ({
    flexShrink: 0,
}));

const FooterInnerContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
}));
const ProgressBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.background.paper, theme.palette.action.disabledOpacity),
    zIndex: 1,
}));

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: '500',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
}));

const Row = styled(Box)(({ theme }) => ({
    flexDirection: 'row',
    display: 'flex',
    mb: 2,
}));

const S = {
    Footer,
    Title,
    ProgressBox,
    Container,
    PaddedBox,
    Body,
    Header,
    FooterInnerContainer,
    Row,
};

export default S;
