import { Box, Typography } from '@mui/material';
import * as S from './AppleWalletPass.styles';
import { InvitationCardTypeSettings } from '../../api/models/firestore/InvitationCardTypeSettings';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';
import { InvitationCardType, useGetCardTitleByLanguage } from '../../enums/InvitationCardType';

type Props = {
    typeSettings: InvitationCardTypeSettings;
};

export const AppleWalletPass: React.FC<Props> = ({ typeSettings }) => {
    const { t } = useTranslation();
    const cardTitle = useGetCardTitleByLanguage(InvitationCardType.B);

    return (
        <S.Pass boxShadow={10} color={typeSettings.color}>
            <Box>
                <S.PassHeader>
                    <S.LogoImage
                        component={'img'}
                        // @ts-ignore because src isn't originally a property of a mui Box component. But it is if you change it into an 'img' html component
                        src={typeSettings.logo_url}
                    />
                    <Typography sx={{ color: 'white', fontWeight: 500 }}>{t('card.invitation')}</Typography>
                </S.PassHeader>
                <S.RowContainer sx={{ paddingBottom: 5 }}>
                    <S.Title variant={'h5'}>{cardTitle}</S.Title>
                    <S.Image
                        component={'img'}
                        // @ts-ignore because src isn't originally a property of a mui Box component. But it is if you change it into an 'img' html component
                        src={typeSettings.image_url}
                    />
                </S.RowContainer>
                <S.RowContainer>
                    <S.InfoBox>
                        <S.InfoText variant={'body2'}>{t('name').toUpperCase()}</S.InfoText>
                        <S.InfoText variant={'h6'}>John Doe</S.InfoText>
                    </S.InfoBox>
                    <S.InfoBox>
                        <S.InfoText variant={'body2'} sx={{ textAlign: 'right' }}>
                            {t('member.id').toUpperCase()}
                        </S.InfoText>
                        <S.InfoText variant={'h6'} sx={{ textAlign: 'right' }}>
                            12345
                        </S.InfoText>
                    </S.InfoBox>
                </S.RowContainer>
                <S.RowContainer sx={{ paddingTop: 2 }}>
                    <S.InfoBox>
                        <S.InfoText>{t('type').toUpperCase()}</S.InfoText>
                        <S.InfoText>{typeSettings.label || 'A'}</S.InfoText>
                    </S.InfoBox>
                    <S.InfoBox>
                        <S.InfoText>{t('season').toUpperCase()}</S.InfoText>
                        <S.InfoText>{`${getYear(new Date()) - 1}-${getYear(new Date())}`}</S.InfoText>
                    </S.InfoBox>
                </S.RowContainer>
            </Box>
            <S.RowContainer justifyContent={'center'}>
                <S.QrContainer>
                    <QRCode value={`12345`} size={150} />
                </S.QrContainer>
            </S.RowContainer>
        </S.Pass>
    );
};
