import { AppSettingsAlt, CardMembership, Loyalty } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardButton from '../../components/dashboard-button/DashboardButton';
import S from './Dashboard.styles';
import { Role } from '../../enums/Role';
import { useCheckBackofficeRoles } from '../../hooks/useCheckBackofficeRoles';

const Dashboard = () => {
    const { t } = useTranslation();
    const hasEditRole = useCheckBackofficeRoles([Role.EDIT])

    const iconStyle = {
        width: 40,
        height: 40,
    };

    return (
        <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: '500', display: 'inline' }}>
                {t('dashboard')}
            </Typography>
            <S.DashboardButtonsBox>
                <DashboardButton title={t('loyalty')} route={'loyalty/campaigns'} icon={<Loyalty sx={iconStyle} />} />
                <DashboardButton title={t('cards.invitation')} route={'invitation-cards'} icon={<CardMembership sx={iconStyle} />} />
                {hasEditRole && (
                    <DashboardButton title={t('app.configuration')} route={'app/configuration'} icon={<AppSettingsAlt sx={iconStyle} />} />
                )}
            </S.DashboardButtonsBox>
        </Box>
    );
};

export default Dashboard;
