import { Typography } from '@mui/material';
import * as S from './GoogleWalletPass.styles';
import { InvitationCardTypeSettings } from '../../api/models/firestore/InvitationCardTypeSettings';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';
import { InvitationCardType, useGetCardTitleByLanguage } from '../../enums/InvitationCardType';

type Props = {
    typeSettings: InvitationCardTypeSettings;
};

export const GoogleWalletPass: React.FC<Props> = ({ typeSettings }) => {
    const { t } = useTranslation();
    const cardTitle = useGetCardTitleByLanguage(InvitationCardType.B);

    return (
        <S.Pass boxShadow={10} color={typeSettings.color}>
            <S.PassHeader>
                <S.LogoImage
                    component={'img'}
                    // @ts-ignore
                    src={typeSettings.logo_url}
                />
                <Typography sx={{ color: 'white', fontWeight: 500 }}>{t('card.invitation')}</Typography>
            </S.PassHeader>
            <S.Separator />
            <S.Title variant={'h5'}>12345</S.Title>
            <S.InfoContainer>
                <S.InfoBox>
                    <S.InfoText>{t('name')}</S.InfoText>
                    <S.InfoText variant={'h6'}>John Doe</S.InfoText>
                </S.InfoBox>
                <S.InfoBox>
                    <S.InfoText sx={{ textAlign: 'right' }}>{t('type')}</S.InfoText>
                    <S.InfoText variant={'h6'} sx={{ textAlign: 'right' }}>
                        {typeSettings.label || 'A'}
                    </S.InfoText>
                </S.InfoBox>
            </S.InfoContainer>
            <S.InfoContainer>
                <S.InfoBox>
                    <S.InfoText>{t('season')}</S.InfoText>
                    <S.InfoText variant={'h6'}>{`${getYear(new Date()) - 1}-${getYear(new Date())}`}</S.InfoText>
                </S.InfoBox>
                <S.InfoBox>
                    <S.InfoText sx={{ textAlign: 'right' }}>{t('title')}</S.InfoText>
                    <S.InfoText variant={'h6'}>{cardTitle}</S.InfoText>
                </S.InfoBox>
            </S.InfoContainer>
            <S.RowContainer>
                <S.QrContainer>
                    <QRCode value={`12345`} size={100} />
                </S.QrContainer>
            </S.RowContainer>
            <S.Image
                component={'img'}
                // @ts-ignore
                src={typeSettings.image_url}
            />
        </S.Pass>
    );
};
