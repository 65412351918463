import { ref, getStorage, uploadBytes } from 'firebase/storage';
import firebase from '../../firebase';
import config from '../../config';
import { useMutation } from 'react-query';
import { delay } from '@okta/okta-auth-js';

const storage = getStorage(firebase.app, `gs://${config.firebase.storageBucket}`);

const mutateProductImages = (file: File) => {
    return uploadBytes(ref(storage, `/flametime/${file.name}`), file).then(() => delay(1000));
};

export const useMutateProductImages = () => {
    return useMutation('mutateProductImages', mutateProductImages);
};
