import { DocumentData, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import firebase from '../../../firebase';
import config from '../../../config';
import { isEqual } from 'lodash';

export const useFetchAppConfiguration = () => {
    const [appConfiguration, setAppConfiguration] = useState<DocumentData>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const firestore = getFirestore(firebase.app);
    const configurationDocRef = doc(firestore, config.firestore.settingsDocs, config.firestore.appConfiguration);

    try {
        onSnapshot(configurationDocRef, newDoc => {
            if (newDoc.exists()) {
                const newData = newDoc.data();
                if (!!newData && !isEqual(newData, appConfiguration)) {
                    setError(undefined);
                    setAppConfiguration(newData);
                }
            }
        });
    } catch (e) {
        const err = (e as Error).toString();
        if (err !== error) {
            setError(err);
        }
    }

    useEffect(() => {
        if (isLoading && (appConfiguration || error)) {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appConfiguration, error]);

    return {
        data: appConfiguration,
        isLoading: isLoading,
        isError: !!error,
    };
};
