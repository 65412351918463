import { styled } from '@mui/material/styles';
import { Box, alpha, ImageListItem, Button, ImageListItemBar } from '@mui/material';

type SelectedProps = {
    selected: boolean;
};

export const Container = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'solid',
    width: 'fit-content',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxHeight: '350px',
    overflow: 'hidden',
}));
export const Row = styled(Box)({
    flexDirection: 'row',
    display: 'flex',
    gap: 4,
    maxHeight: 'inherit',
});
export const UploadImageContainer = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.divider,
    '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity),
    },
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 4,
    minWidth: '128px',
    height: '100%',
    minHeight: '128px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textTransform: 'none',
}));
export const UploadIconContainer = styled(Box)(({ theme }) => ({
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(1),
}));
export const Image = styled(ImageListItem)<SelectedProps>(({ theme, selected }) => ({
    borderColor: selected ? theme.palette.primary.main : alpha(theme.palette.grey['400'], 0.8),
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 4,
    width: '128px',
    minHeight: '128px',
}));
export const ImageInput = styled('input')(_ => ({
    display: 'none',
}));
export const Overlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
export const ImageTitle = styled(ImageListItemBar)<SelectedProps>(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.primary.main : alpha(theme.palette.grey['400'], 0.8),
    textAlign: 'center',
}));

export const ImageNumber = styled(Box)(({ theme }) => ({
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const ImageRemove = styled(ImageListItemBar)(({ theme }) => ({
    background: 'rgba(0,0,0,0)',
    zIndex: 1,
}));
