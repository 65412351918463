import { Box, Typography, Button, alpha, CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchOrders } from '../../api/hooks/useFetchOrders';
import { styled } from '@mui/material/styles';
import { useFetchUsers } from '../../api/hooks/useFetchUsers';
import { ExportOrderRecordTransformer } from '../../api/models/transformers/ExportOrderRecordTransformer';
import { useFetchPrizes } from '../../api/hooks/useFetchPrizes';
import Config from '../../config';
import { Prize } from '../../api/models/Prize';
import CsvColumn from '../../models/CsvColumn';
import { CsvUtil } from '../../utils/CsvUtil';
import { ExportOrderRecord } from '../../api/models/ExportOrderRecord';

const OverlayBox = styled(Box)(({ theme }) => ({
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'column',
}));

interface OptionWithPrize {
    optionId: string;
    prize?: Prize;
}

const getPrizesForOptionIds = (optionIds: string[], prizes: Prize[]): string[] => {
    return optionIds
        .map(optionId => {
            return {
                optionId: optionId,
                prize: prizes.find(prize => prize.options.find(option => option.id === optionId)),
            } as OptionWithPrize;
        })
        .map(
            optionWithPrize => {
                if (!optionWithPrize.prize){
                    return ''
                }
                return `{${optionWithPrize.prize?.name}, ${Object.values(
                    optionWithPrize.prize?.options.find(option => option.id === optionWithPrize.optionId)?.qualities ?? [],
                )?.join(' ')}}`;
            }
        );
};

const createCsvColumnsObj = (): CsvColumn[] => {
    return [
        {
            place: 0,
            columnName: 'id',
            param: 'id',
        },
        {
            place: 1,
            columnName: 'Name',
            param: 'name',
        },
        {
            place: 2,
            columnName: 'Language',
            param: 'language',
        },
        {
            place: 3,
            columnName: 'Email',
            param: 'email',
        },
        {
            place: 4,
            columnName: 'Order date',
            param: 'orderDate',
        },
        {
            place: 5,
            columnName: 'Prizes',
            param: 'prizes',
        },
        {
            place: 6,
            columnName: 'Phone',
            param: 'address.phone',
        },
        {
            place: 7,
            columnName: 'Street & number',
            param: 'address.street',
        },
        {
            place: 8,
            columnName: 'Postal code',
            param: 'address.postalCode',
        },
        {
            place: 9,
            columnName: 'City',
            param: 'address.city',
        },
        {
            place: 10,
            columnName: 'Country',
            param: 'address.country',
        },
    ];
};

interface Props {
    campaignId?: string
}

const OrdersOverview: React.FC<Props> = ({ campaignId }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [hasPressedDownload, setHasPressedDownload] = useState(false);
    const ordersQuery = useFetchOrders();
    const usersQuery = useFetchUsers();
    const prizesQuery = useFetchPrizes(campaignId || Config.deviltimeId, 100, []);

    const [prizes, setPrizes] = useState<Prize[]>();

    useEffect(() => {
        if (prizesQuery.hasNextPage && !prizesQuery.isLoading) {
            prizesQuery.fetchNextPage();
        } else if (!!prizesQuery.data) {
            let prizes: Prize[] = [];
            prizesQuery.data.pages.forEach(page => (prizes = prizes.concat(page)));
            setPrizes(prizes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prizesQuery.data, prizesQuery.isLoading, prizesQuery.hasNextPage]);

    useEffect(() => {
        if (!ordersQuery.hasNextPage && !ordersQuery.isFetching && hasPressedDownload && !!ordersQuery.orders) {
            usersQuery.setUserIds(Array.from(new Set(ordersQuery.orders?.map(order => order.user))));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersQuery.hasNextPage, ordersQuery.isFetching, ordersQuery.orders, hasPressedDownload]);

    useEffect(() => {
        if (!!usersQuery.users && !usersQuery.isFetching) {
            if (!!ordersQuery.orders && !!prizes) {
                const csvOrders: ExportOrderRecord[] = ordersQuery.orders?.map(order =>
                    ExportOrderRecordTransformer.transformExportOrderRecord(
                        order,
                        getPrizesForOptionIds(order.prizes, prizes),
                        usersQuery.users?.find(user => user.id === order.user),
                    ),
                );
                const filteredCsvOrders = csvOrders.filter(o => o.prizes !== undefined)
                const csvColumnsObject = createCsvColumnsObj();
                CsvUtil.createCsv(filteredCsvOrders, csvColumnsObject, 'order-export');
                setHasPressedDownload(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersQuery.users, usersQuery.isFetching]);

    const exportsOrders = () => {
        ordersQuery.refetch();
        setHasPressedDownload(true);
    };

    return (
        <>
            <Box>
                <Typography variant={'h5'} gutterBottom sx={{ fontWeight: '500' }}>
                    {t('orders')}
                </Typography>
                <Button disabled={hasPressedDownload} onClick={exportsOrders}>
                    {t('export.orders')}
                </Button>
            </Box>
            {hasPressedDownload && (
                <OverlayBox>
                    <Typography variant={'h5'} sx={{ marginBottom: theme.spacing(2) }}>
                        {t('exporting')}
                    </Typography>
                    <CircularProgress />
                </OverlayBox>
            )}
        </>
    );
};

export default OrdersOverview;
