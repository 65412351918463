import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language } from './enums/Language';

i18n.use(initReactI18next).init({
    resources: {
        [Language.EN]: {
            translation: require('./i18n/english.translations.json'),
        },
        [Language.NL]: {
            translation: require('./i18n/dutch.translations.json'),
        },
        [Language.FR]: {
            translation: require('./i18n/french.translations.json'),
        },
    },
    nonExplicitSupportedLngs: true,
    fallbackLng: Language.EN,
    supportedLngs: [Language.NL, Language.FR, Language.EN],
    returnEmptyString: false,
    parseMissingKeyHandler(key: string): string {
        return `UNTRANSLATED_${key}`;
    },
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    compatibilityJSON: 'v3',
});

export default i18n;
