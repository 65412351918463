import { DocumentData, DocumentReference, collection, doc, getDoc, getDocs, getFirestore, query, where, writeBatch } from 'firebase/firestore';
import { useState } from 'react';
import firebase from '../../../firebase';
import { InvitationCard } from '../../models/firestore/InvitationCard';
import { isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { InvitationCardSeason } from '../../models/firestore/InvitationCardSeason';
import { GlobalConstants } from '../../../constants';

export const useMutateInvitationCards = () => {
    const [docRefs, setDocRefs] = useState<InvitationCard[]>();
    const [error, setError] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>();

    const firestore = getFirestore(firebase.app);
    const batch = writeBatch(firestore);
    const invitationCardsCollection = collection(firestore, `${GlobalConstants.INVITATION_CARDS.BASE_DOCS}/${GlobalConstants.INVITATION_CARDS.CARDS_DOCS}/imported`);

    const updateOrCreate = async (data: InvitationCard[] | InvitationCard) => {
        setIsSuccess(undefined);
        setError(undefined);

        const docs: InvitationCard[] = [];
        const cards = isArray(data) ? data : [data];
        for (const card of cards) {
            await updateOrCreateOne(card, docs);
        }
        batch
            .commit()
            .then(() => {
                setDocRefs(docs);
                setIsSuccess(true);
            })
            .catch(e => setError(e));
    };

    const updateOrCreateOne = async (value: InvitationCard, cardsArray: InvitationCard[]) => {
        const q = query(invitationCardsCollection, where('cardNumber', '==', value.cardNumber));
        let docId;

        if (!!value.docId) {
            docId = value.docId;
        } else {
            (await getDocs(q)).forEach(d => (docId = d.id));
        }

        delete value.docId;

        if (!!docId) {
            const docToUpdate = doc(invitationCardsCollection, docId);
            const updatedValue: InvitationCard = await createNewValue(value, docToUpdate);
            await batch.update(docToUpdate, { ...updatedValue });
            cardsArray.push(createInvitationCard(docToUpdate.id, updatedValue));
        } else {
            const newDoc = doc(invitationCardsCollection);
            const updatedValue: InvitationCard = await createNewValue(value, newDoc);
            await batch.set(newDoc, updatedValue);
            cardsArray.push(createInvitationCard(newDoc.id, updatedValue));
        }
    };

    const createNewValue = async (value: InvitationCard, currentDoc?: DocumentReference<DocumentData>): Promise<InvitationCard> => {
        const data = currentDoc ? ((await getDoc(currentDoc)).data() as InvitationCard) : undefined;
        const newSeasons = data?.seasons || {};
        await Promise.all(Object.values(value.seasons).map(async s => await createSeasonWithUuid(newSeasons, s, data)));
        return {
            ...value,
            seasons: newSeasons,
        };
    };

    const createSeasonWithUuid = async (newSeasons: { [key: string]: InvitationCardSeason }, season: InvitationCardSeason, data?: InvitationCard) => {
        const currentSeasonUuid = Object.keys(data?.seasons || {}).find(key => data?.seasons[key].season === season.season);
        const currentSeason = data?.seasons[currentSeasonUuid || '']
        newSeasons[currentSeasonUuid || uuidv4()] = currentSeason || season;
    };

    const createInvitationCard = (docId: string, card: InvitationCard) => {
        return {
            ...card,
            docId: docId,
        };
    };

    return {
        mutate: updateOrCreate,
        data: docRefs,
        isError: !!error,
        isSuccess,
    };
};
