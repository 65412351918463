import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { signInWithCustomToken } from 'firebase/auth';
import firebase from '../firebase';
import { functions } from '../api';

export const useSignInFirebaseAuth = () => {
    const { oktaAuth } = useOktaAuth();

    useEffect(() => {
        oktaAuth
            .getUser()
            .then(user =>
                functions.get(`/custom-token`, {
                    params: {
                        user: user.sub,
                    },
                }),
            )

            .then(response => signInWithCustomToken(firebase.auth, response.data));
    }, [oktaAuth]);
};
