import React from 'react';
import * as S from './DashboardFooter.styles'
import package_json from '../../../../package.json';

export const DashboardFooter: React.FC = () => {
  return (
    <S.Footer>
      <S.VersionText>{package_json.version}</S.VersionText>
    </S.Footer>
  )
}