import { api } from '../index';
import { ExternalAddPrizeVariables } from '../models/external-models/ExternalAddPrizeVariables';
import { useMutation } from 'react-query';
import { ExternalUpdateVariables } from '../models/external-models/ExternalUpdateVariables';

const editPrizeMutation = (variables: ExternalUpdateVariables) => {
    return api.patch<ExternalAddPrizeVariables>(`/prize?id=${variables.id}`, variables.updateObjects);
};

export const useEditPrizeMutation = () => {
    return useMutation('editPrizeMutation', editPrizeMutation);
};