import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DocumentData } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsGroupComponent } from '../../components/SettingsGroupComponent';
import * as S from './AppSettingsTab.styles';
import { GlobalConstants } from '../../../../constants';
import { clone, isEqual, isNil } from 'lodash';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { Clear, SaveAlt } from '@mui/icons-material';
import { seasonIsValid } from '../../../../utils/SeasonsUtil';
import { UrlValidator } from '../../../../utils/url.validator';
import { semanticVersionCheck } from '../../../../utils/ValidatorUtil';

type Props = {
    data: DocumentData;
    onChangeSettings: (data: DocumentData) => void;
    onUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
};

export const AppSettingsTab: React.FC<Props> = ({ data, onChangeSettings, onUnsavedChanges }) => {
    const { t } = useTranslation();
    const [currentData, setCurrentData] = useState<DocumentData>(data);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [inValidFields, setInValidFields] = useState<string[]>([]);

    useEffect(() => {
        onUnsavedChanges?.(!isEqual(data, currentData))
    }, [data, currentData, onUnsavedChanges])

    const onChangeData = (key: string, value: any) => {
        setCurrentData({
            ...currentData,
            [key]: value,
        });
    };

    const onSaveClicked = () => {
        setShowSaveModal(true);
    };

    const onCancelClicked = () => {
        setCurrentData(data);
    };

    const onSaveCancelClicked = () => {
        setShowSaveModal(false);
    };

    const onSaveConfirm = () => {
        onChangeSettings(currentData);
        setShowSaveModal(false);
    };

    const filterSettingsOnKey = (key: string) => {
        return !key.startsWith(GlobalConstants.FEATURE_FLAGS_PREFIX);
    };

    const textInputValidationChecks = (key: string, value: string | number) => {
        const isString = typeof value === 'string';
        const isValueNotFilledIn = isNil(value) || (isString && value === '')
        const isIncorrectSeason = isString && key.includes('seasons') && !seasonIsValid(value);
        const isIncorrectUrl = isString && key.includes('url') && typeof value === 'string' && !UrlValidator.validate(value);
        const isIncorrectVersion =
            isString &&
            key.includes('version') &&
            (key.includes('target') || key.includes('min') || key.includes('max')) &&
            !semanticVersionCheck(value);

        if (isValueNotFilledIn || isIncorrectSeason || isIncorrectUrl || isIncorrectVersion) {
            return false
        }
        return true;
    };

    const textInputValidation = (key: string, value: string | number) => {
        const isValid = textInputValidationChecks(key, value);
        if (!isValid) {
            if (!inValidFields.includes(key)) {
                const tempInvalidFields = clone(inValidFields);
                tempInvalidFields.push(key);
                setInValidFields(tempInvalidFields);
            }
        } else {
            if (inValidFields.includes(key)) {
                const tempInvalidFields = clone(inValidFields);
                const index = tempInvalidFields.indexOf(key);
                tempInvalidFields.splice(index, 1);
                setInValidFields(tempInvalidFields);
            }
        }
        return isValid;
    };

    const isFormInvalid = isEqual(data, currentData) || inValidFields.length > 0;

    return (
        <Box>
            <ConfirmationDialog
                title={t('app.configuration.save.title')}
                description={t('app.configuration.save.message')}
                onConfirm={onSaveConfirm}
                onCancel={onSaveCancelClicked}
                open={showSaveModal}
            />
            <S.HeaderContainer>
                <Typography variant={'h5'} gutterBottom sx={{ fontWeight: '500' }}>
                    {t('settings')}
                </Typography>
                <Box>
                    <S.ActionButtons variant={'outlined'} onClick={onCancelClicked} disabled={isFormInvalid} startIcon={<Clear />}>
                        {t('cancel')}
                    </S.ActionButtons>
                    <S.ActionButtons variant={'contained'} onClick={onSaveClicked} disabled={isFormInvalid} startIcon={<SaveAlt />}>
                        {t('save')}
                    </S.ActionButtons>
                </Box>
            </S.HeaderContainer>
            <S.ContentPaper>
                <SettingsGroupComponent
                    data={currentData}
                    onChangeData={onChangeData}
                    filterFields={filterSettingsOnKey}
                    textInputValidationChecks={textInputValidation}
                />
            </S.ContentPaper>
        </Box>
    );
};
