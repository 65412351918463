import { Typography } from '@mui/material';
import React from 'react';
import { SettingsSwitch } from '../../../components/settings-controls/settings-switch/SettingsSwitch';
import { SettingsTextInput } from '../../../components/settings-controls/settings-text-input/SettingsTextInput';
import * as S from './SettingsGroupComponent.styles';
import { SettingsArray } from '../../../components/settings-controls/settings-array/SettingsArray';
import { isNil } from 'lodash';
import { keyToTitle } from '../../../utils/StringsUtil';

type Props = {
    parentKey?: string;
    data: any;
    onChangeData: (key: string, value: any, changedRootSettingKey?: string, changedRootValue?: any) => void;
    showTitle?: boolean;
    filterFields?: (key: string, value?: any) => boolean;
    arraySaveSystem?: boolean;
    textInputValidationChecks?: (key: string, value: string | number) => boolean;
    onUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
};

export const SettingsGroupComponent: React.FC<Props> = ({
    parentKey,
    data,
    onChangeData,
    showTitle,
    filterFields,
    arraySaveSystem = false,
    textInputValidationChecks,
    onUnsavedChanges
}) => {
    const changeData = (key: string, value: any, changedRootSettingKey?: string, changedRootValue?: any) => {
        const newData = parentKey
            ? {
                  ...data,
                  [key]: value,
              }
            : value;
        const rootValue = !isNil(changedRootValue) ? changedRootValue : value;
        onChangeData(parentKey || key, newData, changedRootSettingKey || key, rootValue);
    };

    const getInputControl = (key: string, value: any) => {
        if (!filterFields || filterFields(key, value)) {
            switch (typeof value) {
                case 'string':
                    return (
                        <SettingsTextInput
                            key={key}
                            settingKey={key}
                            value={value}
                            onChangeValue={changeData}
                            textInputValidationChecks={textInputValidationChecks}
                        />
                    );
                case 'number':
                    return <SettingsTextInput key={key} settingKey={key} value={value} onChangeValue={changeData} type={'number'} />;
                case 'boolean':
                    return <SettingsSwitch key={key} settingKey={key} value={value} onChange={changeData} />;
                case 'object':
                    if (Array.isArray(value)) {
                        return (
                            <SettingsArray
                                parentKey={key}
                                data={value}
                                onChange={changeData}
                                saveSystem={arraySaveSystem}
                                textInputValidationChecks={textInputValidationChecks}
                                onUnsavedChanges={onUnsavedChanges}
                            />
                        );
                    }
                    return (
                        <S.SettingsGroupContainer>
                            <SettingsGroupComponent
                                parentKey={key}
                                data={value}
                                onChangeData={changeData}
                                filterFields={filterFields}
                                arraySaveSystem={arraySaveSystem}
                                showTitle
                                textInputValidationChecks={textInputValidationChecks}
                            />
                        </S.SettingsGroupContainer>
                    );
                default:
                    return null;
            }
        }
        return null;
    };

    const sortTypes = (a: string, b: string) => {
        if (Array.isArray(data[a])) {
            return 1;
        } else if (Array.isArray(data[b])) {
            return -1;
        }
        return (typeof data[b]).localeCompare(typeof data[a]) || a.localeCompare(b);
    };

    const keys = Object.keys(data).sort(sortTypes);

    return (
        <S.Container>
            {showTitle && !!parentKey && !Object.keys(data).every(k => !filterFields || !filterFields(k, data[k])) && (
                <Typography variant={'h6'}>{keyToTitle(parentKey)}</Typography>
            )}
            <S.ValuesContainer>
                {keys
                    .filter(k => Array.isArray(data[k]) || typeof data[k] !== 'object')
                    .map(key => {
                        return getInputControl(key, data[key]);
                    })}
            </S.ValuesContainer>
            {keys
                .filter(k => !Array.isArray(data[k]) && typeof data[k] === 'object')
                .map(key => {
                    return getInputControl(key, data[key]);
                })}
        </S.Container>
    );
};
