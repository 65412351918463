import { ExternalUser } from '../external-models/ExternalUser';
import { User } from '../User';

export class UserTransformer {
    static transformUsers(users: ExternalUser[]): User[] {
        return users.map(user => UserTransformer.transformUser(user));
    }

    private static transformUser(user: ExternalUser): User {
        return {
            id: user.id,
            status: user.status,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
        };
    }
}
