import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Footer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[500],
    width: '100%',
    position: 'fixed',
    bottom: 0,
    padding: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const VersionText = styled(Typography)(({ theme }) => ({
  color: 'white'
}));
