import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from '../index';
import { EventTransformer } from '../models/transformers/EventTransformer';
import { ExternalEvent } from '../models/external-models/ExternalEvent';
import { ExternalResponse } from '../models/external-models/ExternalResponse';

const fetchEvent = ({ queryKey }: QueryFunctionContext) => {
    return api.get<{ event: ExternalEvent } & ExternalResponse>('/event', {
        params: {
            id: queryKey[1],
            ...(!!queryKey[2]) && {language: '__ALL__'}
        },
    });
};

export const useFetchEvent = (eventId?: string, allLanguages?: boolean) => {
    return useQuery(['fetchEvent', eventId, allLanguages], fetchEvent, {
        enabled: !!eventId,
        select: response => EventTransformer.transformEvent(response.data.event),
    });
};
