import { Typography, useTheme, ImageList, CircularProgress, IconButton } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import * as S from './ImageSelector.styles';
import { Upload, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useFetchProductImages } from '../../api/hooks/useFetchProductImages';
import { useMutateProductImages } from '../../api/hooks/useMutateProductImages';
import ImageModal from './ImageModal';

interface Props {
    onImageSelected: (imageUrl: string) => void;
    onImageDeselected: (imageUrl: string) => void;
    selectedImages: string[];
}

const ImageSelector: React.FC<Props> = ({ selectedImages, onImageSelected, onImageDeselected }) => {
    const imageInputRef = useRef<HTMLInputElement>(null);
    const mutateProductImages = useMutateProductImages();
    const imagesQuery = useFetchProductImages();
    const { t } = useTranslation();
    const theme = useTheme();

    const [openImageModal, setOpenImageModal] = useState<boolean>(false);

    useEffect(() => {
        if (mutateProductImages.isSuccess) {
            imagesQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateProductImages.isSuccess]);

    const onUploadImageClicked = (event: any) => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    };

    const onChangeInputImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.target.files?.[0]) {
            mutateProductImages.mutate(event.target.files[0]);
        }
    };

    const removeImage = (url: string) => {
        onImageDeselected(url);
    };

    const addImage = (url: string) => {
        setOpenImageModal(false);
        onImageSelected(url);
    };

    const addOrUploadImageButton = (text: string, onClick: (...args: unknown[]) => void) => {
        return (
            <S.UploadImageContainer onClick={onClick}>
                <S.UploadIconContainer>
                    <Upload sx={{ color: 'white' }} />
                </S.UploadIconContainer>
                <Typography color={theme.palette.primary.main} variant={'subtitle2'} sx={{ pointerEvents: 'none' }}>
                    {text}
                </Typography>
                <S.ImageInput onChange={onChangeInputImage} type={'file'} ref={imageInputRef} />
            </S.UploadImageContainer>
        );
    };

    return (
        <S.Container>
            <Typography sx={{ mb: 1 }} variant={'subtitle2'}>
                Select images
            </Typography>
            <S.Row>
                {imagesQuery.data && (
                    <>
                        <ImageList gap={4} sx={{ padding: 0, margin: 0, maxHeight: 'inherit' }} cols={5}>
                            {selectedImages.map((val, index) => {
                                const item = imagesQuery.data.filter(obj => obj.url === val)[0];
                                return (
                                    <S.Image key={item.name} selected={true}>
                                        <S.ImageRemove
                                            position="top"
                                            actionPosition="right"
                                            actionIcon={
                                                <IconButton onClick={() => removeImage(item.url)}>
                                                    <Close />
                                                </IconButton>
                                            }
                                        />
                                        <img src={item.url} alt={item.name} loading={'lazy'} />
                                        <S.Overlay>
                                            <S.ImageNumber>{index + 1}</S.ImageNumber>
                                        </S.Overlay>
                                        <S.ImageTitle title={item.name} selected={true} />
                                    </S.Image>
                                );
                            })}
                            {addOrUploadImageButton(t('add'), () => setOpenImageModal(true))}
                        </ImageList>
                        <ImageModal
                            isLoading={false}
                            open={openImageModal}
                            onCancel={() => {
                                setOpenImageModal(false);
                            }}>
                            <ImageList gap={4} sx={{ paddingRight: 5, marginLeft: 5, overflow: 'scroll', flexWrap: 'wrap' }} cols={5}>
                                {addOrUploadImageButton(t('upload'), onUploadImageClicked)}

                                {imagesQuery.data.map(item => {
                                    const selected = selectedImages.includes(item.url);
                                    if (!selected) {
                                        return (
                                            <S.Image key={item.name} selected={selected} onClick={() => addImage(item.url)}>
                                                <img src={item.url} alt={item.name} loading={'lazy'} />
                                                <S.ImageTitle title={item.name} selected={selected} />
                                            </S.Image>
                                        );
                                    }
                                    return undefined;
                                })}
                            </ImageList>
                        </ImageModal>
                    </>
                )}
            </S.Row>
            {(imagesQuery.isLoading || mutateProductImages.isLoading) && (
                <S.Overlay>
                    <CircularProgress />
                </S.Overlay>
            )}
        </S.Container>
    );
};

export default ImageSelector;
