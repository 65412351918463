import { styled } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import { Outlet } from 'react-router-dom';
import { useSignInFirebaseAuth } from '../../hooks/useSignInFirebaseAuth';
import { DashboardFooter } from './dashboard-footer/DashboardFooter';

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: theme.spacing(11),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

const DashboardLayout = () => {
    useSignInFirebaseAuth();

    return (
        <RootStyle>
            <DashboardNavbar />
            <MainStyle sx={{ mx: 15, marginBottom: 10 }}>
                <Outlet />
            </MainStyle>
            <DashboardFooter />
        </RootStyle>
    );
};

export default DashboardLayout;
