import * as React from 'react';
import MuiTablePagination from '@mui/material/TablePagination';

interface Props {
    count: number;
    rowsPerPage: number;
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    page: number;
}

const TablePagination: React.FC<Props> = ({ count, handleChangePage, rowsPerPage, page, handleChangeRowsPerPage }) => {
    return (
        <MuiTablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
};

export default TablePagination;
