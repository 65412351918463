import { useQuery } from 'react-query';
import { api } from '../index';
import { useEffect, useState } from 'react';
import { User } from '../models/User';
import { ExternalUser } from '../models/external-models/ExternalUser';
import { UserTransformer } from '../models/transformers/UserTransformer';

const fetchUsers = (userIds: string[]) => {
    return api.post<ExternalUser[]>('/users', { users: userIds });
};

export const useFetchUsers = (ids?: string[]) => {
    const [users, setUsers] = useState<User[]>();
    const [userIds, setUserIds] = useState<string[]>(ids ?? []);

    const query = useQuery(['fetchUsers', userIds], () => fetchUsers(userIds), {
        enabled: false,
    });

    useEffect(() => {
        if (userIds.length > 0) {
            query.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIds]);

    useEffect(() => {
        if (query.data && !query.isFetching) {
            // @ts-ignore
            setUsers(UserTransformer.transformUsers(query.data.data.users));
        }
    }, [query.data, query.isFetching]);

    return {
        users,
        isFetching: query.isFetching,
        refetch: query.refetch,
        setUserIds: setUserIds,
    };
};
