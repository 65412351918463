import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { api } from '../index';
import { ExternalCampaigns } from '../models/external-models/ExternalCampaigns';
import { CampaignTransformer } from '../models/transformers/CampaignTransformer';

const fetchCampaigns = ({ queryKey, pageParam }: QueryFunctionContext) => {
    const params = new URLSearchParams();
    params.append('page-size', queryKey[1] as string);
    params.append('segments', '__ALL__');
    if (pageParam) {
        params.append('page', pageParam as string);
    }
    return api.get<ExternalCampaigns>('/campaigns', { params });
};

export const useFetchCampaigns = (pageSize: number) => {
    return useInfiniteQuery(['fetchCampaigns', pageSize], fetchCampaigns, {
        select: response => ({
            pages: response.pages.map(page => CampaignTransformer.transformCampaigns(page.data)),
            pageParams: response.pageParams,
        }),
        getPreviousPageParam: firstPage => firstPage.data.previous_page,
        getNextPageParam: lastPage => lastPage.data.next_page,
        keepPreviousData: true,
    });
};
