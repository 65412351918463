import React, { ReactNode } from 'react';
import S from './ImageModal.styles';
import { CircularProgress, Divider, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean,
    children: ReactNode;
    isLoading: boolean;
    onCancel: () => void;
}

const ImageModal: React.FC<Props> = ({ open, children, isLoading, onCancel }) => {
    const { t } = useTranslation();
    return(
        <Modal open={open} onClose={onCancel}>
            <S.Container>
                {isLoading && (
                    <S.ProgressBox>
                        <CircularProgress />
                    </S.ProgressBox>
                )}
                <S.Header>
                    <S.HeaderRow>
                        <S.Title variant='h4'>{t('add.image')}</S.Title>
                    </S.HeaderRow>
                </S.Header>
                <Divider />
                <S.Body>
                    {children}
                </S.Body>
            </S.Container>
        </Modal>
    );
}

export default ImageModal;
