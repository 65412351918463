import { CircularProgress, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './InvitationCardTypes.styles';
import { GoogleWalletPass } from '../../../components/wallet-pass/GoogleWalletPass';
import { AppleWalletPass } from '../../../components/wallet-pass/AppleWalletPass';
import { ColorPicker } from '../../../components/color-picker/ColorPicker';
import { Add, SaveAlt, Warning } from '@mui/icons-material';
import { useFetchInvitationCardsSettings } from '../../../api/hooks/firestore/useFetchInvitationCardsSettings';
import { InvitationCardTypeSettings } from '../../../api/models/firestore/InvitationCardTypeSettings';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { isEqual } from 'lodash';
import { useUnsavedChangesBlock } from '../../../hooks/useUnsavedChangesBlock';
import { useMutateInvitationCardSettings } from '../../../api/hooks/firestore/useMutateInvitationCardSettings';

const EMPTY_TYPE_LABEL = 'New'

type Props = {
    onUnsavedChanges: (unsavedChanges: boolean) => void;
};

export const InvitationCardTypes: React.FC<Props> = ({ onUnsavedChanges }) => {
    const { t } = useTranslation();
    const invitationCardsSettings = useFetchInvitationCardsSettings();
    const mutateInvitationCardSettings = useMutateInvitationCardSettings()
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [currentType, setCurrentType] = useState<InvitationCardTypeSettings>({
        label: '',
        color: '',
        image_url: '',
        logo_url: '',
    });

    const [isNewType, setIsNewType] = useState<boolean>(false);

    const hasUnsavedChanges = useMemo(() => {
        const typeToCheck = invitationCardsSettings.settings?.types.find(t => t.label === currentType.label);
        return !isEqual(currentType, typeToCheck) && (!isNewType || !isEqual(currentType, invitationCardsSettings.settings?.type_default));
    }, [currentType, invitationCardsSettings.settings, isNewType]);

    const typeAlreadyExists = useMemo(() => {
        return isNewType && invitationCardsSettings.settings?.types.some(t => t.label === currentType.label);
    }, [currentType.label, invitationCardsSettings.settings, isNewType]);

    useUnsavedChangesBlock(hasUnsavedChanges, t('unsaved.changes'));

    useEffect(() => {
        onUnsavedChanges(hasUnsavedChanges || isNewType);
    }, [hasUnsavedChanges, onUnsavedChanges, isNewType]);

    useEffect(() => {
        if (currentType.label === '' && invitationCardsSettings.settings?.types && invitationCardsSettings.settings?.types?.length > 0) {
            const newCardType = invitationCardsSettings.settings?.types[0];
            if (newCardType) {
                setCurrentType(newCardType);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitationCardsSettings.settings]);

    const onChangeType = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
        const label = event.target.value as string;
        if (label === EMPTY_TYPE_LABEL && !!invitationCardsSettings.settings?.type_default) {
            setIsNewType(true);
            setCurrentType(invitationCardsSettings.settings?.type_default);
        } else {
            setIsNewType(false);
            const newCardType = invitationCardsSettings.settings?.types?.find(t => t.label === label);
            if (newCardType) {
                setCurrentType(newCardType);
            }
        }
    };

    const onChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentType({
            ...currentType,
            label: event.target.value as string,
        });
    };

    const onChangeColor = (color: string) => {
        setCurrentType({
            ...currentType,
            color: color,
        });
    };

    const onChangeLogoUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentType({
            ...currentType,
            logo_url: event.target.value as string,
        });
    };

    const onChangeImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentType({
            ...currentType,
            image_url: event.target.value as string,
        });
    };

    const onCLickSave = () => {
        setOpenConfirmModal(true);

        const newTypesArray = invitationCardsSettings.settings?.types.map(t => {
            if (t.label === currentType.label) {
                return currentType;
            }
            return t;
        });
        if (isNewType) {
            newTypesArray?.push(currentType);
            setIsNewType(false);
        }
        if (newTypesArray) {
            mutateInvitationCardSettings.mutate<InvitationCardTypeSettings[]>('types', newTypesArray);
        }
    };

    const onConfirmSave = () => {
        setOpenConfirmModal(false);
    };

    return (
        <>
            <ConfirmationDialog
                title={t('wallet.changes.warning.title')}
                description={t('wallet.changes.warning')}
                onConfirm={onConfirmSave}
                open={openConfirmModal}
                confirmButtonText={t('ok')}
            />
            <Typography variant={'h5'} gutterBottom sx={{ fontWeight: '500' }}>
                {t('types')}
            </Typography>
            {invitationCardsSettings.isLoading ? (
                <S.LoadingBox>
                    <CircularProgress />
                </S.LoadingBox>
            ) : (
                <S.DesignContainer>
                    <S.PropertiesContainer>
                        <Typography variant={'h6'}>{t('properties')}</Typography>

                        <Typography>{t('type')}</Typography>
                        <S.Dropdown value={!isNewType ? currentType.label : EMPTY_TYPE_LABEL} onChange={onChangeType}>
                            {invitationCardsSettings.settings?.types?.map(t => (
                                <S.DropdownItem key={t.label} value={t.label}>
                                    {t.label}
                                </S.DropdownItem>
                            ))}
                            <S.DropdownItem key={EMPTY_TYPE_LABEL} value={EMPTY_TYPE_LABEL} grey>
                                {isNewType ? (
                                    <>{t('new')}</>
                                ) : (
                                    <>
                                        <Add />
                                        {t('add.new.type')}
                                    </>
                                )}
                            </S.DropdownItem>
                        </S.Dropdown>

                        <S.Separator />

                        {isNewType && (
                            <>
                                <Typography>{t('label')}</Typography>
                                <S.UrlInput
                                    variant={'outlined'}
                                    placeholder={t('new.label')}
                                    value={currentType.label}
                                    onChange={onChangeLabel}
                                    error={typeAlreadyExists}
                                    helperText={typeAlreadyExists && t('type.exists')}
                                />
                            </>
                        )}

                        <Typography>{t('color')}</Typography>
                        <ColorPicker
                            color={currentType.color}
                            onColorChange={onChangeColor}
                            width={'100%'}
                            style={{ marginBottom: 2, minHeight: '60px' }}
                        />

                        <Typography>{t('url.logo')}</Typography>
                        <S.UrlInput variant={'outlined'} placeholder={'Logo url'} value={currentType.logo_url} onChange={onChangeLogoUrl} />

                        <Typography>{t('url.image')}</Typography>
                        <S.UrlInput variant={'outlined'} placeholder={'Image url'} value={currentType.image_url} onChange={onChangeImageUrl} />

                        <S.WarningMessageBox>
                            <Warning sx={{ marginRight: 1 }} />
                            <Typography>{t('wallet.changes.warning')}</Typography>
                        </S.WarningMessageBox>

                        <S.SaveButton
                            variant={'contained'}
                            onClick={onCLickSave}
                            disabled={!hasUnsavedChanges || typeAlreadyExists || invitationCardsSettings.isLoading}>
                            <SaveAlt sx={{ marginRight: 2 }} />
                            {t('save')}
                        </S.SaveButton>
                    </S.PropertiesContainer>
                    <S.CardsContainer>
                        <S.CardBox>
                            <Typography variant={'h6'}>Google</Typography>
                            <GoogleWalletPass typeSettings={currentType} />
                        </S.CardBox>
                        <S.CardBox>
                            <Typography variant={'h6'}>Apple</Typography>
                            <AppleWalletPass typeSettings={currentType} />
                        </S.CardBox>
                    </S.CardsContainer>
                </S.DesignContainer>
            )}
        </>
    );
};
