import React from 'react';
import Box from '@mui/material/Box';
import { Stack, Typography, CircularProgress, useTheme } from '@mui/material';
import { useFetchEvent } from '../../api/hooks/useFetchEvent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { DateUtil } from '../../utils/DateUtil';
import config from '../../config';

const EventDetail: React.FC = () => {
    const { t } = useTranslation();
    const { eventId } = useParams<'eventId'>();

    const theme = useTheme();
    const eventQuery = useFetchEvent(eventId);

    return eventQuery.data ? (
        <Box>
            <Stack>
                <Typography variant={'h4'} sx={{ fontWeight: '500', display: 'inline' }} gutterBottom>
                    {eventQuery.data.partner}
                </Typography>
                <Box sx={{ paddingBottom: theme.spacing(2) }}>
                    <Typography>{`${t('name')}: ${eventQuery.data.name}`}</Typography>
                    <Typography>{`${t('description')}: ${eventQuery.data.description}`}</Typography>
                    <Typography>{`${t('event.confirmation.message')}: ${eventQuery.data.confirmationMessage}`}</Typography>
                    <Typography>{`${t('start.date')}: ${DateUtil.formatDate(eventQuery.data.startDate)}`}</Typography>
                    <Typography>{`${t('end.date')}: ${DateUtil.formatDate(eventQuery.data.endDate)}`}</Typography>
                    <Typography>{`${t('redemptions.per.user')}: ${eventQuery.data.redemptionsPerUser}`}</Typography>
                    <Typography>{`${t('event.points')}: ${eventQuery.data.points}`}</Typography>
                    <Typography>{`${t('website.url')}: ${eventQuery.data.websiteUrl}`}</Typography>
                </Box>
                <Box sx={{ paddingBottom: theme.spacing(2) }}>
                    {<Typography>{`${t('qr.code')}: ${config.app.baseUrl}${config.app.qrCodePath}/${eventId}`}</Typography>}
                    {<QRCode value={`${config.app.baseUrl}${config.app.qrCodePath}/${eventId}`} size={300} />}
                </Box>
            </Stack>
        </Box>
    ) : (
        <CircularProgress />
    );
};

export default EventDetail;
