import { QueryFunctionContext, useQuery } from 'react-query';
import { api } from '../index';
import { PrizeTransformer } from '../models/transformers/PrizeTransformer';
import { ExternalPrize } from '../models/external-models/ExternalPrize';

const fetchPrize = ({ queryKey }: QueryFunctionContext) => {
    const params = {
        id: queryKey[1],
        ...(!!queryKey[2]) && {language: '__ALL__'}
    };
    return api.get<{ prize: ExternalPrize } & Response>('/prize', {
        params: params,
    });
};

export const useFetchPrize = (prizeId?: string, allLanguages: boolean = false) => {
    return useQuery(['fetchPrize', prizeId, allLanguages], fetchPrize, {
        select: response => PrizeTransformer.transformPrize(response.data.prize),
        enabled: !!prizeId
    });
};
