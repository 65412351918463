import { ExternalUpdateObjects } from '../external-models/ExternalUpdateObjects';

export class UpdateTransformer {
    static transformToUpdateObject(oldObject: Object, newObject: Object): ExternalUpdateObjects {
        let updateObjects: ExternalUpdateObjects = { updates: [] };
        this.convert(oldObject, newObject, '', updateObjects);
        return updateObjects;
    }

    private static convert(oldObject: Object, newObject: Object, completeProp: string, updateObjects: ExternalUpdateObjects) {
        for (const prop in oldObject) {
            const newCompleteProp = `${completeProp}.${prop}`;
            const oldTempObj = oldObject[prop as keyof typeof oldObject];
            const newTempObj = newObject[prop as keyof typeof newObject];
            if (newTempObj instanceof Object && !Array.isArray(newTempObj)) {
                // Do the conversion for the object on the indicated field
                this.convert(oldTempObj, newTempObj, newCompleteProp, updateObjects);
            } else if (oldTempObj !== newTempObj) {
                // Set the new value for the indicated field
                updateObjects.updates.push({
                    field: newCompleteProp.slice(1),
                    operation: 'set',
                    value: newTempObj,
                });
            }
        }
    }
}
