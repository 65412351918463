import { Alert, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NoAccessContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const AlertBox = styled(Alert)(({ theme }) => ({
    width: '100%',
    maxWidth: '700px',
}));

export const BackButton = styled(Button)(({ theme }) => ({
    margin: 20,
}));
