import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Order } from '../../components/table/Order';
import { Event } from '../../api/models/Event';
import TableRow from '@mui/material/TableRow';
import { IconButton, Skeleton, TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import MuiTable from '@mui/material/Table';
import TableHead from '../../components/table/TableHead';
import TableBody from '@mui/material/TableBody';
import TablePagination from '../../components/table/TablePagination';
import { useFetchEvents } from '../../api/hooks/useFetchEvents';
import { HeadCell } from '../../components/table/HeadCell';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { useDeleteEventMutation } from '../../api/hooks/useDeleteEventMutation';
import { DateUtil } from '../../utils/DateUtil';

const headCells: HeadCell<Event>[] = [
    {
        id: 'partner',
        numeric: false,
        disablePadding: false,
        label: 'partner',
        sortable: true,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        sortable: false,
    },
    {
        id: 'startDate',
        numeric: false,
        disablePadding: false,
        label: 'start.date',
        sortable: true,
    },
    {
        id: 'endDate',
        numeric: false,
        disablePadding: false,
        label: 'end.date',
        sortable: false,
    },
    {
        id: 'points',
        numeric: false,
        disablePadding: false,
        label: 'prize.points',
        sortable: false,
    },
    {
        id: 'redemptionsPerUser',
        numeric: false,
        disablePadding: false,
        label: 'redemptions.per.user',
        sortable: false,
    },
];

interface Props {
    campaignId: string;
    onEventEditPressed: (id: string) => void;
}

const getOrderByParamForRequest = (param?: string) => {
    switch (param) {
        case 'partner':
            return 'partner';
        case 'startDate':
            return 'start-datetime';
        default:
            return undefined;
    }
};

const tableCellHeight = 73;

const EventsOverview: React.FC<Props> = ({ campaignId, onEventEditPressed }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Event>();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [eventToBeDeleted, setEventToBeDeleted] = useState<Event | null>(null);
    const navigate = useNavigate();

    const { t } = useTranslation();

    const eventsQuery = useFetchEvents(campaignId, pageSize, getOrderByParamForRequest(orderBy), order === 'asc' ? 'ASCENDING' : 'DESCENDING');
    const deleteEventMutation = useDeleteEventMutation();

    useEffect(() => {
        if (deleteEventMutation.isSuccess) {
            setEventToBeDeleted(null);
            eventsQuery.refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteEventMutation.isSuccess]);

    const onDeleteEventPressed = (event: Event) => {
        setEventToBeDeleted(event);
    };

    const onConfirmDeleteEvent = () => {
        if (eventToBeDeleted) {
            deleteEventMutation.mutate(eventToBeDeleted.id);
        }
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (eventsQuery.data && eventsQuery.data.pages.length <= newPage) {
            eventsQuery.fetchNextPage();
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const renderSkeleton = (): ReactNode => {
        return Array.from({ length: pageSize }, (_, index) => (
            <TableRow hover tabIndex={-1} key={index.toString()} sx={{ height: tableCellHeight }}>
                {headCells.map(headCell => (
                    <TableCell key={headCell.label}>
                        <Skeleton variant={'text'} width={100} />
                    </TableCell>
                ))}
                <TableCell width={80}>
                    <Skeleton variant={'text'} />
                </TableCell>
            </TableRow>
        ));
    };

    const renderRowEntry = (row: Event, key: keyof Event, onClick?: () => void): ReactNode => {
        switch (key) {
            case 'endDate':
                return (
                    <TableCell align="left" onClick={onClick}>
                        {DateUtil.formatDate(row.endDate)}
                    </TableCell>
                );
            case 'points':
                return (
                    <TableCell align="left" onClick={onClick}>
                        {row.points}
                    </TableCell>
                );
            case 'partner':
                return (
                    <TableCell align="left" onClick={onClick}>
                        {row.partner}
                    </TableCell>
                );
            case 'redemptionsPerUser':
                return (
                    <TableCell align="left" onClick={onClick}>
                        {row.redemptionsPerUser}
                    </TableCell>
                );
            case 'startDate':
                return (
                    <TableCell align="left" onClick={onClick}>
                        {DateUtil.formatDate(row.startDate)}
                    </TableCell>
                );
            case 'name':
                return (
                    <TableCell component="th" scope="row" onClick={onClick}>
                        {row.name}
                    </TableCell>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={2}>
                <TableContainer>
                    <MuiTable>
                        <TableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
                        <TableBody>
                            {eventsQuery.data?.pages[page]
                                ? eventsQuery.data.pages[page].map(row => (
                                      <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                                          {headCells.map(headCell => (
                                              <Fragment key={headCell.id}>
                                                  {renderRowEntry(row, headCell.id, () => navigate(`event/${row.id}`))}
                                              </Fragment>
                                          ))}
                                          <TableCell align={'right'}>
                                              <IconButton onClick={() => onEventEditPressed(row.id)}>
                                                  <Edit />
                                              </IconButton>
                                              <IconButton onClick={() => onDeleteEventPressed(row)}>
                                                  <Delete />
                                              </IconButton>
                                          </TableCell>
                                      </TableRow>
                                  ))
                                : renderSkeleton()}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TablePagination
                    count={eventsQuery.data ? (eventsQuery.hasNextPage ? -1 : eventsQuery.data.pages.flatMap(p => p).length) : 0}
                    page={page}
                    handleChangePage={handleChangePage}
                    rowsPerPage={pageSize}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <ConfirmationDialog
                title={t('delete.event.title', { name: eventToBeDeleted?.partner })}
                description={t('delete.event.description', { name: eventToBeDeleted?.description })}
                onConfirm={onConfirmDeleteEvent}
                onCancel={() => setEventToBeDeleted(null)}
                open={!!eventToBeDeleted}
            />
        </Box>
    );
};

export default EventsOverview;
