import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

const DashboardButton = styled(Button)(({ theme }) => ({
    width: 200,
    height: 200,
    margin: 25,
}));

const DashboardButtonContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const S = {
    DashboardButton,
    DashboardButtonContent,
};

export default S;
