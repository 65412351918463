import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    SelectChangeEvent,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import MuiTable from '@mui/material/Table';
import { useTranslation } from 'react-i18next';
import * as S from './InvitationCardsTable.styles';
import TableHead from '../table/TableHead';
import TablePagination from '../table/TablePagination';
import { HeadCell } from '../table/HeadCell';
import { Clear, Delete, Search, Send } from '@mui/icons-material';
import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { trim } from 'lodash';
import { InvitationCard } from '../../api/models/firestore/InvitationCard';
import { useFetchInvitationCardsSettings } from '../../api/hooks/firestore/useFetchInvitationCardsSettings';
import ActionButton from '../../models/ActionButton';
import { findLastSeason } from '../../utils/SeasonsUtil';

const headCells: HeadCell<any>[] = [
    {
        id: 'card_number',
        numeric: false,
        disablePadding: false,
        label: 'card.number',
        sortable: false,
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'name',
        align: 'center',
        sortable: false,
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'email',
        align: 'center',
        sortable: false,
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'type',
        align: 'center',
        sortable: false,
    },
    {
        id: 'season',
        numeric: false,
        disablePadding: false,
        label: 'season.last',
        align: 'center',
        sortable: false,
    },
    {
        id: 'language',
        numeric: false,
        disablePadding: false,
        label: 'language',
        align: 'center',
        sortable: false,
    },
];

export type InvitationCardTableRef = {
    resetFilters: () => void;
};

type Props = {
    data?: InvitationCard[];
    actionButtons?: ActionButton[];
    emptyDataComponent?: React.ReactElement;
    title?: string;
    onTableDataChanged?: (data?: InvitationCard[]) => void;
    onSendSingleClicked?: (card: InvitationCard) => void;
};

export const InvitationCardsTable = React.forwardRef<InvitationCardTableRef, Props>(
    ({ data, actionButtons, emptyDataComponent, title, onTableDataChanged, onSendSingleClicked }, ref?) => {
        const { t } = useTranslation();
        const ALL_TYPES_TEXT = t('all.types');
        const ALL_SEASONS_TEXT = t('all.seasons');
        const [tableData, setTableData] = useState<InvitationCard[]>();
        const [page, setPage] = useState<number>(0);
        const [pageSize, setPageSize] = useState<number>(5);
        const [type, setType] = useState<string>(ALL_TYPES_TEXT);
        const [search, setSearch] = useState<string>('');
        const [showClearSearch, setShowClearSearch] = useState<boolean>(false);

        const [season, setSeason] = useState<string>(ALL_SEASONS_TEXT);

        const invitationCardsSettings = useFetchInvitationCardsSettings();

        useImperativeHandle(ref, () => ({
            resetFilters: () => {
                setSeason(ALL_SEASONS_TEXT);
                setType(ALL_TYPES_TEXT);
                setSearch('');
            },
        }));

        // This useEffect fixes the traslations of the filter not updating on language change
        useEffect(() => {
            if (!invitationCardsSettings.settings?.types.some(t => t.label === type)) {
                setType(ALL_TYPES_TEXT);
            }
            if (!allSeasons?.includes(season)) {
                setSeason(ALL_SEASONS_TEXT);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ALL_TYPES_TEXT, ALL_SEASONS_TEXT]);

        useEffect(() => {
            const filteredData = data?.filter(d => {
                return (
                    (type === ALL_TYPES_TEXT || d.cardType === type) &&
                    (season === ALL_SEASONS_TEXT || findLastSeason(d).season === season) &&
                    trim(search)
                        .toLowerCase()
                        .split(' ')
                        .every(
                            s =>
                                d.lastname.toLowerCase().includes(s) ||
                                d.firstname.toLowerCase().includes(s) ||
                                d.email.toLowerCase().includes(s) ||
                                d.cardNumber.toString().includes(s),
                        )
                );
            });
            setTableData(filteredData);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data, search, type, season]);

        useEffect(() => {
            onTableDataChanged && onTableDataChanged(tableData);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tableData]);

        const allSeasons = useMemo(() => {
            const allDataSeasons = data?.map(card => {
                const lastSeason = findLastSeason(card);
                return lastSeason.season;
            });
            return allDataSeasons?.filter((s, i) => allDataSeasons?.indexOf(s) === i);
        }, [data]);

        const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPageSize(parseInt(event.target.value, 10));
            setPage(0);
        };

        const onTypeChanged = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
            setType(event.target.value as string);
        };

        const onSeasonChanged = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
            setSeason(event.target.value as string);
        };

        const onSearchChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setShowClearSearch(true);
            setSearch(event.target?.value || '');
        };

        const onSearchFocus = () => {
            if (search !== '') {
                setShowClearSearch(true);
            }
            setSearch(trim(search));
        };

        const onSearchBlur = () => {
            setShowClearSearch(false);
            setSearch(trim(search));
        };

        const onClickClearSearch = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            setSearch('');
        };

        return (
            <Box>
                <S.TitleAndActionContainer>
                    <Typography variant={'h5'} gutterBottom sx={{ fontWeight: '500' }}>
                        {title || t('cards')}
                    </Typography>
                    <S.ActionsBox>
                        <S.Dropdown value={season} disabled={!data || data.length <= 0} onChange={onSeasonChanged}>
                            <S.DropdownItem key={ALL_SEASONS_TEXT} value={ALL_SEASONS_TEXT}>
                                {ALL_SEASONS_TEXT}
                            </S.DropdownItem>
                            {allSeasons?.map(t => (
                                <S.DropdownItem key={t} value={t}>
                                    {t}
                                </S.DropdownItem>
                            ))}
                        </S.Dropdown>

                        <S.Dropdown value={type} disabled={!data || data.length <= 0} onChange={onTypeChanged}>
                            <S.DropdownItem key={ALL_TYPES_TEXT} value={ALL_TYPES_TEXT}>
                                {ALL_TYPES_TEXT}
                            </S.DropdownItem>
                            {invitationCardsSettings.settings?.types.map(t => (
                                <S.DropdownItem key={t.label} value={t.label}>
                                    {t.label}
                                </S.DropdownItem>
                            ))}
                        </S.Dropdown>
                        <TextField
                            placeholder={'Search'}
                            value={search}
                            onChange={onSearchChanged}
                            onFocus={onSearchFocus}
                            onBlur={onSearchBlur}
                            disabled={!data || data.length <= 0}
                            sx={{ height: 1, marginRight: 2 }}
                            InputProps={{
                                style: {
                                    height: '38px',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {!showClearSearch ? (
                                            <Search />
                                        ) : (
                                            <IconButton sx={{ m: 0, p: 0 }} onMouseDown={onClickClearSearch}>
                                                <Clear />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {actionButtons &&
                            actionButtons.map((b, index) => (
                                <Button
                                    variant={b.variant || 'contained'}
                                    disabled={b.disabled}
                                    sx={{
                                        alignSelf: 'flex-start',
                                        marginRight: actionButtons.length > 1 && index < actionButtons.length - 1 ? 2 : 0,
                                    }}
                                    onClick={b.actionButtonClicked}>
                                    {b.icon && <b.icon style={{ paddingRight: 5 }} />}
                                    {b.actionButtonText}
                                </Button>
                            ))}
                    </S.ActionsBox>
                </S.TitleAndActionContainer>
                {!!emptyDataComponent && (!data || data.length <= 0) ? (
                    emptyDataComponent
                ) : (
                    <S.TablePaper>
                        <TableContainer>
                            <MuiTable>
                                <TableHead headCells={headCells} />
                                <TableBody>
                                    {tableData &&
                                        tableData.slice(page * pageSize, page * pageSize + pageSize).map(d => (
                                            <>
                                                <TableRow>
                                                    <TableCell align={'left'}>
                                                        <Typography>{d.cardNumber}</Typography>
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        <Typography>
                                                            {d.firstname} {d.lastname}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        <Typography>{d.email}</Typography>
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        <Typography>{d.cardType}</Typography>
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        <Typography>{findLastSeason(d).season}</Typography>
                                                    </TableCell>
                                                    <TableCell align={'center'}>
                                                        <Typography>{d.language}</Typography>
                                                    </TableCell>
                                                    <TableCell align={'right'}>
                                                        <IconButton onClick={() => onSendSingleClicked && onSendSingleClicked(d)}>
                                                            <Send />
                                                        </IconButton>
                                                        <IconButton disabled>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                </TableBody>
                            </MuiTable>
                        </TableContainer>
                        <TablePagination
                            count={tableData ? tableData.length : 0}
                            page={page}
                            handleChangePage={handleChangePage}
                            rowsPerPage={pageSize}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </S.TablePaper>
                )}
            </Box>
        );
    },
);
