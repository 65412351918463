import { useTranslation } from 'react-i18next';

export enum InvitationCardType {
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
}

export const getInvitationCardKind = (type: InvitationCardType) => {
    switch (type) {
        case InvitationCardType.B:
        case InvitationCardType.C:
            return 'A';
        case InvitationCardType.D:
        case InvitationCardType.E:
            return 'B';
        case InvitationCardType.F:
        case InvitationCardType.G:
        case InvitationCardType.H:
            return 'C';
        case InvitationCardType.I:
        case InvitationCardType.J:
            return '¢';
        default:
            return undefined;
    }
};

export const getInvitationCardTitleFR = (type: InvitationCardType) => {
    switch (type) {
        case InvitationCardType.B:
            return "Membre d'honneur";
        case InvitationCardType.D:
            return 'Comité officiel';
        case InvitationCardType.G:
        case InvitationCardType.H:
        case InvitationCardType.J:
            return "Carte d'arbitre";
        case InvitationCardType.C:
        case InvitationCardType.E:
        case InvitationCardType.F:
        case InvitationCardType.I:
        default:
            return "Carte d'invitation";
    }
};

export const getInvitationCardTitleNL = (type: InvitationCardType) => {
    switch (type) {
        case InvitationCardType.B:
            return 'Erelid';
        case InvitationCardType.D:
            return 'Comitélid';
        case InvitationCardType.G:
        case InvitationCardType.H:
        case InvitationCardType.J:
            return 'Scheidsrechterskaart';
        case InvitationCardType.C:
        case InvitationCardType.E:
        case InvitationCardType.F:
        case InvitationCardType.I:
        default:
            return 'Uitnodigingskaart';
    }
};

export const getInvitationCardTitleEN = (type: InvitationCardType) => {
    switch (type) {
        case InvitationCardType.B:
            return 'Honorary Member';
        case InvitationCardType.D:
            return 'Committee Member';
        case InvitationCardType.G:
        case InvitationCardType.H:
        case InvitationCardType.J:
            return 'Referee card';
        case InvitationCardType.C:
        case InvitationCardType.E:
        case InvitationCardType.F:
        case InvitationCardType.I:
        default:
            return 'Invitation card';
    }
};

export const useGetCardTitleByLanguage = (type: InvitationCardType) => {
    const { i18n } = useTranslation();

    switch (i18n.language) {
        case 'nl':
            return getInvitationCardTitleNL(type);
        case 'fr':
            return getInvitationCardTitleFR(type);
        default:
            return getInvitationCardTitleEN(type);
    }
};
