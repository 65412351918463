import { useTranslation } from 'react-i18next';
import CreateOrUpdateModal from '../CreateOrUpdateModal';
import * as S from './SendNewEmailModal.styles';
import { TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Warning } from '@mui/icons-material';
import { validate as isEmail } from 'email-validator';

type Props = {
    open: boolean;
    email: string;
    onSend: (email: string, shouldUpdate?: boolean) => void;
    onCancel: () => void;
    title?: string;
    onChangeEmail?: (email: string) => void;
    shouldAskToUpdate?: boolean;
    warningText?: string;
};

export const SendNewEmailModal: React.FC<Props> = ({ open, email, title, onChangeEmail, shouldAskToUpdate, onCancel, onSend, warningText }) => {
    const { t } = useTranslation();
    const [initialEmail, setInitialEmail] = useState<string>(email);
    const [currentEmail, setCurrentEmail] = useState<string>(email);
    const [shouldUpdate, setShouldUpdate] = useState<boolean>(true);

    useEffect(() => {
        if (email !== currentEmail) {
            setCurrentEmail(email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    useEffect(() => {
        if (open) {
            setInitialEmail(email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSendEmail = () => {
        const shouldDoUpdate = shouldUpdate && initialEmail !== currentEmail;
        const update = shouldAskToUpdate ? shouldDoUpdate : undefined;
        onSend(currentEmail, update);
    };

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentEmail(event.currentTarget.value);
        onChangeEmail && onChangeEmail(event.currentTarget.value);
    };

    const onChangeShouldUpdate = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setShouldUpdate(checked);
    };

    const areFieldsValid = () => {
        return isEmail(currentEmail)
    }

    return (
        <CreateOrUpdateModal
            title={title || t('send.new.email')}
            onCreate={onSendEmail}
            isLoading={false}
            open={open}
            onCancel={onCancel}
            areFieldsValid={areFieldsValid}
            buttonText={t('send')}>
            <S.PaddedBox>
                <TextField label={t('email')} value={currentEmail} onChange={onEmailChange} />
                {shouldAskToUpdate && (
                    <FormControlLabel
                        label={t('update.email')}
                        control={
                            <Checkbox value={shouldUpdate} onChange={onChangeShouldUpdate} disabled={initialEmail === currentEmail} defaultChecked />
                        }
                    />
                )}
                {warningText && (
                    <S.WarningMessageBox>
                        <Warning sx={{ marginRight: 1 }} />
                        <Typography>{warningText}</Typography>
                    </S.WarningMessageBox>
                )}
            </S.PaddedBox>
        </CreateOrUpdateModal>
    );
};
