import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import firebase from '../../../firebase';
import { isEqual } from 'lodash';
import { InvitationCard } from '../../models/firestore/InvitationCard';
import { GlobalConstants } from '../../../constants';

export const useFetchInvitationCards = () => {
    const [invitationCards, setInvitationCards] = useState<InvitationCard[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const firestore = getFirestore(firebase.app);
    const invitationCardsCollection = collection(firestore, `${GlobalConstants.INVITATION_CARDS.BASE_DOCS}/${GlobalConstants.INVITATION_CARDS.CARDS_DOCS}/imported`);

    getDocs(invitationCardsCollection)
        .then(docData => {
            const newData: InvitationCard[] = [];
            docData.forEach(data => {
                newData.push({
                    ...data.data(),
                    docId: data.id
                } as InvitationCard);
            });
            if (!!newData && !isEqual(newData, invitationCards)) {
                setError(undefined);
                setInvitationCards(newData);
            }
        })
        .catch(err => {
            setError(err);
        });

    useEffect(() => {
        if (invitationCards || error) {
            setIsLoading(false);
        }
    }, [invitationCards, error]);

    return {
        invitationCards: invitationCards,
        isLoading: isLoading,
        isError: !!error,
    };
};
