import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as S from './InvitationCardsUpload.styles';
import { Add, Clear, Send } from '@mui/icons-material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as xlsx from 'xlsx';
import { InvitationCardTableRef, InvitationCardsTable } from '../../components/invitation-cards-table/InvitationCardsTable';
import { useNavigate } from 'react-router-dom';
import InvitationCardExcel from '../../models/InvitationCardExcel';
import { convertInvitationCardExcel } from '../../api/models/transformers/InvitationCardHolderTransformer';
import { InvitationCard } from '../../api/models/firestore/InvitationCard';
import { useMutateInvitationCards } from '../../api/hooks/firestore/useMutateInvitationCards';
import { useFetchInvitationCardsSettings } from '../../api/hooks/firestore/useFetchInvitationCardsSettings';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ActionButton from '../../models/ActionButton';
import { SendCardsModal } from './components/SendCardsModal/SendCardsModal';
import { useMutateInvitationCardsMails } from '../../api/hooks/useMutateInvitationCardsMails';
import { useMutateInvitationCardsSendout } from '../../api/hooks/firestore/useMutateInvitationCardsSendout';
import { useFetchInvitationCards } from '../../api/hooks/firestore/useFetchInvitationCards';
import { validate as isEmail } from 'email-validator';

export const InvitationCardsUpload = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const uploadInputRef = useRef<HTMLInputElement>(null);
    const [importedCards, setImportedCards] = useState<InvitationCard[]>();
    const [tableData, setTableData] = useState<InvitationCard[]>();
    const [dataToSend, setDataToSend] = useState<InvitationCard[]>();

    const fileUploadArea = useRef<HTMLElement>(null);
    const [warningText, setWarningText] = useState<string>();
    const [unexistingTypes, setUnexistingTypes] = useState<string[]>();

    const mutateInvitationCards = useMutateInvitationCards();
    const invitationCardsSettings = useFetchInvitationCardsSettings();
    const mutateSendInvitationCards = useMutateInvitationCardsMails();
    const mutateInvitationCardsSendout = useMutateInvitationCardsSendout();

    const cardTableRef = useRef<InvitationCardTableRef>(null);
    const fetchInvitationCards = useFetchInvitationCards();

    useEffect(() => {
        fileUploadArea.current?.addEventListener('dragover', handleDragOver);
        fileUploadArea.current?.addEventListener('drop', onFileDroped);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUploadArea.current]);

    useEffect(() => {
        if (mutateInvitationCards.isSuccess && mutateInvitationCards.data && mutateInvitationCards.data.length >= 0) {
            const dataToSend = mutateInvitationCards.data.filter(c => isEmail(c.email));
            if (dataToSend && dataToSend.length > 0) {
                mutateSendInvitationCards.mutate(dataToSend);
            } else {
                sendOutSuccess()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateInvitationCards.isSuccess]);

    useEffect(() => {
        if (mutateSendInvitationCards.isSuccess) {
            if (mutateInvitationCards.data && importedCards) {
                mutateInvitationCardsSendout.mutate(mutateInvitationCards.data, importedCards);
            }
            sendOutSuccess()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutateSendInvitationCards.isSuccess]);

    const sendOutSuccess = () => {
        const newImportedCardsList = importedCards?.filter(c => !mutateInvitationCards.data?.some(d => c.cardNumber === d.cardNumber));
        if (!newImportedCardsList || newImportedCardsList.length <= 0) {
            navigate('..', { replace: true });
        } else {
            setImportedCards(newImportedCardsList);
        }
    }

    const clickUploadButton = () => {
        uploadInputRef.current && uploadInputRef.current.click();
    };

    const onSendSingleClicked = (card: InvitationCard) => {
        mutateInvitationCards.mutate(card);
    };

    const clickSendButton = () => {
        if (tableData) {
            const typesArray = invitationCardsSettings.settings?.types.map(t => t.label);
            const allNonExistingTypes = tableData.map(c => (!typesArray?.includes(c.cardType) ? c.cardType : undefined));
            const nonExistingTypes = allNonExistingTypes.filter((v, index) => !!v && allNonExistingTypes.indexOf(v) === index);
            if (!nonExistingTypes || nonExistingTypes.length <= 0) {
                const sortedDataToSend = tableData.sort((a, b) => a.language.localeCompare(b.language));
                setDataToSend(sortedDataToSend);
            } else {
                setWarningText(`${t('type.exists.not.warning')} ${t('type.exists.not.warning.list')}`);
                setUnexistingTypes(nonExistingTypes as string[]);
            }
        }
    };

    const clickCancelButton = () => {
        navigate('..', { replace: true });
    };

    const onTableDataChanged = (data?: InvitationCard[]) => {
        setTableData(data);
    };

    const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onFileDroped = async (event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
        readFile(event.dataTransfer?.files);
    };

    const onFileChanged = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        readFile(uploadInputRef?.current?.files);
    };

    const readFile = async (fileList?: FileList | null) => {
        if (fileList?.[0]) {
            const arrayBuffer = await fileList[0].arrayBuffer();
            const excelDoc = xlsx.read(arrayBuffer);
            const data = xlsx.utils.sheet_to_json(excelDoc.Sheets[excelDoc.SheetNames[0]]) as InvitationCardExcel[];
            const importedCards = data
                .filter(d => !fetchInvitationCards.invitationCards || filterSendoutSuccess(fetchInvitationCards.invitationCards, d.Numéro, d.Saison))
                .map(convertInvitationCardExcel);
            if (importedCards.length <= 0) {
                setWarningText(`${t('import.no.cards')}`);
            } else if (importedCards.some(c => c.cardType === '')) {
                setWarningText(`${t('type.map.failed.warning')}`);
            } else {
                setImportedCards(importedCards);
            }
        }
    };

    const filterSendoutSuccess = (cards: InvitationCard[], cardNumber: number, season: string) => {
        const card = cards.find(c => c.cardNumber === cardNumber);
        const seasonValues = card ? Object.values(card.seasons).find(s => s.season === season) : undefined;
        return !season || !seasonValues?.sendoutCompleted;
    };

    const onOkWarningModal = () => {
        setWarningText(undefined);
        setUnexistingTypes(undefined);
    };

    const onClickDoneSend = () => {
        const newImportedCardsList = importedCards?.filter(c => !dataToSend?.some(d => c.cardNumber === d.cardNumber));
        if (!newImportedCardsList || newImportedCardsList.length <= 0) {
            navigate('..', { replace: true });
        } else {
            setImportedCards(newImportedCardsList);
            setDataToSend(undefined);
            cardTableRef.current?.resetFilters();
        }
    };

    const actionButtons: ActionButton[] = [
        {
            actionButtonClicked: clickSendButton,
            actionButtonText: `${t('save')} & ${t('send')}`,
            disabled: !importedCards || importedCards.length <= 0,
            icon: Send,
        },
        {
            actionButtonClicked: clickCancelButton,
            actionButtonText: t('cancel'),
            icon: Clear,
        },
    ];

    const emptyDataContainer = (
        <S.FileUploadArea ref={fileUploadArea}>
            <S.FileUploadText>{t('upload.box')}</S.FileUploadText>
            <Button variant={'contained'} onClick={clickUploadButton}>
                <Add sx={{ paddingRight: 1 }} />
                {t('upload')}
            </Button>
        </S.FileUploadArea>
    );

    return (
        <Box>
            <ConfirmationDialog
                title={t('import.failed.title')}
                description={warningText || ''}
                onConfirm={onOkWarningModal}
                open={!!warningText}
                confirmButtonText={t('ok')}
                list={unexistingTypes}
            />
            <SendCardsModal open={(dataToSend && dataToSend?.length > 0) || false} data={dataToSend} onDone={onClickDoneSend} />
            <input
                ref={uploadInputRef}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                type="file"
                onChange={onFileChanged}
                hidden
            />
            <InvitationCardsTable
                data={importedCards}
                ref={cardTableRef}
                title={t('cards.invitation.upload')}
                actionButtons={actionButtons}
                emptyDataComponent={emptyDataContainer}
                onTableDataChanged={onTableDataChanged}
                onSendSingleClicked={onSendSingleClicked}
            />
        </Box>
    );
};
