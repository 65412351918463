import { QueryFunctionContext, useInfiniteQuery } from 'react-query';
import { api } from '../index';
import { ExternalPrizes } from '../models/external-models/ExternalPrizes';
import { PrizeTransformer } from '../models/transformers/PrizeTransformer';

const fetchPrizes = ({ queryKey, pageParam }: QueryFunctionContext) => {
    const params = new URLSearchParams();
    params.append('page-size', queryKey[2] as string);
    params.append('campaign', queryKey[1] as string);
    if (pageParam) {
        params.append('page', pageParam as string);
    }
    if (queryKey[3]) {
        const segments = queryKey[3] as string[];
        segments.forEach(segment => params.append('segments', segment));
    }
    return api.get<ExternalPrizes>('/prizes', { params });
};

export const useFetchPrizes = (campaignId: string, pageSize: number, segments: string[]) => {
    return useInfiniteQuery(['fetchPrizes', campaignId, pageSize, segments], fetchPrizes, {
        select: response => ({
            pages: response.pages.map(page => PrizeTransformer.transformPrizes(page.data)),
            pageParams: response.pageParams,
        }),
        getPreviousPageParam: firstPage => firstPage.data.previous_page,
        getNextPageParam: lastPage => lastPage.data.next_page,
        keepPreviousData: true,
        enabled: segments != null,
    });
};
