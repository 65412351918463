import { useMutation } from 'react-query';
import { api } from '../index';

const deleteEventMutation = (id: string) => {
    return api.delete('/event', {
        params: {
            id: id,
        },
    });
};

export const useDeleteEventMutation = () => {
    return useMutation('deleteEventMutation', deleteEventMutation);
};
