import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

const LanguageButton = styled(Button)(({ theme }) => ({
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[400]}` ,
    color: theme.palette.text.primary,
    textTransform: 'none',
    margin: 20,
}));

const IconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
}));

const S = {
    LanguageButton,
    IconContainer,
};

export default S;
