import { alpha, styled } from '@mui/material/styles';
import { Box, Tab, Tabs } from '@mui/material';

export const TabsContainer = styled(Tabs)(({ theme }) => ({
    marginLeft: 30, 
    display: 'flex', 
    alignItems: 'center' 
}));

export const NavTab = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    },
    borderRadius: 10,
    alignSelf: 'center',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));
