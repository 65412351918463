import { ExternalAddPrizeOptionVariables } from '../../api/models/external-models/ExternalAddPrizeOptionVariables';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
    TextField,
    InputBaseComponentProps,
    Stack,
    Chip,
    Button,
    useTheme,
    IconButton,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Delete, Add } from '@mui/icons-material';
import { StringDictionary } from '../../api/models/StringDictionary';

interface Props {
    setPrizeOptions: React.Dispatch<React.SetStateAction<ExternalAddPrizeOptionVariables[]>>;
    prizeOptions: ExternalAddPrizeOptionVariables[];
}

interface Option {
    name: string;
    values: string[];
}

const generatePossibleOptionsMatrix = (prizeOptions: Option[]): StringDictionary[] => {
    const keys = prizeOptions.map(option => option.name);
    const matrix = keys.map(key => prizeOptions.find(option => option.name === key)?.values || []);
    const combos = matrix.reduce(
        (acc, currentValue) => {
            return acc.flatMap(c => currentValue.map(n => c.concat(n)));
        },
        [[]] as string[][],
    );
    if (combos[0]?.length === 0) return [];

    const createObject = (values: string[]) => Object.fromEntries(keys.map((key, i) => [key, values[i]]));
    return combos.map(createObject);
};

const numberInputProps: InputBaseComponentProps = { inputMode: 'numeric', pattern: '[0-9]*' };

const areDictionariesEqual = (dict1: StringDictionary, dict2: StringDictionary) => {
    return Object.entries(dict1).every(([key, value]) => dict2[key] === value);
};

const AddPrizeOptionSection: React.FC<Props> = ({ setPrizeOptions, prizeOptions }) => {
    const theme = useTheme();
    const [qualities, setQualities] = useState<Option[]>([]);
    const { t } = useTranslation();
    const optionsMatrix = useMemo(() => generatePossibleOptionsMatrix(qualities), [qualities]);
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        setPrizeOptions(existingOptions =>
            optionsMatrix.map(value => ({
                stock: existingOptions.find(option => areDictionariesEqual(value, option.qualities))?.stock || 0,
                qualities: value,
                images: [],
            })),
        );
    }, [optionsMatrix, setPrizeOptions]);

    const addNewPrizeQuality = () => {
        setQualities(qualities => [...qualities, { name: '', values: [] }]);
    };

    const renderQualityItem = (quality: Option, qualityIndex: number) => {
        const onChangeQualityName = (evt: React.ChangeEvent<HTMLInputElement>) => {
            const newQualities = qualities.map((value, index) => {
                if (index === qualityIndex) {
                    return {
                        name: evt.target.value,
                        values: [...value.values],
                    };
                }
                return value;
            });
            setQualities(newQualities);
        };

        const onAddQualityValue = () => {
            const inputEl = inputRef.current! as HTMLInputElement;
            if (inputEl.value.trim() !== ''){
                const newQualities = qualities.map((value, index) => {
                    if (index === qualityIndex) {
                        return {
                            name: value.name,
                            values: [...value.values.filter(val => val.toLowerCase() !== inputEl.value.toLowerCase()), inputEl.value],
                        };
                    }
                    return value;
                });
                setQualities(newQualities);
                inputEl.value = '';
            }
        };

        const onAddQualityKeyPressed = (evnt: React.KeyboardEvent<HTMLInputElement>) => {
            if (evnt.key.toLowerCase() === 'enter') {
                onAddQualityValue();
            }
        };

        const renderQualityItemValue = (value: string) => {
            const deleteQualityValue = () => {
                setQualities(
                    qualities.map((quality, index) => {
                        if (index === qualityIndex) {
                            return {
                                name: quality.name,
                                values: quality.values.filter(val => val !== value),
                            };
                        }
                        return quality;
                    }),
                );
            };

            return <Chip key={value} sx={{ marginRight: theme.spacing(1) }} label={value} onDelete={deleteQualityValue} />;
        };

        const deleteVariant = () => {
            setQualities(qualities.filter((quality, index) => index !== qualityIndex));
        };

        return (
            <Stack direction={'row'} spacing={1} key={qualityIndex.toString()}>
                <TextField label={t('name')} value={quality.name} onChange={onChangeQualityName} />
                <TextField
                    fullWidth
                    label={t('qualities')}
                    onKeyPress={onAddQualityKeyPressed}
                    inputRef={inputRef}
                    InputProps={{
                        startAdornment: quality.values.map(renderQualityItemValue),
                        endAdornment: (
                            <IconButton onClick={onAddQualityValue}>
                                <Add />
                            </IconButton>
                        ),
                    }}
                />
                <IconButton onClick={deleteVariant}>
                    <Delete />
                </IconButton>
            </Stack>
        );
    };

    const onChangeStockAmount = (amount: string, prizeOptionIndex: number) => {
        setPrizeOptions(existingOptions => [
            ...existingOptions.slice(0, prizeOptionIndex),
            {
                ...existingOptions[prizeOptionIndex],
                stock: +amount,
            },
            ...existingOptions.slice(prizeOptionIndex + 1),
        ]);
    };

    return (
        <>
            <Stack spacing={2}>{qualities.map(renderQualityItem)}</Stack>
            <TableContainer component={Paper} sx={{ marginTop: theme.spacing(2) }}>
                <Table size={'small'} sx={{ minWidth: 100 }}>
                    <TableHead>
                        <TableRow>
                            {qualities.map(q => (
                                <TableCell key={q.name}>{q.name}</TableCell>
                            ))}
                            <TableCell>Stock</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prizeOptions.map((option, index) => (
                            <TableRow key={index.toString()}>
                                {Object.values(option.qualities).map(value => (
                                    <TableCell key={value}>{value}</TableCell>
                                ))}
                                <TableCell>
                                    <TextField
                                        onChange={event => onChangeStockAmount(event.target.value, index)}
                                        size={'small'}
                                        InputProps={{ inputProps: numberInputProps }}
                                        type={'number'}
                                        value={Number(option.stock).toString()}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button sx={{ marginTop: theme.spacing(1) }} onClick={addNewPrizeQuality}>
                {t('add.prize.quality')}
            </Button>
        </>
    );
};

export default AddPrizeOptionSection;
