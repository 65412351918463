import { useInfiniteQuery, QueryFunctionContext } from 'react-query';
import { api } from '../index';
import { ExternalOrders } from '../models/external-models/ExternalOrders';
import { OrderTransformer } from '../models/transformers/OrderTransformer';
import { useEffect, useState } from 'react';
import { Order } from '../models/Order';

const fetchOrders = ({ pageParam }: QueryFunctionContext) => {
    const params = new URLSearchParams();
    params.append('page-size', '100');
    if (pageParam) {
        params.append('page', pageParam as string);
    }
    return api.get<ExternalOrders>('/orders', { params });
};

export const useFetchOrders = () => {
    const [orders, setOrders] = useState<Order[]>();

    const query = useInfiniteQuery('fetchOrders', fetchOrders, {
        getNextPageParam: lastPage => lastPage.data.next_page,
        enabled: false,
    });

    useEffect(() => {
        if (query.hasNextPage) {
            query.fetchNextPage();
        }
    }, [query.hasNextPage, query]);

    useEffect(() => {
        if (query.data && !query.isFetching) {
            setOrders(query.data.pages.flatMap(page => OrderTransformer.transformOrders(page.data.orders)));
        }
    }, [query.data, query.isFetching]);

    return {
        orders,
        isFetching: query.isFetching,
        refetch: query.refetch,
        hasNextPage: query.hasNextPage,
    };
};
