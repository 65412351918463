import { DocumentData, doc, getFirestore, writeBatch } from 'firebase/firestore';
import config from '../../../config';
import firebase from '../../../firebase';
import { isEqual, pick } from 'lodash';

export const useEditAppConfiguration = (currentData?: DocumentData) => {
    const firestore = getFirestore(firebase.app);
    const appConfig = doc(firestore, config.firestore.settingsDocs, config.firestore.appConfiguration);
    const batch = writeBatch(firestore);

    const editAppConfig = async (newData: DocumentData) => {
        if (currentData) {
            const changes = Object.keys(newData).filter(key => !isEqual(currentData[key], newData[key]));
            const changedData = pick(newData, changes);
            await batch.update(appConfig, { ...changedData });
            await batch.commit();
        }
    };

    return {
        update: editAppConfig,
    };
};
