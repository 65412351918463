import { initializeApp } from 'firebase/app';
import config from './config';
import { getAuth } from 'firebase/auth';

const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    ...config.firebase,
});
const auth = getAuth(app);

const firebase = { app, auth };
export default firebase;
