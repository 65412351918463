import { Box, Button, MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DesignContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
}));

export const CardBox= styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PropertiesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '40%',
  overflow: 'scroll',
  height: '81vh',
  paddingRight: 20
}));

export const Dropdown = styled(Select)(({ theme }) => ({
  width: '100%',
  marginBottom: 20,
  textAlign: 'center',
}));

export const WarningMessageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  padding: 15,
  width: 200,
  alignSelf: 'center',
  marginTop: 20,
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '60%',
  flexWrap: 'wrap',
  justifyContent: 'center'
}));

export const Separator = styled(Box)(({ theme }) => ({
  height: 1,
  width: '100%',
  backgroundColor: theme.palette.divider,
  marginBottom: 10,
}));

export const UrlInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginBottom: 20,
}));

export const DropdownItem = styled(MenuItem)<{ grey?: boolean}>(({ theme, grey }) => ({
  textAlign: 'center', 
  justifyContent: 'center', 
  marginRight: 17,
  ...(grey && { color: 'grey' }),
}));

export const LoadingBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
}));
