import { useMutation } from 'react-query';
import { api } from '../index';

const deletePrizeMutation = (id: string) => {
    return api.delete('/prize', {
        params: {
            prize: id,
        },
    });
};

export const useDeletePrizeMutation = () => {
    return useMutation('deletePrizeMutation', deletePrizeMutation);
};
