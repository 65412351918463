import { useQuery } from 'react-query';
import { api } from '../index';
import { ExternalSegments } from '../models/external-models/ExternalSegments';

const segmentsQuery = () => {
    const params = new URLSearchParams();
    params.append('page-size', '20');
    return api.get<ExternalSegments>('/segments', { params });
};

export const useSegmentsQuery = () => {
    return useQuery('segmentsQuery', segmentsQuery, {
        select: response => response.data.segments.map(segment => segment.name),
    });
};
