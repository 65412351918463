import { useNavigate } from 'react-router-dom';
import { Role } from '../../enums/Role';
import { AlertTitle } from '@mui/material';
import * as S from './RequireRole.styles'
import { useCheckBackofficeRoles } from '../../hooks/useCheckBackofficeRoles';
import { useTranslation } from 'react-i18next';

type Props = {
    roles: Role[];
    children: JSX.Element;
};

const RequireRole: React.FC<Props> = ({ roles, children }: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const hasRequiredRole = useCheckBackofficeRoles(roles)

    if (!hasRequiredRole) {
        return (
          <S.NoAccessContainer>
            <S.AlertBox severity={'warning'}>
              <AlertTitle>{t('roles.no.access.title')}</AlertTitle>
              {t('roles.no.access.message')}
            </S.AlertBox>
            <S.BackButton variant={'contained'} onClick={() => navigate(-1)}>{t('back')}</S.BackButton>
          </S.NoAccessContainer>
        )
    }

    return children;
};

export default RequireRole;
