import { ConfigInterface } from './ConfigInterface';
import localConfig from './local.config.json';
import devConfig from './dev.config.json';
import stagingConfig from './staging.config.json';
import prodConfig from './prod.config.json';
import { Environment } from '../enums/Environment';

const config: ConfigInterface = {
    ...(process.env.REACT_APP_ENVIRONMENT === Environment.Production
        ? prodConfig
        : process.env.REACT_APP_ENVIRONMENT === Environment.Staging
        ? stagingConfig
        : devConfig),
};

export default process.env.NODE_ENV !== 'production' ? (localConfig as ConfigInterface) : config;
