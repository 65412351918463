import { useMutation } from 'react-query';
import { api } from '../index';

const deleteCampaignMutation = (id: string) => {
    return api.delete('/campaign', {
        params: {
            campaign: id,
        },
    });
};

export const useDeleteCampaignMutation = () => {
    return useMutation('deleteCampaignMutation', deleteCampaignMutation);
};
