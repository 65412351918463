import React from 'react';
import * as S from './SettingsSwitch.styles';
import { Switch } from '@mui/material';
import { keyToTitle } from '../../../utils/StringsUtil';

type Props = {
    settingKey: string;
    value: boolean;
    onChange: (key: string, value: boolean) => void;
};

export const SettingsSwitch: React.FC<Props> = ({ settingKey, value, onChange }) => {
    const name = keyToTitle(settingKey)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(settingKey, event.target.checked);
    };

    const configSwitch = () => {
        return <Switch onChange={handleChange} key={settingKey} value={settingKey} checked={value} />;
    };
    return <S.ControlLabel labelPlacement={'start'} label={name} control={configSwitch()} />;
};
