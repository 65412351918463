import React, { ReactNode } from 'react';
import * as S from './SettingsTextInput.styles';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { keyToTitle } from '../../../utils/StringsUtil';

type IconProps = {
    icon: ReactNode;
    onClick: () => void;
    disabled?: boolean;
};

type Props = {
    settingKey: string;
    value: string | number;
    onChangeValue: (key: string, value: string | number) => void;
    type?: React.InputHTMLAttributes<unknown>['type'];
    rightSideIcon?: IconProps;
    disabled?: boolean;
    textInputValidationChecks?: (key: string, value: string | number) => boolean;
    parentKey?: string;
};

export const SettingsTextInput: React.FC<Props> = ({
    settingKey,
    value,
    onChangeValue,
    type,
    rightSideIcon,
    disabled = false,
    textInputValidationChecks,
    parentKey,
}) => {
    const { t } = useTranslation()
    const name = keyToTitle(settingKey)

    const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const val = type === 'number' ? parseInt(e.target.value) : e.target.value;
        onChangeValue(settingKey, val);
    };

    const isError = textInputValidationChecks && !textInputValidationChecks(settingKey, value);

    return (
        <S.TextInput
            label={name}
            disabled={disabled}
            onChange={onTextChange}
            key={settingKey}
            type={type}
            value={value}
            error={isError}
            helperText={isError ? t('wrong.input.format') : undefined}
            InputProps={{
                endAdornment: rightSideIcon && (
                    <IconButton onClick={rightSideIcon?.onClick} disabled={rightSideIcon.disabled}>
                        {rightSideIcon?.icon}
                    </IconButton>
                ),
            }}
        />
    );
};
