import { Box, Button } from '@mui/material';
import AccountPopover from '../../components/AccountPopover';
import images from '../../theme/images';
import { useNavigate } from 'react-router-dom';
import S from './DashboardNavbar.styles';
import LanguagePopover from '../../components/language-popover/LanguagePopover';

export const languagePickerMinWidth = 120;

const DashboardNavbar = () => {
    const navigate = useNavigate();

    const onLogoClick = () => {
        navigate('/backoffice');
    };

    return (
        <S.NavAppBar position={'fixed'} elevation={0}>
            <S.NavToolbar sx={{ mx: 15 }}>
                <Button onClick={onLogoClick} sx={{ marginLeft: -2 }}>
                    <Box
                        sx={{
                            height: 'auto',
                            width: 100,
                            marginRight: 1,
                        }}
                        component={'img'}
                        src={images.rbfaFullLogoWithStripe}
                    />
                </Button>
                <S.NavBarButtonsContainer sx={{ marginRight: -1 }}>
                    <LanguagePopover />
                    <S.AccountPopoverContainer>
                        <AccountPopover />
                    </S.AccountPopoverContainer>
                </S.NavBarButtonsContainer>
            </S.NavToolbar>
        </S.NavAppBar>
    );
};
export default DashboardNavbar;
