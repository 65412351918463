import { collection, doc, getDoc, getFirestore, writeBatch } from 'firebase/firestore';
import { useState } from 'react';
import firebase from '../../../firebase';
import { InvitationCard } from '../../models/firestore/InvitationCard';
import { GlobalConstants } from '../../../constants';

export const useMutateInvitationCardsSendout = () => {
    const [docRefs, setDocRefs] = useState<InvitationCard[]>();
    const [error, setError] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>();

    const firestore = getFirestore(firebase.app);
    const batch = writeBatch(firestore);
    const invitationCardsCollection = collection(
        firestore,
        `${GlobalConstants.INVITATION_CARDS.BASE_DOCS}/${GlobalConstants.INVITATION_CARDS.CARDS_DOCS}/imported`,
    );

    const update = async (updatedData: InvitationCard[], importedData: InvitationCard[]) => {
        setIsSuccess(undefined);
        setError(undefined);

        const docs: InvitationCard[] = [];

        for (const card of updatedData) {
            const season = importedData.find(ic => ic.cardNumber === card.cardNumber)?.seasons.newSeason.season;
            const seasonUuid = Object.keys(card.seasons).find(uuid => card.seasons[uuid].season === season);
            if (seasonUuid) {
                await updateSendoutProp(card, seasonUuid, docs);
            }
        }
        batch
            .commit()
            .then(() => {
                setDocRefs(docs);
                setIsSuccess(true);
            })
            .catch(e => setError(e));
    };

    const updateSingle = async (updatedData: InvitationCard, seasonUuid: string) => {
        setIsSuccess(undefined);
        setError(undefined);

        const docs: InvitationCard[] = [];

        await updateSendoutProp(updatedData, seasonUuid, docs);

        batch
            .commit()
            .then(() => {
                setDocRefs(docs);
                setIsSuccess(true);
            })
            .catch(e => setError(e));
    };

    const updateSendoutProp = async (value: InvitationCard, seasonUuid: string, cardsArray: InvitationCard[]) => {
        let docId = value.docId;
        delete value.docId;

        if (!!docId) {
            const docToUpdate = doc(invitationCardsCollection, docId);
            const data = (await getDoc(docToUpdate)).data() as InvitationCard;
            const newSeasons = {
                ...data.seasons,
                [seasonUuid]: {
                    ...data.seasons[seasonUuid],
                    sendoutCompleted: true,
                },
            };
            const updatedValue: InvitationCard = {
                ...data,
                seasons: newSeasons,
            };
            await batch.update(docToUpdate, { ...updatedValue });
            cardsArray.push(createInvitationCard(docToUpdate.id, updatedValue));
        }
    };

    const createInvitationCard = (docId: string, card: InvitationCard) => {
        return {
            ...card,
            docId: docId,
        };
    };

    return {
        mutate: update,
        mutateSingle: updateSingle,
        data: docRefs,
        isError: !!error,
        isSuccess,
    };
};
