import { ExternalOrder } from '../external-models/ExternalOrder';
import { Order } from '../Order';

export class OrderTransformer {
    static transformOrders(orders: ExternalOrder[]): Order[] {
        return orders.map(OrderTransformer.transformOrder);
    }

    private static transformOrder(order: ExternalOrder): Order {
        return {
            id: order.id,
            address: {
                city: order.address.city,
                postalCode: order.address.postal_code,
                country: order.address.country,
                name: order.address.name,
                phone: order.address.phone,
                street: order.address.street,
            },
            prizes: order.prizes,
            user: order.user,
            language: order.language,
            orderDate: order.creation_time,
        };
    }
}
