import { Box, Paper, Typography } from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { SettingsGroupComponent } from '../../components/SettingsGroupComponent';
import { isEqual } from 'lodash';
import { GlobalConstants } from '../../../../constants';

type Props = {
    data: DocumentData;
    onChangeSettings: (data: DocumentData) => void;
    onUnsavedChanges?: (hasUnsavedChanges: boolean) => void;
};

export const FeatureFlagsTab: React.FC<Props> = ({ data, onChangeSettings, onUnsavedChanges }) => {
    const { t } = useTranslation();
    const [currentData, setCurrentData] = useState<DocumentData>(data);
    const [changedSettingKey, setChangedSettingKey] = useState<string>();
    const [changedSettingValue, setChangedSettingValue] = useState<any>();

    useEffect(() => {
        if (!isEqual(data, currentData)) {
            setCurrentData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const keyToName = (key: string) => {
        return key.charAt(0).toLocaleUpperCase() + key.slice(1).replaceAll('_', ' ');
    };

    const onCancel = () => {
        setCurrentData(data);
    };

    const onConfirmFeatureToggle = () => {
        onChangeSettings(currentData);
        setCurrentData(data);
    };

    const confirmModalMessage = () => {
        if (!!changedSettingKey && changedSettingValue !== undefined) {
            const name = keyToName(changedSettingKey);
            if (typeof changedSettingValue === 'boolean') {
                return t('feature.flag.toggle.message', {
                    name: name.toLowerCase().replace('enable ', ''),
                    value: changedSettingValue ? t('on') : t('off'),
                });
            }
            return t('feature.flag.change.message', { name: name, value: JSON.stringify(changedSettingValue) });
        }
        return '';
    };

    const filterSettingsOnKey = (key: string, value: any) => {
        const isObjectWithEnableFlags = (val: any): boolean => {
            return (
                typeof val === 'object' &&
                Object.keys(val).some(k => k.startsWith(GlobalConstants.FEATURE_FLAGS_PREFIX) || isObjectWithEnableFlags(val[k]))
            );
        };
        return key.startsWith(GlobalConstants.FEATURE_FLAGS_PREFIX) || isObjectWithEnableFlags(value);
    };

    const onChangeData = (key: string, value: any, changedRootSettingKey?: string, changedRootSettingValue?: any) => {
        setCurrentData({
            ...currentData,
            [key]: value,
        });
        setChangedSettingKey(changedRootSettingKey);
        setChangedSettingValue(changedRootSettingValue);
    };

    return (
        <Box>
            <ConfirmationDialog
                title={t('feature.flag.change.title', { name: keyToName(changedSettingKey || '') })}
                description={confirmModalMessage()}
                onConfirm={onConfirmFeatureToggle}
                onCancel={onCancel}
                open={!isEqual(data, currentData)}
            />
            <Typography variant={'h5'} gutterBottom sx={{ fontWeight: '500', marginBottom: 1, marginTop: 1 }}>
                {t('features')}
            </Typography>
            <Paper sx={{ ml: -1, p: 2 }}>
                <SettingsGroupComponent
                    data={currentData}
                    onChangeData={onChangeData}
                    filterFields={filterSettingsOnKey}
                    onUnsavedChanges={onUnsavedChanges}
                    arraySaveSystem
                />
            </Paper>
        </Box>
    );
};
