import { IconButton, Avatar, alpha, Box, Typography, Divider, MenuItem } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import MenuPopover from './menu-popover/MenuPopover';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

const AccountPopover = () => {
    const navigate = useNavigate();
    const [oktaUser, setOktaUser] = useState<UserClaims>();
    const { oktaAuth } = useOktaAuth();
    const { t } = useTranslation();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        oktaAuth.getUser().then(user => {
            setOktaUser(user);
        });
    }, [oktaAuth]);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const logout = () => {
        navigate('/logout', {
            replace: true,
        });
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open
                        ? {
                              '&:before': {
                                  zIndex: 1,
                                  content: "''",
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '50%',
                                  position: 'absolute',
                                  bgcolor: theme => alpha(theme.palette.grey[900], theme.palette.action.disabledOpacity),
                              },
                          }
                        : {}),
                }}>
                <Avatar />
            </IconButton>
            <MenuPopover
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                    width: 'fit-content'
                }}>
                <>
                    <Box sx={{ my: 1.5, px: 2.5 }}>
                        <Typography variant="subtitle2" noWrap>
                            {`${oktaUser?.given_name} ${oktaUser?.family_name}`}
                        </Typography>
                        <Typography variant="body2" noWrap>
                            {oktaUser?.email}
                        </Typography>
                    </Box>

                    <Divider sx={{ borderStyle: 'dashed' }} />

                    <MenuItem onClick={logout} sx={{ m: 1 }}>
                        {t('logout')}
                    </MenuItem>
                </>
            </MenuPopover>
        </>
    );
};

export default AccountPopover;
