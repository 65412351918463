import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as S from './InvitationCards.styles';
import { SyntheticEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../components/TabPanel';
import { InvitationCardTypes } from './tabs/InvitationCardTypes';
import { InvitationCardsTab } from './tabs/InvitationCardsTab';
import { Role } from '../../enums/Role';
import { useCheckBackofficeRoles } from '../../hooks/useCheckBackofficeRoles';

export const InvitationCards = () => {
    const { t } = useTranslation();
    const [tab, setTab] = useState<number>(0);
    const [designUnsavedChanges, setDesignUnsavedChanges] = useState<boolean>(false);
    const hasEditRole = useCheckBackofficeRoles([Role.EDIT])

    useEffect(() => {
        if (!hasEditRole && tab !== 0) {
            setTab(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasEditRole])

    const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
        if (designUnsavedChanges) {
            if (window.confirm(t('unsaved.changes'))) {
                changeTab(newValue);
            }
        } else {
            changeTab(newValue);
        }
    };

    const changeTab = (tab: number) => {
        setDesignUnsavedChanges(false);
        setTab(tab);
    };

    return (
        <Box>
            <S.HeaderContainer>
                <Typography variant={'h4'} gutterBottom sx={{ fontWeight: '500' }}>
                    {t('cards.invitation')}
                </Typography>
                {hasEditRole && (
                    <S.TabsContainer value={tab} onChange={handleTabChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                        <S.NavTab label={t('cards')} id={'Cards'} />
                        <S.NavTab label={t('types')} id={'Types'} />
                    </S.TabsContainer>
                )}
            </S.HeaderContainer>
            <>
                <TabPanel activeTab={tab} index={0}>
                    <InvitationCardsTab />
                </TabPanel>
                {hasEditRole && (
                    <TabPanel activeTab={tab} index={1}>
                        <InvitationCardTypes onUnsavedChanges={setDesignUnsavedChanges} />
                    </TabPanel>
                )}
            </>
        </Box>
    );
};
