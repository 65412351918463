import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

export const PaddedBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

export const WarningMessageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}));
