import { RGBColor } from 'react-color';

export const hexToRgbObject = (hex: string): RGBColor => {
    const hexToCalculate = hex.replace('#', '');

    const number = Number.parseInt(hexToCalculate, 16);

    return {
        r: number >> 16,
        g: (number >> 8) & 255,
        b: number & 255,
    };
};

export const hexToRgb = (hex: string) => {
    const rgbObject = hexToRgbObject(hex);
    return `rgb(${rgbObject.r},${rgbObject.g},${rgbObject.b})`;
};

export const rgbToHex = (rgb: string) => {
    const rgbToCalculate = rgb.replace('rgb(', '').replace(')', '');
    const rgbValues: string[] = rgbToCalculate.split(',').map(v => {
        const colorValueNumber = parseInt(v);
        const colorValueHex = colorValueNumber.toString(16);
        return colorValueHex.length === 1 ? '0' + colorValueHex : colorValueHex;
    });
    return `#${rgbValues[0]}${rgbValues[1]}${rgbValues[2]}`;
};

export const shouldUseLightContrast = (hex: string) => {
  const rgbObject = hexToRgbObject(hex);
  return !((rgbObject.r * 0.299 + rgbObject.g * 0.587 + rgbObject.b * 0.114) > 186)
}
