import { Language } from '../enums/Language';
import { ReactComponent as UsIcon } from './../assets/icons/US.svg';
import { ReactComponent as NlIcon } from './../assets/icons/NL.svg';
import { ReactComponent as FrIcon } from './../assets/icons/FR.svg';
import { ReactNode } from 'react';

export const getLanguageIcon = (lang: Language | string): ReactNode => {
    switch (lang) {
        case Language.NL:
            return <NlIcon />;
        case Language.FR:
            return <FrIcon />;
        case Language.EN:
            return <UsIcon />;
        default: 
            return <UsIcon />;
    }
};
