import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import firebase from '../../../firebase';
import { GlobalConstants } from '../../../constants';

export const useMutateInvitationCardSettings = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const firestore = getFirestore(firebase.app);
    const invitationCardsSettings = doc(firestore, GlobalConstants.INVITATION_CARDS.BASE_DOCS, GlobalConstants.INVITATION_CARDS.SETTINGS_DOCS);

    const update = <T>(key: string, value: T) => {
      updateDoc(invitationCardsSettings, key, value).then(() => {
        setError(undefined)
      }).catch(err => {
        setError(err)
      }).finally(() => {
        setIsLoading(false)
      })
    };

    return {
        isLoading: isLoading,
        isError: !!error,
        mutate: update,
    };
};
