import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
}));

export const ValuesContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
}));

export const ArrayAddButton = styled(Button)(({ theme }) => ({
    width: 300,
    padding: 15,
    margin: 8,
}));

export const SettingsGroupContainer = styled(Box)(({ theme }) => ({
    paddingLeft: 10,
}));
