import { styled } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';

const NavAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
}));

const NavToolbar = styled(Toolbar)(({ theme }) => ({
    justifyContent: 'space-between'
}));

const NavBarButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
}));

const AccountPopoverContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const S = {
    NavAppBar,
    NavToolbar,
    NavBarButtonsContainer,
    AccountPopoverContainer,
};

export default S;

