import InvitationCardExcel from '../../../models/InvitationCardExcel';
import { InvitationCard } from '../firestore/InvitationCard';
import {
    getInvitationCardKind,
    getInvitationCardTitleEN,
    getInvitationCardTitleFR,
    getInvitationCardTitleNL,
} from '../../../enums/InvitationCardType';

export const convertInvitationCardExcel = (card: InvitationCardExcel): InvitationCard => {
    return {
        firstname: card.Prénom,
        lastname: card.Nom,
        cardNumber: card.Numéro,
        cardType: getInvitationCardKind(card.Type) || '',
        type: card.Type,
        email: card.Email?.toString().replace(' ', '') || '',
        seasons: {
            newSeason: {
                season: card.Saison,
                googleClicked: false,
                appleClicked: false,
                sendoutCompleted: false,
            },
        },
        language: card.Langue,
        title: {
            EN: getInvitationCardTitleEN(card.Type),
            NL: getInvitationCardTitleNL(card.Type),
            FR: getInvitationCardTitleFR(card.Type),
        },
    };
};
