import { Box, Button, SxProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import MenuPopover from '../menu-popover/MenuPopover';
import * as S from './ColorPicker.styles';
import { Theme } from '@mui/system';

interface Props {
    color: string;
    width?: number | string;
    onColorChange?: (color: string) => void;
    onColorChangeCompleted?: (color: string) => void;
    style: SxProps<Theme>
}

export const ColorPicker: React.FC<Props> = ({ color, width, onColorChange, onColorChangeCompleted, style }) => {
    const [currentColor, setCurrentColor] = useState<string>(color);
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef(null);

    useEffect(() => {
        if (color !== currentColor) {
            changeColor(color)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color])

    const onClickOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onChangeColor = (newColor: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
        changeColor(newColor.hex)
    };
 
    const changeColor = (hexColor: string) => {
        setCurrentColor(hexColor);
        onColorChange?.(hexColor);
    }

    const onChangeComplete = (newColor: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentColor(newColor.hex);
        onColorChangeCompleted?.(newColor.hex);
    };

    return (
        <Box width={width} sx={style}>
            <Button onClick={onClickOpen} ref={anchorRef} sx={{ padding: 0, width: '100%' }}>
                <S.ColorButtonText currentColor={currentColor} width={'100%'}>
                    {currentColor}
                </S.ColorButtonText>
            </Button>
            <MenuPopover
                open={open}
                anchorEl={anchorRef.current}
                onClose={onClose}
                sx={{ width: 'auto' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <SketchPicker color={currentColor} onChange={onChangeColor} onChangeComplete={onChangeComplete} disableAlpha />
            </MenuPopover>
        </Box>
    );
};
