import { api } from '../index';
import { useMutation } from 'react-query';
import { ExternalAddEventsVariables } from '../models/external-models/ExternalAddEventsVariables';
import { ExternalSingleEventVariables } from '../models/external-models/ExternalSingleEventVariables';

const addEventsMutation = (variables: ExternalAddEventsVariables) => {
    return api.post<ExternalSingleEventVariables>('/events', variables);
};

export const useAddEventsMutation = () => {
    return useMutation('addEventsMutation', addEventsMutation);
};
