import { Prize } from '../../api/models/Prize';
import * as React from 'react';
import { useState, Fragment, ReactNode, useEffect, KeyboardEvent } from 'react';
import TableRow from '@mui/material/TableRow';
import { TableCell, IconButton, Collapse, Typography, TextField } from '@mui/material';
import { Edit, Delete, ChevronRight, SaveAlt, Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableHead from '../../components/table/TableHead';
import TableBody from '@mui/material/TableBody';
import { HeadCell } from '../../components/table/HeadCell';
import { PrizeOption } from '../../api/models/PrizeOption';
import { useTranslation } from 'react-i18next';
import { DateUtil } from '../../utils/DateUtil';
import { useEditPrizeOptionMutation } from '../../api/hooks/useEditPrizeOptionMutation';
import { UpdateTransformer } from '../../api/models/transformers/UpdateTransformer';
import { isEqual } from 'lodash';

const optionsHeadCells: HeadCell<PrizeOption>[] = [
    {
        id: 'qualities',
        numeric: false,
        disablePadding: false,
        label: 'qualities',
        sortable: false,
    },
    {
        id: 'stock',
        numeric: false,
        disablePadding: false,
        label: 'prize.option.stock',
        align: 'center',
        sortable: false,
    },
];

interface Props {
    prize: Prize;
    headCells: HeadCell<Prize>[];
    onPrizeDeletePressed: (prize: Prize) => void;
    onPrizeEditPressed: (id: string) => void;
    onPrizeOptionDeletePressed: (prizeId: string, id: string) => void;
    onPrizeOptionEditSuccess?: () => void;
}

const PrizesTableRow: React.FC<Props> = ({
    prize,
    headCells,
    onPrizeDeletePressed,
    onPrizeEditPressed,
    onPrizeOptionDeletePressed,
    onPrizeOptionEditSuccess,
}) => {
    const [open, setOpen] = useState(false);
    const [editingPrizeOption, setEditingPrizeOption] = useState<PrizeOption>();
    const { t } = useTranslation();
    const editPrizeOptionMutation = useEditPrizeOptionMutation();

    useEffect(() => {
        setEditingPrizeOption(undefined);
        onPrizeOptionEditSuccess?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPrizeOptionMutation.isSuccess]);

    const renderRowEntry = (row: Prize, key: keyof Prize, onClick: () => void): ReactNode => {
        switch (key) {
            case 'category':
                return <TableCell align="left" onClick={onClick}>{row.category}</TableCell>;
            case 'points':
                return <TableCell align="left" onClick={onClick}>{row.points}</TableCell>;
            case 'ordersPerUser':
                return <TableCell align="left" onClick={onClick}>{row.ordersPerUser}</TableCell>;
            case 'availableUntil':
                return <TableCell align="left" onClick={onClick}>{DateUtil.formatDate(row.availableUntil)}</TableCell>;
            case 'label':
                return <TableCell align="left" onClick={onClick}>{row.label.toString()}</TableCell>;
            case 'name':
                return (
                    <TableCell component="th" scope="row" onClick={onClick}>
                        {row.name.toString()}
                    </TableCell>
                );
            default:
                return null;
        }
    };

    const onPrizeOptionEditPressed = (option: PrizeOption) => {
        setEditingPrizeOption(option);
    };

    const onPrizeOptionSavePressed = (option: PrizeOption) => {
        if (editingPrizeOption) {
            const updateObject = UpdateTransformer.transformToUpdateObject(option, editingPrizeOption);
            editPrizeOptionMutation.mutate({
                id: option.id,
                updateObjects: updateObject,
            });
        }
    };

    const onChangeOptionStock = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (editingPrizeOption) {
            setEditingPrizeOption({ ...editingPrizeOption, stock: +event.target.value });
        }
    };

    const onStopPrizeOptionEditPressed = () => {
        setEditingPrizeOption(undefined);
    };

    const areFieldsValid = (option: PrizeOption) => {
        return !isEqual(option, editingPrizeOption) && editingPrizeOption?.stock! >= 0;
    };

    const onStockTextKeyDown = (event: KeyboardEvent<HTMLDivElement>, option: PrizeOption) => {
        if (event.key.toLowerCase() === 'enter') {
            onPrizeOptionSavePressed(option)
        }
    }

    return (
        <Fragment key={prize.id}>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}>
                {prize.options.length > 0 ? (
                    <TableCell width={30}>
                        <IconButton size="small" onClick={() => setOpen(!open)}>
                            <ChevronRight
                                sx={{
                                    transition: theme => theme.transitions.create('transform'),
                                    ...(open
                                        ? {
                                              transform: 'rotate(-90deg)',
                                          }
                                        : { transform: 'rotate(90deg)' }),
                                }}
                            />
                        </IconButton>
                    </TableCell>
                ) : (
                    <TableCell />
                )}
                {headCells.map(headCell => (
                    <Fragment key={headCell.id}>{renderRowEntry(prize, headCell.id, () => setOpen(!open))}</Fragment>
                ))}
                <TableCell align={'right'}>
                    <IconButton onClick={() => onPrizeEditPressed(prize.id)}>
                        <Edit />
                    </IconButton>
                    <IconButton onClick={() => onPrizeDeletePressed(prize)}>
                        <Delete />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {t('prize.options')}
                            </Typography>
                            <MuiTable size="small">
                                <TableHead headCells={optionsHeadCells} />
                                <TableBody>
                                    {prize.options.map(option => (
                                        <TableRow key={option.id}>
                                            <TableCell component="th" scope="row" width={'50%'}>
                                                {Object.entries(option.qualities)
                                                    .map(([key, value]) => `${key}: ${value}`)
                                                    .join(', ')}
                                            </TableCell>
                                            <TableCell align={'center'} width={'30%'}>
                                                {editingPrizeOption?.id !== option.id ? (
                                                    option.stock
                                                ) : (
                                                    <TextField
                                                        type={'number'}
                                                        size="small"
                                                        InputProps={{
                                                            inputProps: {
                                                                style: { textAlign: 'center' },
                                                                min: 0,
                                                            },
                                                        }}
                                                        value={editingPrizeOption.stock}
                                                        onChange={onChangeOptionStock}
                                                        onKeyDown={(ev) => onStockTextKeyDown(ev,option)}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align={'right'} width={80}>
                                                {editingPrizeOption?.id !== option.id ? (
                                                    <>
                                                        <IconButton onClick={() => onPrizeOptionEditPressed(option)}>
                                                            <Edit />
                                                        </IconButton>
                                                        <IconButton disabled onClick={() => onPrizeOptionDeletePressed(prize.id, option.id)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconButton
                                                            onClick={() => onPrizeOptionSavePressed(option)}
                                                            disabled={!areFieldsValid(option)}>
                                                            <SaveAlt />
                                                        </IconButton>
                                                        <IconButton onClick={onStopPrizeOptionEditPressed}>
                                                            <Close />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </MuiTable>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

export default PrizesTableRow;
