import { SvgIconComponent } from '@mui/icons-material';
import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import S from './DashboardButton.styles';

interface Props {
    title: string
    route: string
    icon: ReactElement<SvgIconComponent>
}

const DashboardButton = ({ title, route, icon }: Props) => {
    const navigate = useNavigate();
    const onClickLoyalty = () => navigate(route)
    
    return (
        <Box>
            <S.DashboardButton variant={'contained'} onClick={onClickLoyalty}>
                <S.DashboardButtonContent>
                    {icon}
                    {title}
                </S.DashboardButtonContent>
            </S.DashboardButton>
        </Box>
    );
};

export default DashboardButton;
