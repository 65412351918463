import { Box, LinearProgress } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const ContentContainer = styled(Box)(({ theme }) => ({
    padding: 20,
}));

export const LoadingBar = styled(LinearProgress)(({ theme }) => ({
    marginTop: 10,
    height: 20,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}));
